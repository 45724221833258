import {createSelector} from 'reselect';
import {CommonRootState} from '../types';

export const selectWeatherflowConnectedAt = (state: CommonRootState) =>
  state.weatherflow.link && state.weatherflow.link.date_connected;

export const selectWeatherflowConnected = (state: CommonRootState) =>
  state.weatherflow.link !== null;

export const selectWeatherflowActive = (state: CommonRootState) =>
  state.weatherflow.link !== null && state.weatherflow.link.account.active;

export const getWeatherflowStationList = (state: CommonRootState) => {
  if (state.weatherflow.link !== null) {
    return state.weatherflow.link.account.weather_stations;
  }
  return [];
};

const getRawCurrentStationId = (state: CommonRootState) =>
  state.weatherflow.currentStation;

export const getCurrentWeatherflowStation = createSelector(
  getRawCurrentStationId,
  getWeatherflowStationList,
  (stationId, stationList) => {
    if (stationList.length === 0) {
      return null;
    }
    const currentStation = stationId
      ? stationList.find((item) => item.device_id === stationId) ||
        stationList[0]
      : stationList[0];

    return currentStation;
  },
);
