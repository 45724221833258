import {
  cleanUpStationList,
  ErrorInfo,
  getUserUsername,
  selectUserEmail,
  useApiClient,
  UserDetail,
  UserService,
  useUserEdit,
  useUserLoggedIn,
} from '@smartmixin/core';
import {StatusCodes} from 'http-status-codes';
import React, {PropsWithChildren} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useEffectOnce, useUpdateEffect} from 'react-use';
import {SentryUtils} from '.';
import AlertSnackbar from '../containers/AlertSnackbar';
import {useWindowFocus} from '../hooks';

const UserSyncProvider = ({children}: PropsWithChildren) => {
  const dispatch = useDispatch();
  const {fetch} = useApiClient();
  const editUserDetails = useUserEdit();
  const isLoggedIn = useUserLoggedIn();
  // const purchase = useSelector(selectPurchase);
  const email = useSelector(selectUserEmail);
  const username = useSelector(getUserUsername);
  const isFocused = useWindowFocus();

  /***************************************
   * INITIAL SYNC WITH SMARTMIXIN SERVERS
   ****************************************/
  useEffectOnce(() => {
    dispatch(cleanUpStationList());
    if (isLoggedIn) {
      // authenticated, sync user details
      fetch(UserService.getUserDetail())
        .then((data: UserDetail) => {
          editUserDetails(data);
        })
        .catch((err: ErrorInfo) => {
          if (err.statusCode === StatusCodes.UNAUTHORIZED) {
            // Account seems to be not valid anymore
            dispatch({type: 'LOGOUT'});
          }
        });
    }
    // else if (purchase) {
    //   // For unauthenticated users, check subscription validity
    //   fetch(BillingService.getPurchaseStatus(purchase.id), { ignore404: true })
    //     .then((data: BillingStatus) => {
    //       dispatch(setBillingStatus(data));
    //     })
    //     .catch(() => {});
    // }
  });

  /***************************************
   * APP STATE MONITORING
   ****************************************/
  const [currentAppState, setCurrentAppState] = React.useState(isFocused);
  const [previousAppState, setPreviousAppState] = React.useState(null);

  useUpdateEffect(() => {
    setCurrentAppState(isFocused);
  }, [isFocused]);

  useUpdateEffect(() => {
    if (previousAppState !== null && currentAppState !== previousAppState) {
      if (currentAppState) {
        if (isLoggedIn) {
          fetch(UserService.getUserDetail(), {debounce: 5000})
            .then((data: UserDetail) => {
              editUserDetails(data);
            })
            .catch((err: ErrorInfo) => {
              if (err.statusCode === StatusCodes.UNAUTHORIZED) {
                // Account seems to be not valid anymore
                dispatch({type: 'LOGOUT'});
              }
            });
        }
      }
      setPreviousAppState(currentAppState);
    }
  }, [currentAppState, previousAppState, isLoggedIn, fetch, editUserDetails]);

  React.useEffect(() => {
    if (isLoggedIn) {
      if (email) {
        SentryUtils.setUser(email, username);
      }
    }
  }, [isLoggedIn, email, username]);

  return (
    <>
      <AlertSnackbar />
      {children}
    </>
  );
};

export default UserSyncProvider;
