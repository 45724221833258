import {Dates, useUserLanguage} from '@smartmixin/core';
import React, {PropsWithChildren} from 'react';
import {IntlProvider} from 'react-intl';
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from './setup';

const onError = () => {
  // if ((e.code = ReactIntlErrorCode.MISSING_DATA)) {
  //   return;
  // }
};

const loadMessages = async (l: string) => {
  let messages;
  switch (l) {
    case 'en':
      messages = await import('./translations/en.js');
      break;
    case 'bg':
      messages = await import('./translations/bg.js');
      break;
    case 'cs':
      messages = await import('./translations/cs.js');
      break;
    case 'da':
      messages = await import('./translations/da.js');
      break;
    case 'de':
      messages = await import('./translations/de.js');
      break;
    case 'es':
      messages = await import('./translations/es.js');
      break;
    case 'fr':
      messages = await import('./translations/fr.js');
      break;
    case 'hr':
      messages = await import('./translations/hr.js');
      break;
    case 'hu':
      messages = await import('./translations/hu.js');
      break;
    case 'it':
      messages = await import('./translations/it.js');
      break;
    case 'ja':
      messages = await import('./translations/ja.js');
      break;
    case 'ko':
      messages = await import('./translations/ko.js');
      break;
    case 'nb':
      messages = await import('./translations/nb.js');
      break;
    case 'nl':
      messages = await import('./translations/nl.js');
      break;
    case 'pl':
      messages = await import('./translations/pl.js');
      break;
    case 'pt':
      messages = await import('./translations/pt.js');
      break;
    case 'ru':
      messages = await import('./translations/ru.js');
      break;
    case 'sk':
      messages = await import('./translations/sk.js');
      break;
    case 'sr':
      messages = await import('./translations/sr.js');
      break;
    case 'sv':
      messages = await import('./translations/sv.js');
      break;
    case 'uk':
      messages = await import('./translations/uk.js');
      break;
    default:
      messages = await import('./translations/en.js');
      break;
  }
  return messages.default;
};

const LanguageProvider = ({children}: PropsWithChildren) => {
  const language = useUserLanguage();

  // user preferred language
  const userProfileLanguage = AVAILABLE_LANGUAGES.includes(language)
    ? language
    : null;

  const locale = userProfileLanguage || DEFAULT_LANGUAGE;

  Dates.setLocale(locale);
  Dates.setTimeDifferenceThresholds();

  const [messagesForLocale, setMessagesForLocale] =
    React.useState<Record<string, string>>();

  React.useEffect(() => {
    loadMessages(locale).then((messages) => {
      setMessagesForLocale(messages);
    });
  }, [locale]);

  return messagesForLocale ? (
    <IntlProvider
      locale={locale}
      messages={messagesForLocale}
      onError={onError}>
      {children}
    </IntlProvider>
  ) : null;
};

export default LanguageProvider;
