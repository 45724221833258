import * as Dates from '../Dates';
import NetatmoDevice from '../NetatmoCommon/NetatmoDevice';
import {AWAY, FROST_GUARD, ZONE_TYPE} from './constants';
import NetatmoEnergyModule from './NetatmoEnergyModule';
import NetatmoEnergyRoom from './NetatmoEnergyRoom';
import Schedule from './Schedule';
import {HomeStatus, HomeType, ThermostatMode} from './types';

export default class Home extends NetatmoDevice {
  data: HomeType;
  status: HomeStatus | undefined;
  coordinates: {latitude: number; longitude: number};
  thermostatFullMode: ThermostatMode;
  moduleList: NetatmoEnergyModule[];

  constructor(data: HomeType, status: HomeStatus | undefined) {
    super();
    this.data = data;
    this.status = status;

    this.id = data.id;
    this.timezone = data.timezone;

    this.coordinates = this.getCoordinate();

    this.thermostatFullMode = this.getThermostatFullMode();
    this.moduleList = this.getModuleList();
  }

  getName() {
    return this.data.name;
  }

  getCountry() {
    return this.data.country;
  }

  getAltitude() {
    return this.data.altitude || null;
  }

  getCoordinate() {
    return {
      latitude: this.data.coordinates[1],
      longitude: this.data.coordinates[0],
    };
  }

  getRoomList() {
    if (typeof this.data.rooms !== 'undefined') {
      return this.data.rooms.map(
        (room) =>
          new NetatmoEnergyRoom(room, this.getRoomDashboardData(room.id)),
      );
    }
    return [];
  }

  getRoomsWithModules() {
    const validModuleIdList = this.getModuleList()
      .filter((item) => !['NAPlug', 'OTH'].includes(item.getType()))
      .map((item) => item.getId());

    return this.getRoomList().filter(
      (item) =>
        item &&
        item.getModuleIds().filter((id) => validModuleIdList.includes(id))
          .length > 0,
    );
  }

  getModuleList() {
    if (typeof this.data.modules !== 'undefined') {
      return this.data.modules.map(
        (module) =>
          new NetatmoEnergyModule(
            module,
            this.getModuleDashboardData(module.id),
          ),
      );
    }
    return [];
  }

  getModuleFromId(moduleId: string) {
    for (const module of this.getModuleList()) {
      if (module.id === moduleId) {
        return module;
      }
    }
    return null;
  }

  getRoomFromId(roomId: string) {
    for (const room of this.getRoomList()) {
      if (room.id === roomId) {
        return room;
      }
    }
    return null;
  }

  getRoomAssociatedToModule(moduleId: string) {
    for (const room of this.getRoomList()) {
      if (room.getModuleIds().includes(moduleId)) {
        return room;
      }
    }
    return null;
  }

  getModuleListForRoom(roomId: string) {
    return this.getModuleList().filter((item) => item.getRoomId() === roomId);
  }

  getNATherm1ForRoom(roomId: string) {
    for (const item of this.getModuleList()) {
      if (
        item.getRoomId() === roomId &&
        ['NATherm1', 'OTM'].includes(item.getType())
      ) {
        return item;
      }
    }
    return null;
  }

  getNATherm1AssociatedToRelay(relayId: string) {
    for (const item of this.getModuleList()) {
      if (
        item.isBridged() &&
        item.getBridgeId() === relayId &&
        ['NATherm1', 'OTM'].includes(item.getType())
      ) {
        return item;
      }
    }
    return null;
  }

  getThermostatMode() {
    return this.data.therm_mode;
  }

  getThermostatFullMode(): ThermostatMode {
    return {
      mode: this.getThermostatMode(),
      endTime: this.getThermModeEndtime(),
    };
  }

  createRoomSetpointList() {
    return this.getRoomList().reduce(
      (obj, room) => {
        obj[room.id] = room.getThermSetpointTemperature();
        return obj;
      },
      {} as Record<string, number | undefined>,
    );
  }

  getThermostatSetpointDefaultDuration() {
    return this.data.therm_setpoint_default_duration;
  }

  getThermModeEndtime() {
    return this.data.therm_mode_endtime;
  }

  getScheduleList() {
    return this.data.schedules.map((schedule) => new Schedule(schedule));
  }

  getDashboardData() {
    return this.status;
  }

  getModuleDashboardData(moduleId: string) {
    if (this.getDashboardData()) {
      const modules = Object.values(this.getDashboardData().modules);
      const moduleData = modules.filter((item) => item.id === moduleId);
      if (moduleData.length > 0) {
        return moduleData[0];
      }
    }
    return undefined;
  }

  getRoomDashboardData(roomId: string) {
    if (this.getDashboardData()) {
      const rooms = Object.values(this.getDashboardData().rooms);
      const roomData = rooms.filter((item) => item.id === roomId);
      if (roomData.length > 0) {
        return roomData[0];
      }
    }
    return undefined;
  }

  getSetpointEndtime() {
    const roomList = this.getRoomsWithModules();
    if (!roomList || roomList.length === 0) {
      return undefined;
    }
    const roomDashboard = this.getRoomDashboardData(roomList[0].id);
    if (!roomDashboard) {
      return undefined;
    }
    return roomDashboard.therm_setpoint_end_time;
  }

  getThermProgramList() {
    if (typeof this.data.schedules !== 'undefined') {
      return this.data.schedules.map((item) => new Schedule(item));
    }
    return [];
  }

  getCurrentThermProgram() {
    for (const schedule of this.getThermProgramList()) {
      if (schedule.isSelected()) {
        return schedule;
      }
    }
  }

  getThermProgram(programId: string) {
    for (const schedule of this.getThermProgramList()) {
      if (schedule.id === programId) {
        return schedule;
      }
    }
    return null;
  }

  getProgramSetpointTemp(schedule = null) {
    return this.getProgramSetpointZone(schedule).temp;
  }

  getFrostGuardSetpointTemp(schedule = null) {
    return this.getZoneFromType(
      schedule || this.getCurrentThermProgram(),
      ZONE_TYPE[FROST_GUARD],
    ).temp;
  }

  getAwaySetpointTemp(schedule = null) {
    return this.getZoneFromType(
      schedule || this.getCurrentThermProgram(),
      ZONE_TYPE[AWAY],
    ).temp;
  }

  getProgramSetpointZoneName(schedule = null) {
    return this.getProgramSetpointZone(schedule).name;
  }

  getProgramSetpointZone(schedule = null) {
    const program = schedule || this.getCurrentThermProgram();
    if (program) {
      const currentOffset = Dates.timeDiffWithMidnight(this.timezone);
      let currentTimeslotId = null;
      for (const timeslot of program.getTimetable()) {
        if (currentOffset > timeslot.m_offset) {
          currentTimeslotId = timeslot.zone_id;
        } else {
          break;
        }
      }
      if (currentTimeslotId !== null) {
        const currentZone = this.getZoneFromType(program, currentTimeslotId);
        return currentZone;
      } else {
        // probably sunday evening, take first timeslot
        currentTimeslotId = program.getTimetable()[0].zone_id;
        const currentZone = this.getZoneFromType(program, currentTimeslotId);
        return currentZone;
      }
    }
    return null;
  }

  getProgramSetpointZoneEndtime(program = null) {
    const selectedProgram = program || this.getCurrentThermProgram();
    const currentOffset = Dates.timeDiffWithMidnight(this.timezone);
    if (typeof selectedProgram !== 'undefined') {
      for (const timeslot of selectedProgram.getTimetable()) {
        if (currentOffset < timeslot.m_offset) {
          return (
            Dates.timestampAtWeekStart(this.timezone) + 60 * timeslot.m_offset
          );
        }
      }
      // sunday evening
      const timeslot = selectedProgram.getTimetable()[0];
      return Dates.timestampAtWeekStart(this.timezone) + 60 * timeslot.m_offset;
    }
    return null;
  }

  getZoneFromType(program: Schedule, type: number) {
    for (const zone of program.getZoneList()) {
      if (zone.id === type) {
        return zone;
      }
    }
  }

  getCurrentThermProgramName() {
    return this.getCurrentThermProgram()?.getName();
  }

  getBoilerStatus() {
    const naThermModuleList = this.getModuleList().filter(
      (item) => item.getType() === 'NATherm1',
    );
    if (naThermModuleList.length > 0) {
      return naThermModuleList[0].getBoilerStatus();
    }
    return false;
  }
}
