import {Navigate} from 'react-router-dom';

export const stationDetailRoutes = [
  {
    path: '',
    lazy: () =>
      import('../../../../screens/stations/StationDetail/StationDashboard'),
  },
  {
    path: 'forecast',
    lazy: () =>
      import('../../../../screens/stations/StationDetail/StationForecast'),
  },
  {
    path: 'history',
    lazy: () =>
      import('../../../../screens/stations/StationDetail/StationHistory'),
  },
  {
    path: '*',
    element: <Navigate to={''} replace />,
  },
];
