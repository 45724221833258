import {createSlice} from '@reduxjs/toolkit';
import {NetatmoDataGranularity} from '../../api/types';
import {DAY} from '../../utils/Dates';
import {ChartConfig, ComparisonConfig} from '../../utils/NetatmoCommon';
import {Scale} from '../../utils/Time';
import {ForecastModelConfig} from '../../utils/WeatherForecast/types';

type Account = {
  uid: string;
  active: boolean;
  last_updated: number;
  extra_data: Record<string, any>;
};

type StationMetadata = {
  name: string;
};

type Metadata = {
  stations: Record<string, StationMetadata>;
};

type Link = {
  account: Account;
  date_connected: number;
  metadata: Metadata;
};

type Props = {
  link: Link | null;
  currentStation: string | null;
  mapMarkers: any[];
  chartConfig: ChartConfig;
  forecastModels: Record<string, ForecastModelConfig[]>;
  selectedForecastModel: Record<string, string>;
  mapFilterStations: boolean;
  mapRainMeasurement: 'lastHour' | 'today' | 'lastMeasure';
  mapWindMeasurement: 'wind' | 'gust';
};

export const netatmoWeatherSlice = createSlice({
  name: 'netatmoWeather',
  initialState: {
    link: null,
    currentStation: null,
    mapMarkers: [],
    chartConfig: {
      scale: DAY,
      granularity: 'max',
      comparison: null,
    },
    forecastModels: {},
    selectedForecastModel: {},
    mapFilterStations: true,
    mapRainMeasurement: 'lastHour',
    mapWindMeasurement: 'wind',
  } as Props,
  reducers: {
    setNetatmoWeatherUserLink: (state, {payload: link}) => {
      state.link = link;
    },
    setNetatmoWeatherAccount: (state, {payload: account}) => {
      if (state.link !== null) {
        state.link.account = account;
      }
    },
    setNetatmoWeatherCurrentStation: (state, {payload: stationid}) => {
      state.currentStation = stationid;
    },
    setNetatmoWeatherForecastModels: (
      state,
      {
        payload: {stationId, forecastModels},
      }: {
        payload: {
          stationId: string;
          forecastModels: ForecastModelConfig[];
        };
      },
    ) => {
      const newForecastModels = {} as Record<string, ForecastModelConfig[]>;
      newForecastModels[stationId] = forecastModels;
      state.forecastModels = {...state.forecastModels, ...newForecastModels};
    },
    setNetatmoWeatherSelectedForecastModel: (
      state,
      {
        payload: {stationId, forecastModel},
      }: {payload: {stationId: string; forecastModel: string}},
    ) => {
      const newSelectedForecastModel = {} as Record<string, any>;
      newSelectedForecastModel[stationId] = forecastModel;
      state.selectedForecastModel = {
        ...state.selectedForecastModel,
        ...newSelectedForecastModel,
      };
    },
    setNetatmoWeatherChartComparison: (
      state,
      {payload: comparisonConfig}: {payload: ComparisonConfig},
    ) => {
      state.chartConfig = {
        ...state.chartConfig,
        comparison: comparisonConfig,
      };
    },
    setNetatmoWeatherChartScale: (
      state,
      {payload: scale}: {payload: Scale},
    ) => {
      state.chartConfig = {...state.chartConfig, scale};
    },
    setNetatmoWeatherChartGranularity: (
      state,
      {payload: granularity}: {payload: NetatmoDataGranularity},
    ) => {
      state.chartConfig = {...state.chartConfig, granularity};
    },
    setNetatmoWeatherMapFilterStations: (
      state,
      {payload: value}: {payload: boolean},
    ) => {
      state.mapFilterStations = value;
    },
    setNetatmoWeatherMapRainMeasurement: (
      state,
      {payload: value}: {payload: 'lastHour' | 'today' | 'lastMeasure'},
    ) => {
      state.mapRainMeasurement = value;
    },
    setNetatmoWeatherMapWindMeasurement: (
      state,
      {payload: value}: {payload: 'wind' | 'gust'},
    ) => {
      state.mapWindMeasurement = value;
    },
  },
});

export const {
  setNetatmoWeatherUserLink,
  setNetatmoWeatherAccount,
  setNetatmoWeatherCurrentStation,
  setNetatmoWeatherForecastModels,
  setNetatmoWeatherSelectedForecastModel,
  setNetatmoWeatherChartComparison,
  setNetatmoWeatherChartScale,
  setNetatmoWeatherChartGranularity,
  setNetatmoWeatherMapFilterStations,
  setNetatmoWeatherMapRainMeasurement,
  setNetatmoWeatherMapWindMeasurement,
} = netatmoWeatherSlice.actions;

export default netatmoWeatherSlice.reducer;
