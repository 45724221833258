import {combineReducers} from '@reduxjs/toolkit';
import {
  ambientWeatherReducer,
  billingReducer,
  dataReducer,
  davisReducer,
  ecowittReducer,
  myAcuriteReducer,
  netatmoEnergyReducer,
  netatmoWeatherReducer,
  uiInteractionReducer,
  userPreferenceReducer,
  userReducer,
  weatherDataReducer,
  weatherReducer,
  weatherflowReducer,
  wundergroundReducer,
} from '@smartmixin/core';
import prefsReducer from './prefs';

export const reducerObj = {
  uiInteraction: uiInteractionReducer,
  prefs: prefsReducer,
  user: userReducer,
  userPreference: userPreferenceReducer,
  billing: billingReducer,
  netatmoWeather: netatmoWeatherReducer,
  netatmoEnergy: netatmoEnergyReducer,
  data: dataReducer,
  weather: weatherReducer,
  weatherData: weatherDataReducer,
  davis: davisReducer,
  weatherflow: weatherflowReducer,
  ambientWeather: ambientWeatherReducer,
  wunderground: wundergroundReducer,
  ecowitt: ecowittReducer,
  myAcurite: myAcuriteReducer,
};

const appReducer = combineReducers(reducerObj);

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    const {ui, prefs, userPreference} = state;
    state = {ui, prefs, userPreference};
  }

  return appReducer(state, action);
};

export default rootReducer;
