import {stationDetailRoutes} from './stationDetail/routes';

export const stationRoutes = [
  {
    path: '',
    lazy: async () => import('../../../screens/stations/StationList'),
  },
  {
    path: 'search',
    lazy: async () => import('../../../screens/stations/StationSearch'),
  },
  {
    path: ':id',
    lazy: async () => import('./stationDetail'),
    children: stationDetailRoutes,
  },
  {
    path: '*',
    lazy: async () => import('../../../screens/NotFound'),
  },
];
