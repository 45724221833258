import {createSelector} from 'reselect';
import {NetatmoEnergyHome, Utils} from '../../utils/NetatmoEnergy';
import {HomeType} from '../../utils/NetatmoEnergy/types';
import {CommonRootState} from '../types';

export const selectNetatmoEnergyConnectedAt = (state: CommonRootState) =>
  state.netatmoEnergy.link && state.netatmoEnergy.link.date_connected;

export const selectNetatmoEnergyConnected = (state: CommonRootState) =>
  state.netatmoEnergy.link !== null;

export const selectNetatmoEnergyActive = (state: CommonRootState) =>
  state.netatmoEnergy.link !== null && state.netatmoEnergy.link.account.active;

export const getRawHomeList = (state: CommonRootState) => {
  if (state.netatmoEnergy.link !== null) {
    return state.netatmoEnergy.link.account.extra_data.homes;
  }
  return [];
};

export const getRawHomeStatuses = (state: CommonRootState) => {
  return state.netatmoEnergy.homeStatus;
};

export const getHomeList = createSelector(
  getRawHomeList,
  getRawHomeStatuses,
  (homeList, statusList) =>
    homeList === undefined
      ? Utils.getHomeList([], {})
      : Utils.getHomeList(homeList as HomeType[], statusList),
);

export const getCurrentHome = (state: CommonRootState) => {
  let defaultHome = null;
  let home = null;
  if (getHomeList(state).length !== 0) {
    if (state.netatmoEnergy.currentHome !== null) {
      home = Utils.getHomeFromId(
        getHomeList(state),
        state.netatmoEnergy.currentHome,
      );
    }
    defaultHome = getHomeList(state)[0];
  }
  return (home || defaultHome) as NetatmoEnergyHome;
};

export const getRoomSetpointList = createSelector(getCurrentHome, (home) =>
  home !== null ? home.createRoomSetpointList() : {},
);

export const selectNetatmoEnergyChartConfig = (state: CommonRootState) =>
  state.netatmoEnergy.chartConfig;
