import {CommonRootState} from '../types';
import {FREEMIUM, LEGACY_PREMIUM, PREMIUM, PREMIUM_FREE} from './constants';

export const selectUserBillingPlan = (state: CommonRootState) => {
  let status = FREEMIUM;
  if (state.billing.billing.free_subscription) {
    status = PREMIUM_FREE;
  } else if (state.billing.billing.legacy_premium) {
    status = LEGACY_PREMIUM;
  } else if (state.billing.billing.premium) {
    status = PREMIUM;
  }
  return status;
};

export const selectBilling = (state: CommonRootState) => state.billing.billing;

export const selectIsPremium = (state: CommonRootState) =>
  state.billing.billing.premium;

export const selectIsLegacyPremium = (state: CommonRootState) =>
  state.billing.billing.premium || state.billing.billing.legacy_premium;

export const selectPurchase = (state: CommonRootState) =>
  state.billing.billing.purchase;
