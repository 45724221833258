import {createSelector} from 'reselect';
import {CommonRootState} from '../types';

export const selectDavisConnectedAt = (state: CommonRootState) =>
  state.davis.link && state.davis.link.date_connected;

export const selectDavisConnected = (state: CommonRootState) =>
  state.davis.link !== null;

export const selectDavisActive = (state: CommonRootState) =>
  state.davis.link !== null && state.davis.link.account.active;

export const getDavisStationList = (state: CommonRootState) => {
  if (state.davis.link !== null) {
    return state.davis.link.account.weather_stations.filter(
      (station) =>
        station.data && station.data.sensors && station.data.sensors.length > 0,
    );
  }
  return [];
};

const getRawCurrentStationId = (state: CommonRootState) =>
  state.davis.currentStation;

export const getCurrentDavisStation = createSelector(
  getRawCurrentStationId,
  getDavisStationList,
  (stationId, stationList) => {
    if (stationList.length === 0) {
      return null;
    }
    const currentStation = stationId
      ? stationList.find((item) => item.device_id === stationId) ||
        stationList[0]
      : stationList[0];

    return currentStation;
  },
);
