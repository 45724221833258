import chroma from 'chroma-js';
import {isLight} from '../Colors';

export type TemperatureUnit = 0 | 1 | 2;
export type RainUnit = 0 | 1;
export type SpeedUnit = 0 | 1 | 2 | 3 | 4;
export type PressureUnit = 0 | 1 | 2;
export type DistanceUnit = 0 | 1;

export type Units = {
  T: TemperatureUnit;
  RR: RainUnit;
  FF: SpeedUnit;
  SLP: PressureUnit;
  DISTANCE: DistanceUnit;
};

// TEMPERATURE
export const CELSIUS = 0;
export const FAHRENHEIT = 1;
export const KELVIN = 2;

export const getKpiColor = (value: number, type: string) => {
  if (type === 'Temperature') {
    if (value < 0 || value > 35) {
      return 'white';
    }
    return 'black';
  }
  if (type === 'Humidity') {
    if (value > 90) {
      return 'white';
    }
    return 'black';
  }
  if (type === 'Rain') {
    if (value > 10) {
      return 'white';
    }
    return 'black';
  }
  return 'black';
};

export const getMonthlyRainKpiColor = (value: number) => {
  if (value > 50) {
    return 'white';
  }
  return 'black';
};

export const getFontColorFromBackground = (hc: string) =>
  isLight(hc) ? 'black' : 'white';

export const getTemperatureUnitLabel = ({T}: {T: number}) => {
  let label;
  switch (T) {
    case CELSIUS:
      label = '°C';
      break;
    case FAHRENHEIT:
      label = '°F';
      break;
    case KELVIN:
      label = 'K';
      break;
    default:
      label = '°C';
      break;
  }
  return label;
};

export const getTempUnitChoices = () => ['°C', '°F', 'K'];

export const convertToFahrenheit = (celsius: number) => (celsius * 9) / 5 + 32;

export const convertToKelvin = (celsius: number) => celsius + 273.15;

export const convertDegreeDayToFahrenheit = (celsius: number) =>
  (celsius * 9) / 5;

export const convertToCelsius = (fahrenheit: number) =>
  ((fahrenheit - 32) * 5) / 9;

// RAIN
export const MM = 0;
export const INCH = 1;

export const getRainUnitLabel = ({RR}: {RR: number}) => {
  let label;
  switch (RR) {
    case MM:
      label = 'mm';
      break;
    case INCH:
      label = 'in';
      break;
    default:
      label = 'mm';
      break;
  }
  return label;
};

export const getRainUnitChoices = () => ['mm', 'in'];

export const convertToInches = (mm: number) => mm / 25.4;

// WIND
export const KPH = 0;
export const MPH = 1;
export const MS = 2;
export const BEAUFORT = 3;
export const KNOT = 4;

export const getWindUnitLabel = ({FF}: {FF: number}) => {
  let label;
  switch (FF) {
    case KPH:
      label = 'km/h';
      break;
    case MPH:
      label = 'mph';
      break;
    case MS:
      label = 'm/s';
      break;
    case BEAUFORT:
      label = 'Bft';
      break;
    case KNOT:
      label = 'kn';
      break;
    default:
      label = 'km/h';
      break;
  }
  return label;
};

export const getWindUnitChoices = () => ['km/h', 'mph', 'm/s', 'Bft', 'knot'];

export const convertSpeedToMph = (value: number) => value * 0.621371;

export const convertSpeedToMps = (value: number) => value / 3.6;

export const convertMpsSpeedToKph = (value: number) => value * 3.6;

export const convertSpeedToBeaufort = (value: number) => {
  /*
  0    Calm                <1    <1    <1    Smoke rises vertically
  1    Light Air           1-5    1-3    1-3    Direction shown by smoke drift but not by wind vanes
  2    Light Breeze        6-11    4-7    4-6    Wind felt on face; leaves rustle; wind vane moved by wind
  3    Gentle Breeze       12-19    8-12    7-10    Leaves and small twigs in constant motion; light flags extended
  4    Moderate Breeze     20-28    13-18    11-16    Raises dust and loose paper; small branches moved.
  5    Fresh Breeze        29-38    19-24    17-21    Small trees in leaf begin to sway; crested wavelets form on inland waters.
  6    Strong Breeze       38-49    25-31    22-27    Large branches in motion; whistling heard in telegraph wires; umbrellas used with difficulty.
  7    Near Gale           50-61    32-38    28-33    Whole trees in motion; inconvenience felt when walking against the wind.
  8    Gale                62-74        39-46    34-40    Twigs break off trees; generally impedes progress.
  9    Strong Gale         75-88    47-54    41-47    Slight structural damage (chimney pots and slates removed).
  10    Storm              89-102    55-63    48-55    Seldom experienced inland; trees uprooted; considerable structural damage
  11    Violent Storm      103-117    64-72    56-63    Very rarely experienced; accompanied by widespread damage.
  12    Hurricane          118 plus    73 plus    64 plus    Devastation''' */
  if (value < 1) {
    return 0;
  }
  if (value < 6) {
    return 1;
  }
  if (value < 12) {
    return 2;
  }
  if (value < 20) {
    return 3;
  }
  if (value < 29) {
    return 4;
  }
  if (value < 38) {
    return 5;
  }
  if (value < 50) {
    return 6;
  }
  if (value < 62) {
    return 7;
  }
  if (value < 75) {
    return 8;
  }
  if (value < 89) {
    return 9;
  }
  if (value < 103) {
    return 10;
  }
  if (value < 118) {
    return 11;
  }
  return 12;
};

export const convertSpeedToKnots = (value: number) => value * 0.54;

// PRESSURE
export const MBAR = 0;
export const IN_HG = 1;
export const MM_HG = 2;

export const getPressureUnitLabel = ({SLP}: {SLP: number}) => {
  let label;
  switch (SLP) {
    case MBAR:
      label = 'mb';
      break;
    case IN_HG:
      label = 'inHg';
      break;
    case MM_HG:
      label = 'mmHg';
      break;
    default:
      label = 'mb';
      break;
  }
  return label;
};

export const getPressureUnitChoices = () => ['mb', 'inHg', 'mmHg'];

export const convertPressureToInHg = (value: number) => value * 0.02953;

export const convertPressureToMmHg = (value: number) => value / 1.3332239;

// FEEL_LIKE ALGO
export const HUMIDEX = 0;
export const HEAT_INDEX = 1;
export const AUSTRALIAN_FORMULA = 2;

export const getFeelLikeAlgoChoices = () => [
  'humidex',
  'heatIndex',
  'australianFormula',
];

// DISTANCE
export const METER_KILOMETER = 0;
export const FOOT_MILE = 1;

export const convertAltitudeToFeet = (value: number) => {
  // meters to feet
  return value * 3.2808;
};

export const convertAltitudeToUserUnit = (
  units: Units,
  value?: number | null,
) => {
  if (units.DISTANCE === FOOT_MILE) {
    return convertAltitudeToFeet(value);
  } else {
    return value;
  }
};

export const getAltitudeUnitLabel = ({DISTANCE}: {DISTANCE: number}) => {
  let label;
  switch (DISTANCE) {
    case METER_KILOMETER:
      label = 'm';
      break;
    case FOOT_MILE:
      label = 'ft';
      break;
    default:
      label = 'm';
      break;
  }
  return label;
};

export const convertDistanceToMiles = (value: number) => {
  // km to miles
  return value / 1.609344;
};

export const convertDistanceToUserUnit = (
  units: Units,
  value?: number | null,
) => {
  if (units.DISTANCE === FOOT_MILE) {
    return convertDistanceToMiles(value);
  } else {
    return value;
  }
};

export const getDistanceUnitLabel = ({DISTANCE}: {DISTANCE: number}) => {
  let label: string;
  switch (DISTANCE) {
    case METER_KILOMETER:
      label = 'km';
      break;
    case FOOT_MILE:
      label = 'mi';
      break;
    default:
      label = 'km';
      break;
  }
  return label;
};

// Defined Default Decimal precision
const DEFAULT_PRECISION_MAPPING = {
  T: {},
  RR: {},
  FF: {},
  SLP: {},
  DISTANCE: {},
} as {
  [key: string]: {[key: number]: number};
};
DEFAULT_PRECISION_MAPPING.T[CELSIUS] = 1;
DEFAULT_PRECISION_MAPPING.T[FAHRENHEIT] = 1;
DEFAULT_PRECISION_MAPPING.T[KELVIN] = 1;

DEFAULT_PRECISION_MAPPING.RR[MM] = 1;
DEFAULT_PRECISION_MAPPING.RR[INCH] = 3;

DEFAULT_PRECISION_MAPPING.FF[KPH] = 0;
DEFAULT_PRECISION_MAPPING.FF[MPH] = 0;
DEFAULT_PRECISION_MAPPING.FF[MS] = 1;
DEFAULT_PRECISION_MAPPING.FF[KNOT] = 1;
DEFAULT_PRECISION_MAPPING.FF[BEAUFORT] = 0;

DEFAULT_PRECISION_MAPPING.SLP[MBAR] = 1;
DEFAULT_PRECISION_MAPPING.SLP[IN_HG] = 2;
DEFAULT_PRECISION_MAPPING.SLP[MM_HG] = 1;

DEFAULT_PRECISION_MAPPING.DISTANCE[METER_KILOMETER] = 1;
DEFAULT_PRECISION_MAPPING.DISTANCE[FOOT_MILE] = 1;

export const convertTemperatureValue = (
  value: number,
  unit: TemperatureUnit,
  precision: number = null,
) => {
  if (unit === CELSIUS) {
    return +value.toFixed(precision == null ? 1 : precision);
  }
  if (unit === FAHRENHEIT) {
    return +convertToFahrenheit(value).toFixed(
      precision == null ? 1 : precision,
    );
  }
  // KELVIN
  return +convertToKelvin(value).toFixed(precision == null ? 1 : precision);
};

export const convertRainValue = (
  value: number,
  unit: RainUnit,
  precision: number = null,
) => {
  if (unit === INCH) {
    return +convertToInches(value).toFixed(precision === null ? 3 : precision);
  }
  // MM
  return +value.toFixed(precision === null ? 1 : precision);
};

export const convertSpeedValue = (
  value: number,
  unit: SpeedUnit,
  precision: number = null,
) => {
  if (unit === KPH) {
    return +value.toFixed(precision === null ? 0 : precision);
  }
  if (unit === MPH) {
    return +convertSpeedToMph(value).toFixed(
      precision === null ? 0 : precision,
    );
  }
  if (unit === MS) {
    return +convertSpeedToMps(value).toFixed(
      precision === null ? 1 : precision,
    );
  }
  if (unit === KNOT) {
    return +convertSpeedToKnots(value).toFixed(
      precision === null ? 1 : precision,
    );
  }
  // BEAUFORT
  return +convertSpeedToBeaufort(value);
};

export const convertPressureValue = (
  value: number,
  unit: PressureUnit,
  precision: number = null,
) => {
  if (unit === IN_HG) {
    return +convertPressureToInHg(value).toFixed(
      precision === null ? 2 : precision,
    );
  }
  if (unit === MM_HG) {
    return +convertPressureToMmHg(value).toFixed(
      precision === null ? 1 : precision,
    );
  }
  // MBAR
  return +value.toFixed(precision === null ? 1 : precision);
};

export const convertDistanceValue = (
  value: number,
  unit: DistanceUnit,
  precision: number = null,
) => {
  if (unit === FOOT_MILE) {
    return +convertDistanceToMiles(value / 1000).toFixed(
      precision === null ? 1 : precision,
    );
  }
  // METER_KILOMETER
  return +(value / 1000).toFixed(precision === null ? 1 : precision);
};

export const convertDegreeDayValue = (
  value: number,
  unit: TemperatureUnit,
  precision: number = null,
) => {
  if (unit === FAHRENHEIT) {
    return +convertDegreeDayToFahrenheit(value).toFixed(
      precision == null ? 1 : precision,
    );
  }
  // CELSIUS
  return +value.toFixed(precision == null ? 1 : precision);
};

export const convertValue = (
  value: number,
  dataType: string,
  units: Units,
  precision: number = null,
) => {
  if (value === null || typeof value === 'undefined') {
    return null;
  }

  if (['Temperature', 'T'].includes(dataType)) {
    return convertTemperatureValue(value, units.T, precision);
  }
  if (['Rain', 'RR', 'RR_1H'].includes(dataType)) {
    return convertRainValue(value, units.RR, precision);
  }
  if (
    ['Wind', 'WindStrength', 'GustStrength', 'FF', 'FXY'].includes(dataType)
  ) {
    return convertSpeedValue(value, units.FF, precision);
  }
  if (['Pressure', 'SLP'].includes(dataType)) {
    return convertPressureValue(value, units.SLP, precision);
  }
  if (['VIS', 'WIND_RUN'].includes(dataType)) {
    return convertDistanceValue(value, units.DISTANCE, precision);
  }

  if (['HeatingDegreeDay', 'CoolingDegreeDay'].includes(dataType)) {
    return convertDegreeDayValue(value, units.T, precision);
  }

  if (['UV'].includes(dataType)) {
    // truncate values to first decimal
    return +value.toFixed(1);
  }

  if (
    [
      'Humidity',
      'Noise',
      'CO2',
      'NOISE',
      'Noise',
      'U',
      'SOLAR_RADIATION',
    ].includes(dataType)
  ) {
    // truncate values to integer part
    return +value.toFixed(0);
  }

  if (['BoilerDuration'].includes(dataType)) {
    return +(value / 3600).toFixed(precision == null ? 0 : precision);
  }

  // Anything else
  return +value.toFixed(precision || 0);
};

export const convertValueToString = (
  value: number,
  dataType: string,
  units: Units,
  precision: number = null,
) => {
  const convertedValue = convertValue(value, dataType, units, precision);

  if (convertedValue === null) {
    return '-';
  }

  // Handle Rain differently
  if (['Rain', 'RR', 'RR_1H'].includes(dataType)) {
    if (convertedValue !== 0.0) {
      if (units.RR === INCH) {
        if (convertedValue.toString() === '0.000') {
          return '<0.001';
        }
      } else if (convertedValue.toString() === '0.0') {
        return '<0.1';
      }
    }
    return convertedValue.toFixed(
      precision === null ? DEFAULT_PRECISION_MAPPING.RR[units.RR] : precision,
    );
  }

  // All other non-null metrics
  if (precision !== null) {
    return convertedValue.toFixed(precision);
  }

  if (
    ['Temperature', 'T', 'HeatingDegreeDay', 'CoolingDegreeDay'].includes(
      dataType,
    )
  ) {
    return convertedValue.toFixed(DEFAULT_PRECISION_MAPPING.T[units.T]);
  }
  if (
    ['Wind', 'WindStrength', 'GustStrength', 'FF', 'FXY'].includes(dataType)
  ) {
    return convertedValue.toFixed(DEFAULT_PRECISION_MAPPING.FF[units.FF]);
  }
  if (['Pressure', 'SLP'].includes(dataType)) {
    return convertedValue.toFixed(DEFAULT_PRECISION_MAPPING.SLP[units.SLP]);
  }
  if (['VIS'].includes(dataType)) {
    return convertedValue.toFixed(
      DEFAULT_PRECISION_MAPPING.DISTANCE[units.DISTANCE],
    );
  }

  if (['UV'].includes(dataType)) {
    return convertedValue.toFixed(1);
  }

  // All other metrics are integers
  return convertedValue.toFixed(0);
};

export const getPolarAngleFromDegrees = (value: number) => {
  if (value === null) {
    return null;
  }
  const normalized = value % 360;
  const half = 11.5;
  if (normalized < 23 - half) {
    return 'N';
  }
  if (normalized < 45 - half) {
    return 'NNE';
  }
  if (normalized < 68 - half) {
    return 'NE';
  }
  if (normalized < 90 - half) {
    return 'ENE';
  }
  if (normalized < 113 - half) {
    return 'E';
  }
  if (normalized < 135 - half) {
    return 'ESE';
  }
  if (normalized < 158 - half) {
    return 'SE';
  }
  if (normalized < 180 - half) {
    return 'SSE';
  }
  if (normalized < 203 - half) {
    return 'S';
  }
  if (normalized < 225 - half) {
    return 'SSW';
  }
  if (normalized < 248 - half) {
    return 'SW';
  }
  if (normalized < 270 - half) {
    return 'WSW';
  }
  if (normalized < 293 - half) {
    return 'W';
  }
  if (normalized < 313 - half) {
    return 'WNW';
  }
  if (normalized < 336 - half) {
    return 'NW';
  }
  if (normalized < 360 - half) {
    return 'NNW';
  }
  return 'N';
};

export const getWindIconFromDegrees = (value: number) =>
  `from-${getPolarAngleFromDegrees(value).toLowerCase()}`;

export const temperatureScale = chroma
  .scale([
    '#fd00ff',
    '#0000CD',
    '#0084ff',
    '#00fff4',
    '#63ed09',
    '#ffff00',
    '#FFA500',
    '#ff004d',
  ])
  .domain([-20, 40])
  .classes(60);

const range = (start: number, end: number, step = 0.5) => {
  const length = Math.floor((end - start) / step) + 1;
  const myArray = [];
  for (let i = 0; i <= length; i++) {
    myArray.push(start + i * step);
  }
  return myArray;
};

export const getTempColour = (value: number) => {
  return temperatureScale(value).hex();
};

export const temperatureColors = range(-20, 40, 1).map((value) =>
  getTempColour(value),
);

export const getHeatingDegreeDayColour = (value: number) => {
  return chroma.scale('Blues').domain([0, 30]).classes(30)(value).hex();
};

export const getCoolingDegreeDayColour = (value: number) => {
  return chroma.scale('Reds').domain([0, 30]).classes(30)(value).hex();
};

export const getMonthlyHeatingDegreeDayColour = (value: number) => {
  return chroma
    .scale('Blues')
    .domain([0, 30 * 30])
    .classes(30)(value)
    .hex();
};

export const getMonthlyCoolingDegreeDayColour = (value: number) => {
  return chroma
    .scale('Reds')
    .domain([0, 30 * 30])
    .classes(30)(value)
    .hex();
};

export const getCO2Color = (value: number) => {
  return chroma
    .scale(['#63ed09', '#ffff00', '#FFA500', '#ff004d'])
    .domain([200, 1800])
    .classes(8)(value)
    .hex();
};

export const getNoiseColor = (value: number) => {
  return chroma
    .scale(['#63ed09', '#ffff00', '#FFA500', '#ff004d'])
    .domain([40, 60])
    .classes(10)(value)
    .hex();
};

export const getHumidityColor = (value: number) => {
  if (value < 10) {
    return '#FF0000';
  }
  if (value < 12) {
    return '#FF0a00';
  }
  if (value < 14) {
    return '#FF1400';
  }
  if (value < 16) {
    return '#FF1e00';
  }
  if (value < 12) {
    return '#FF2800';
  }
  if (value < 14) {
    return '#FF3200';
  }
  if (value < 16) {
    return '#FF3c00';
  }
  if (value < 18) {
    return '#FF4600';
  }
  if (value < 20) {
    return '#FF5000';
  }
  if (value < 22) {
    return '#FF5a00';
  }
  if (value < 24) {
    return '#FF6400';
  }
  if (value < 26) {
    return '#FF6e00';
  }
  if (value < 28) {
    return '#FF7800';
  }
  if (value < 30) {
    return '#FF8200';
  }
  if (value < 32) {
    return '#FF8c00';
  }
  if (value < 34) {
    return '#FF9600';
  }
  if (value < 36) {
    return '#FFa000';
  }
  if (value < 38) {
    return '#FFaa00';
  }
  if (value < 40) {
    return '#FFb400';
  }
  if (value < 42) {
    return '#FFbe00';
  }
  if (value < 44) {
    return '#FFc800';
  }
  if (value < 46) {
    return '#FFd200';
  }
  if (value < 48) {
    return '#FFdc00';
  }
  if (value < 50) {
    return '#FFe600';
  }
  if (value < 52) {
    return '#FFf000';
  }
  if (value < 54) {
    return '#FFfa00';
  }
  if (value < 56) {
    return '#fdff00';
  }
  if (value < 58) {
    return '#d7ff00';
  }
  if (value < 60) {
    return '#b0ff00';
  }
  if (value < 62) {
    return '#8aff00';
  }
  if (value < 64) {
    return '#65ff00';
  }
  if (value < 66) {
    return '#3eff00';
  }
  if (value < 68) {
    return '#17ff00';
  }
  if (value < 70) {
    return '#00ff10';
  }
  if (value < 72) {
    return '#00ff36';
  }
  if (value < 74) {
    return '#00ff5c';
  }
  if (value < 76) {
    return '#00ff83';
  }
  if (value < 78) {
    return '#00ffa8';
  }
  if (value < 80) {
    return '#00ffd0';
  }
  if (value < 82) {
    return '#00fff4';
  }
  if (value < 84) {
    return '#00e4ff';
  }
  if (value < 86) {
    return '#00d4ff';
  }
  if (value < 88) {
    return '#00c4ff';
  }
  if (value < 90) {
    return '#00b4ff';
  }
  if (value < 91) {
    return '#00a4ff';
  }
  if (value < 92) {
    return '#0094ff';
  }
  if (value < 93) {
    return '#0084ff';
  }
  if (value < 94) {
    return '#0074ff';
  }
  if (value < 95) {
    return '#0064ff';
  }
  if (value < 96) {
    return '#0054ff';
  }
  if (value < 97) {
    return '#0044ff';
  }
  if (value < 98) {
    return '#0032ff';
  }
  if (value < 99) {
    return '#0022ff';
  }
  if (value < 100) {
    return '#0012ff';
  }
  return '#0002ff';
};

export const getRainColor = (value: number) => {
  if (value < 0.01) {
    return chroma('#EAEAEA').alpha(0.5).hex();
  }
  if (value < 1) {
    return '#00fff4';
  }
  if (value < 2) {
    return '#00e4ff';
  }
  if (value < 3) {
    return '#00d4ff';
  }
  if (value < 4) {
    return '#00c4ff';
  }
  if (value < 5) {
    return '#00b4ff';
  }
  if (value < 6) {
    return '#00a4ff';
  }
  if (value < 7) {
    return '#0094ff';
  }
  if (value < 8) {
    return '#0084ff';
  }
  if (value < 9) {
    return '#0074ff';
  }
  if (value < 10) {
    return '#0064ff';
  }
  if (value < 11) {
    return '#0054ff';
  }
  if (value < 12) {
    return '#0044ff';
  }
  if (value < 13) {
    return '#0032ff';
  }
  if (value < 14) {
    return '#0022ff';
  }
  if (value < 15) {
    return '#0012ff';
  }
  if (value < 16) {
    return '#0002ff';
  }
  if (value < 17) {
    return '#0000ff';
  }
  if (value < 18) {
    return '#0100ff';
  }
  if (value < 19) {
    return '#0200ff';
  }
  if (value < 20) {
    return '#0300ff';
  }
  if (value < 21) {
    return '#0400ff';
  }
  return '#0500ff';
};

export const getMonthlyRainColor = (value: number) => {
  if (value < 0.01) {
    return chroma('#EAEAEA').alpha(0.5).hex();
  }
  if (value < 5) {
    return '#00fff4';
  }
  if (value < 10) {
    return '#00e4ff';
  }
  if (value < 15) {
    return '#00d4ff';
  }
  if (value < 20) {
    return '#00c4ff';
  }
  if (value < 25) {
    return '#00b4ff';
  }
  if (value < 30) {
    return '#00a4ff';
  }
  if (value < 35) {
    return '#0094ff';
  }
  if (value < 40) {
    return '#0084ff';
  }
  if (value < 45) {
    return '#0074ff';
  }
  if (value < 50) {
    return '#0064ff';
  }
  if (value < 55) {
    return '#0054ff';
  }
  if (value < 60) {
    return '#0044ff';
  }
  if (value < 65) {
    return '#0032ff';
  }
  if (value < 70) {
    return '#0022ff';
  }
  if (value < 75) {
    return '#0012ff';
  }
  if (value < 80) {
    return '#0002ff';
  }
  if (value < 85) {
    return '#0000ff';
  }
  if (value < 90) {
    return '#0100ff';
  }
  if (value < 95) {
    return '#0200ff';
  }
  if (value < 100) {
    return '#0300ff';
  }
  if (value < 105) {
    return '#0400ff';
  }
  return '#0500ff';
};

export const getBoilerColor = (value: number) => {
  return chroma
    .scale(['#fbf9ed', '#CC4002'])
    .domain([0, 80])
    .gamma(0.5)
    .correctLightness()
    .classes(15)(value)
    .hex();
};

export const getMonthlyBoilerColor = (value: number) => {
  return chroma
    .scale(['#fbf9ed', '#CC4002'])
    .domain([0, 60])
    .gamma(0.5)
    .correctLightness()
    .classes(10)(value)
    .hex();
};

export const getBoilerDurationColor = (value: number) => {
  return chroma
    .scale(['#fbf9ed', '#CC4002'])
    .domain([0, 0.8 * 60 * 60 * 24])
    .gamma(0.5)
    .correctLightness()
    .classes(15)(value)
    .hex();
};

export const getMonthlyBoilerDurationColor = (value: number) => {
  return chroma
    .scale(['#fbf9ed', '#CC4002'])
    .domain([0, 0.6 * 60 * 60 * 24 * 30])
    .gamma(0.5)
    .correctLightness()
    .classes(10)(value)
    .hex();
};

export const getWindColor = (v: number) => {
  const value = convertSpeedToBeaufort(v);
  if (value === 0) {
    return '#00ff83';
  }
  if (value === 1) {
    return '#00ff10';
  }
  if (value === 2) {
    return '#65ff00';
  }
  if (value === 3) {
    return '#d7ff00';
  }
  if (value === 4) {
    return '#FFf000';
  }
  if (value === 5) {
    return '#FFd200';
  }
  if (value === 6) {
    return '#FFb400';
  }
  if (value === 7) {
    return '#FF9600';
  }
  if (value === 8) {
    return '#FF7800';
  }
  if (value === 9) {
    return '#FF5a00';
  }
  if (value === 10) {
    return '#FF3c00';
  }
  if (value === 11) {
    return '#FF1e00';
  }
  if (value === 12) {
    return '#FF0000';
  }
  return '#FF0000';
};

export const getPressureColor = (value: number) => {
  if (value < 970) {
    return '#EB41B7';
  }
  if (value < 980) {
    return '#EA408C';
  }
  if (value < 990) {
    return '#EA3F60';
  }
  if (value < 1000) {
    return '#E9473E';
  }
  if (value < 1005) {
    return '#E9713D';
  }
  if (value < 1010) {
    return '#E99B3B';
  }
  if (value < 1015) {
    return '#E8C53A';
  }
  if (value < 1020) {
    return '#E0E839';
  }
  if (value < 1025) {
    return '#B4E738';
  }
  if (value < 1030) {
    return '#87E737';
  }
  return '#5BE735';
};

export const getSolarRadiationColour = (value: number) => {
  return chroma
    .scale([
      '#000066',
      '#0000CD',
      '#0084ff',
      '#00fff4',
      '#63ed09',
      '#ffff00',
      '#FFA500',
      '#ff004d',
    ])
    .domain([0, 1200])
    .classes(60)(value)
    .hex();
};

export const getUVColor = (value: number) => {
  if (value < 2) {
    return '#1AAA51';
  }
  if (value < 6) {
    return '#FBC333';
  }
  if (value < 8) {
    return '#F99537';
  }
  if (value < 11) {
    return '#EB4F31';
  }
  return '#EB41B7';
};

export const getColor = (type: string, value: number) => {
  if (['Temperature', 'T'].includes(type)) {
    return getTempColour(value);
  }
  if (type === 'CO2') {
    return getCO2Color(value);
  }
  if (['Humidity', 'U'].includes(type)) {
    return getHumidityColor(value);
  }
  if (['Rain', 'RR', 'RR_1H'].includes(type)) {
    return getRainColor(value);
  }
  if (['Wind', 'FF', 'FXY'].includes(type)) {
    return getWindColor(value);
  }
  if (type === 'Noise') {
    return getNoiseColor(value);
  }
  if (['Pressure', 'SLP'].includes(type)) {
    return getPressureColor(value);
  }
  if (type === 'Boiler') {
    return getBoilerColor(value);
  }
  if (type === 'HeatingDegreeDay') {
    return getHeatingDegreeDayColour(value);
  }
  if (type === 'CoolingDegreeDay') {
    return getCoolingDegreeDayColour(value);
  }
  if (type === 'SOLAR_RADIATION') {
    return getSolarRadiationColour(value);
  }
  if (type === 'UV') {
    return getUVColor(value);
  }
  if (type === 'BoilerDuration') {
    return getBoilerDurationColor(value);
  }

  return '#000000';
};

export const getMonthlyColor = (type: string, value: number) => {
  if (['Rain', 'RR', 'RR_1H'].includes(type)) {
    return getMonthlyRainColor(value);
  }
  if (type === 'Boiler') {
    return getMonthlyBoilerColor(value);
  }
  if (type === 'HeatingDegreeDay') {
    return getMonthlyHeatingDegreeDayColour(value);
  }
  if (type === 'CoolingDegreeDay') {
    return getMonthlyCoolingDegreeDayColour(value);
  }
  if (type === 'BoilerDuration') {
    return getMonthlyBoilerDurationColor(value);
  }
  return getColor(type, value);
};

export const getUnitLabel = (units: Units, type: string) => {
  if (['Temperature', 'T'].includes(type)) {
    return getTemperatureUnitLabel(units);
  }
  if (['Rain', 'RR', 'RR_1H'].includes(type)) {
    return getRainUnitLabel(units);
  }
  if (['Wind', 'FF', 'FXY'].includes(type)) {
    return getWindUnitLabel(units);
  }
  if (type === 'CO2') {
    return 'ppm';
  }
  if (['Noise', 'NOISE'].includes(type)) {
    return 'dB';
  }
  if (['Humidity', 'U'].includes(type)) {
    return '%';
  }
  if (['Pressure', 'SLP'].includes(type)) {
    return getPressureUnitLabel(units);
  }
  if (type === 'SOLAR_RADIATION') {
    return 'W/m²';
  }
  if (type === 'SOLAR_IRRADIATION_1H') {
    return 'kJ/m²';
  }
  if (type === 'VIS') {
    return getDistanceUnitLabel(units);
  }
  return '';
};
