import {ThemeProvider as MuiThemeProvider} from '@emotion/react';
import type {} from '@mui/lab/themeAugmentation';
import {createTheme} from '@mui/material';
import {LinkProps} from '@mui/material/Link';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {useUserLanguage} from '@smartmixin/core';
import React, {PropsWithChildren} from 'react';
import {useSelector} from 'react-redux';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import {selectIsDarkTheme} from '../store/prefs/selectors';

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & {href: RouterLinkProps['to']}
>((props, ref) => {
  const {href, ...other} = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const ThemeProvider = ({children}: PropsWithChildren) => {
  const isDarkTheme = useSelector(selectIsDarkTheme);
  const language = useUserLanguage();

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          button: {
            textTransform: 'none',
          },
          fontFamily: 'Inter',
        },
        palette: {
          mode: isDarkTheme ? 'dark' : 'light',
          primary: {main: '#33B5E5'},
          secondary: {main: '#fb5f26'},
          hot: {main: '#f13a59'},
          cold: {main: '#33B5E5'},
          rain: {main: '#33B5E5'},
          background: {
            paper: isDarkTheme ? 'rgb(11, 13, 14)' : 'rgb(251, 252, 254)',
            default: isDarkTheme ? '#000' : '#FFF',
          },
        },
        shape: {
          borderRadius: 8,
        },
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
        },
      }),
    [isDarkTheme],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default ThemeProvider;
