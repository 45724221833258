import {ScheduleType} from './types';

export default class Schedule {
  data: ScheduleType;
  id: string;

  constructor(data: ScheduleType) {
    this.data = data;
    this.id = data.id;
  }

  getName() {
    return this.data.name;
  }

  getZoneList() {
    return this.data.zones;
  }

  getTimetable() {
    return this.data.timetable;
  }

  isSelected() {
    return !!this.data.selected;
  }
}
