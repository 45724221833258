import BaseMoment from 'moment';
import {DateRange, extendMoment} from 'moment-range';
import moment, {Moment} from 'moment-timezone';
import * as Time from '../Time';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const momentRange = extendMoment(BaseMoment);

export type DateTimeConfig = {
  is_24_hour: boolean;
  month_format: string;
};

export const hourFormatter = ({is_24_hour}: DateTimeConfig) =>
  is_24_hour ? 'HH:mm' : 'hh:mm A';

export const DAY = 'day';
export const WEEK = 'week';
export const TEN_DAYS = 'tenDays';
export const MONTH = 'month';
export const YEAR = 'year';
export const LAST_24_HOURS = 'last24Hours';
export const LAST_7_DAYS = 'last7Days';
export const LAST_30_DAYS = 'last30Days';

export const LAST_SCALE_CHOICES = [
  LAST_24_HOURS,
  LAST_7_DAYS,
  LAST_30_DAYS,
] as Time.Scale[];

export const PREVIOUS_DAY = 'previousDay';
export const SAME_DAY_LAST_YEAR = 'sameDayLastYear';
export const PREVIOUS_WEEK = 'previousWeek';
export const PREVIOUS_MONTH = 'previousMonth';
export const SAME_MONTH_LAST_YEAR = 'sameMonthLastYear';
export const PREVIOUS_YEAR = 'previousYear';
export const PREVIOUS_24_HOURS = 'previous24Hours';
export const PREVIOUS_7_DAYS = 'previous7Days';
export const PREVIOUS_30_DAYS = 'previous30Days';

export const setLocale = (locale: string) => {
  const momentLocale = locale === 'sr' ? 'sr-cyrl' : locale;

  const loadLocale = async (l: string) => {
    switch (l) {
      case 'bg':
        await import('./locale/bg.js');
        break;
      case 'cs':
        await import('./locale/cs.js');
        break;
      case 'da':
        await import('./locale/da.js');
        break;
      case 'de':
        await import('./locale/de.js');
        break;
      case 'es':
        await import('./locale/es.js');
        break;
      case 'fr':
        await import('./locale/fr.js');
        break;
      case 'hr':
        await import('./locale/hr.js');
        break;
      case 'hu':
        await import('./locale/hu.js');
        break;
      case 'it':
        await import('./locale/it.js');
        break;
      case 'ja':
        await import('./locale/ja.js');
        break;
      case 'ko':
        await import('./locale/ko.js');
        break;
      case 'nb':
        await import('./locale/nb.js');
        break;
      case 'nl':
        await import('./locale/nl.js');
        break;
      case 'pl':
        await import('./locale/pl.js');
        break;
      case 'pt':
        await import('./locale/pt.js');
        break;
      case 'ru':
        await import('./locale/ru.js');
        break;
      case 'sk':
        await import('./locale/sk.js');
        break;
      case 'sr-cyrl':
        await import('./locale/sr-cyrl.js');
        break;
      case 'sv':
        await import('./locale/sv.js');
        break;
      case 'uk':
        await import('./locale/uk.js');
        break;
      default:
        await Promise.resolve();
    }
  };

  loadLocale(momentLocale).then(() => {
    moment.locale(momentLocale);
  });
};

export const setTimeDifferenceThresholds = () => {
  // Change threshold for dates (see https://stackoverflow.com/questions/17473300/moment-js-change-the-fromnow-time-range)
  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('ss', 0);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 60);
  moment.relativeTimeThreshold('d', 24);
  moment.relativeTimeThreshold('M', 12);
};

export const isoToTimestamp = (iso: string) => {
  const date = new Date(iso);
  return moment(date).unix();
};

export const isTimestampToday = (timestamp: number, timezone: string) =>
  dayStart(timestamp, timezone) === dayStart(now(), timezone);

export const timestampToDateString = (timestamp: number) => {
  const m = moment.unix(timestamp);
  return m.format('LL');
};

export const timestampToMoment = (timestamp: number, timezone: string) =>
  moment.tz(timestamp * 1000, timezone);

export const momentToTimestamp = (m: Moment) => m.unix();

export const timestampToCalendarString = (timestamp: number) => {
  const m = moment.unix(timestamp);
  return m.calendar();
};

export const dateStringToLocal = (dateString: string, monthFormat: string) => {
  const m = moment(dateString, 'YYYY-MM-DD');
  return [m.format('ddd'), m.format(monthFormat)].join('\n');
};

export const monthStringToLocal = (monthString: string) => {
  const m = moment(monthString, 'M');
  return m.format('MMM')[0].toUpperCase();
};

export const parseString = (datetimeString: string) => moment(datetimeString);

export const now = () => moment().unix();

export const timestampFromMonth = (
  month: number,
  year: number,
  timezone: string,
) => {
  return moment.tz({year, month: month - 1, day: 1}, timezone).unix();
};

export const timestampFromYear = (year: number, timezone: string) => {
  // 1st June of the year
  return moment.tz({year, month: 6 - 1, day: 1}, timezone).unix();
};

export const currentYear = (timezone: string) => moment().tz(timezone).year();

export const currentMonth = (timezone: string) =>
  moment().tz(timezone).month() + 1;

export const nextMonth = (year: number, month: number) => {
  const m = moment(`${year}-${month}`, 'YYYY-MM').add(1, 'months');
  return {
    year: m.year(),
    month: m.month() + 1,
  };
};

export const previousMonth = (year: number, month: number) => {
  const m = moment(`${year}-${month}`, 'YYYY-MM').subtract(1, 'months');
  return {
    year: m.year(),
    month: m.month() + 1,
  };
};

export const currentDay = (timezone: string) => moment().tz(timezone).day();

export const differenceWithNow = (
  timestamp: number,
  floor: null | 'hour' | 'day' = null,
) => {
  if (floor !== null) {
    return moment(timestamp * 1000).diff(moment().startOf(floor)) / 1000;
  }
  const diff = moment(timestamp * 1000).diff(moment()) / 1000;
  return diff;
};

export const formatTimeDifference = (timestamp: number) => {
  if (timestamp) {
    return moment(timestamp * 1000).fromNow();
  }
  return '-';
};

export const timezoneDateFromTimestamp = (
  timestamp: number,
  timezone: string,
) => moment.tz(timestamp * 1000, timezone);

export const shift = (
  timestamp: number,
  timezone: string,
  amount: number,
  unit: BaseMoment.unitOfTime.DurationConstructor,
) => {
  const m = moment.tz(timestamp * 1000, timezone);
  if (amount > 0) {
    return m.add(amount, unit).unix();
  } else {
    return m.subtract(Math.abs(amount), unit).unix();
  }
};

export const hourStart = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.startOf('hour').unix();
};

export const hourEnd = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.endOf('hour').unix();
};

export const dayStart = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.startOf('day').unix();
};

export const dayEnd = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.endOf('day').unix();
};

export const weekStart = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.startOf('isoWeek').unix();
};

export const weekEnd = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.endOf('isoWeek').unix();
};

export const monthStart = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.startOf('month').unix();
};

export const monthEnd = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.endOf('month').unix();
};

export const yearStart = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.startOf('year').unix();
};

export const yearEnd = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.endOf('year').unix();
};

export const extractMonth = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.month();
};

export const extractYear = (timestamp: number, timezone: string) => {
  const m = moment.tz(timestamp * 1000, timezone);
  return m.year();
};

export const applyTimezoneOffset = (timestamp: number, timezone: string) => {
  const offset = moment.tz.zone(timezone).offset(timestamp) * 60;
  return timestamp - offset;
};

export const formatDate = (
  timestamp: number,
  timezone: string,
  formatter: string,
) => {
  if (timestamp) {
    const m = timezoneDateFromTimestamp(timestamp, timezone);
    return m.format(formatter);
  }
  return '-';
};

export const formatMonth = (year: number, month: number) => {
  const m = moment(`${year}-${month}`, 'YYYY-MM');
  return m.format('MMMM YYYY');
};

export const timeDiffUtcWithNowInMin = (timestamp: number) =>
  moment().diff(moment.unix(timestamp), 'minutes');

export const timeDiffUtcWithNowInSeconds = (timestamp: number) =>
  moment().diff(moment.unix(timestamp), 'seconds');

export const timeDiffWithMidnight = (timezone: string) => {
  const nowMoment = moment().tz(timezone);
  const beginningOfTheWeek = moment
    .tz(now() * 1000, timezone)
    .startOf('isoWeek');
  return nowMoment.diff(beginningOfTheWeek, 'minutes');
};

export const timestampAtWeekStart = (timezone: string) =>
  moment
    .tz(now() * 1000, timezone)
    .startOf('isoWeek')
    .unix();

export const daysInMonth = (year: number, month: number) =>
  moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

export const monthListShort = () => moment.monthsShort();

export const monthList = () => moment.months();

export const getPrevious = (
  timestamp: number,
  timezone: string,
  scale: string,
) => {
  const date = timezoneDateFromTimestamp(timestamp, timezone);
  if (scale === DAY) {
    return date.subtract(1, DAY).unix();
  }
  if (scale === WEEK) {
    return date.subtract(1, 'week').unix();
  }
  if (scale === MONTH) {
    return date.subtract(1, 'month').unix();
  }
  if (scale === YEAR) {
    return date.subtract(1, 'year').unix();
  }
  return -1;
};

export const getNext = (
  timestamp: number,
  timezone: string,
  scale: Time.Scale,
) => {
  const date = timezoneDateFromTimestamp(timestamp, timezone);
  if (scale === DAY) {
    return date.add(1, 'day').unix();
  }
  if (scale === WEEK) {
    return date.add(1, 'week').unix();
  }
  if (scale === MONTH) {
    return date.add(1, 'month').unix();
  }
  if (scale === YEAR) {
    return date.add(1, 'year').unix();
  }
  return -1;
};

export const getScaleStart = (
  timestamp: number,
  timezone: string,
  scale: string,
  extended: boolean = false,
) => {
  let start = 0;
  switch (scale) {
    case DAY:
      start = dayStart(
        extended ? getPrevious(timestamp, timezone, scale) : timestamp,
        timezone,
      );
      break;
    case 'last24Hours':
      start = shift(hourStart(now(), timezone), timezone, -23, 'hours');
      break;
    case 'week':
      start = weekStart(
        extended ? getPrevious(timestamp, timezone, scale) : timestamp,
        timezone,
      );
      break;
    case 'last7Days':
      start = shift(hourStart(now(), timezone), timezone, -7, 'days');
      break;
    case 'month':
      start = monthStart(
        extended ? getPrevious(timestamp, timezone, scale) : timestamp,
        timezone,
      );
      break;
    case 'last30Days':
      start = shift(dayStart(now(), timezone), timezone, -30, 'days');
      break;
    case 'year':
      start = yearStart(
        extended ? getPrevious(timestamp, timezone, scale) : timestamp,
        timezone,
      );
      break;
    default:
      start = 0;
  }
  return start;
};

export const getScaleEnd = (
  timestamp: number,
  timezone: string,
  scale: Time.Scale,
) => {
  let end = 0;
  switch (scale) {
    case DAY:
      end = dayEnd(timestamp, timezone);
      break;
    case 'last24Hours':
      end = hourEnd(now(), timezone);
      break;
    case 'week':
      end = weekEnd(timestamp, timezone);
      break;
    case 'last7Days':
      end = hourStart(now(), timezone);
      break;
    case 'month':
      end = monthEnd(timestamp, timezone);
      break;
    case 'last30Days':
      end = dayEnd(now(), timezone);
      break;
    case 'year':
      end = yearEnd(timestamp, timezone);
      break;
    default:
      end = 0;
  }
  return end;
};

export const formatTimestampFromScale = (
  timestamp: number,
  timezone: string,
  scale: Time.Scale,
) => {
  if (scale === DAY) {
    return formatDate(timestamp, timezone, 'll');
  }
  if ([WEEK, TEN_DAYS].includes(scale)) {
    return [
      formatDate(getScaleStart(timestamp, timezone, scale), timezone, 'll'),
      formatDate(getScaleEnd(timestamp, timezone, scale), timezone, 'll'),
    ].join(' - ');
  }
  if (scale === MONTH) {
    return formatDate(timestamp, timezone, 'MMMM YYYY');
  }
  if (scale === YEAR) {
    return formatDate(timestamp, timezone, 'YYYY');
  }
  return '';
};

export const getTodaySharpHours = (
  timestamp: number,
  timezone: string,
  formatter: string,
) => {
  const range = momentRange.range(
    moment(getScaleStart(timestamp, timezone, 'day') * 1000),
    moment(getScaleEnd(timestamp, timezone, 'day') * 1000),
  );

  const hours = Array.from(range.by('hour', {excludeEnd: false}));
  return hours.map((hour) => [hour.unix().toString(), hour.format(formatter)]);
};

export const groupByDay = (
  timestamp: number,
  timezone: string,
  monthFormat: string,
) => {
  const range = momentRange.range(
    moment.tz(getScaleStart(timestamp, timezone, 'week') * 1000, timezone),
    moment.tz(getScaleEnd(timestamp, timezone, 'week') * 1000, timezone),
  );
  const days = Array.from(range.by('day', {excludeEnd: false}));
  return days.map((m) => {
    const dayRange = momentRange.range(
      moment.tz(getScaleStart(m.unix(), timezone, 'day') * 1000, timezone),
      moment.tz(getScaleEnd(m.unix(), timezone, 'day') * 1000, timezone),
    );
    const hours = Array.from(dayRange.by('hour', {excludeEnd: false}));
    const dayTimestamps = hours.map((h) => h.unix().toString());
    return [[m.format('ddd'), m.format(monthFormat)].join('\n'), dayTimestamps];
  }) as [string, string[]][];
};

const byWeek = (range: DateRange, options = {excludeEnd: false, step: 1}) => {
  return {
    [Symbol.iterator]() {
      const step = options.step || 1;
      const wStart = range.start.startOf('isoWeek');
      const wEnd = range.end.endOf('isoWeek');
      const diff = Math.abs(wStart.diff(wEnd, 'week')) / step;
      const excludeEnd = options.excludeEnd || false;
      let iteration = 0;

      return {
        next() {
          const current = range.start
            .startOf('isoWeek')
            .clone()
            .add(iteration * step, 'week');
          const done = excludeEnd ? !(iteration < diff) : !(iteration <= diff);

          iteration++;

          return {
            done,
            value: done ? undefined : current,
          };
        },
      };
    },
  };
};

export const groupByWeek = (timestamp: number, timezone: string) => {
  const range = momentRange.range(
    moment.tz(getScaleStart(timestamp, timezone, 'month') * 1000, timezone),
    moment.tz(getScaleEnd(timestamp, timezone, 'month') * 1000, timezone),
  );
  const weeks = Array.from(byWeek(range));
  return weeks.map((m) => {
    const wStart = moment.tz(
      getScaleStart(m.unix(), timezone, 'week') * 1000,
      timezone,
    );
    const wEnd = moment.tz(
      getScaleEnd(m.unix(), timezone, 'week') * 1000,
      timezone,
    );
    const dayRange = momentRange.range(wStart, wEnd);
    const days = Array.from(dayRange.by('day', {excludeEnd: false}));
    const dayTimestamps = days.map((h) => h.unix().toString());
    return [m.week(), dayTimestamps];
  }) as [number, string[]][];
};

export const groupByMonth = (timestamp: number, timezone: string) => {
  const range = momentRange.range(
    moment.tz(getScaleStart(timestamp, timezone, 'year') * 1000, timezone),
    moment.tz(getScaleEnd(timestamp, timezone, 'year') * 1000, timezone),
  );
  const months = Array.from(range.by('month', {excludeEnd: false}));
  return months.map((m) => {
    const monthRange = momentRange.range(
      moment.tz(getScaleStart(m.unix(), timezone, 'month') * 1000, timezone),
      moment.tz(getScaleEnd(m.unix(), timezone, 'month') * 1000, timezone),
    );
    const days = Array.from(monthRange.by('day', {excludeEnd: false}));
    const dayTimestamps = days.map((h) => h.unix().toString());
    return [monthStringToLocal((m.month() + 1).toString()), dayTimestamps];
  });
};
