import {Navigate} from 'react-router-dom';
import {serviceRoutes} from './services/routes';

export const settingsRoutes = [
  {
    path: '',
    element: <Navigate to="preferences" replace />,
  },
  {
    path: 'account',
    lazy: () => import('../../../screens/settings/AccountScreen'),
  },
  {
    path: 'preferences',
    lazy: () => import('../../../screens/settings/PreferenceScreen'),
  },
  {
    path: 'services',
    children: serviceRoutes,
  },
  {
    path: '*',
    element: <Navigate to="preferences" replace />,
  },
];
