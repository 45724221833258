import {messages} from '@smartmixin/i18n';
import React from 'react';
import {useIntl} from 'react-intl';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Box from '../../../components/Box';
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';
import PageTitle from '../../../components/Typography/PageTitle';

const getRouteLabel = (pathname: string) => {
  if (pathname.includes('account')) {
    return 'account';
  }
  if (pathname.includes('services')) {
    return 'services';
  }
  return 'preferences';
};

const Component = () => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [routeLabel, setRouteLabel] = React.useState<string>(
    getRouteLabel(pathname),
  );

  React.useEffect(() => {
    setRouteLabel(getRouteLabel(pathname));
  }, [pathname]);

  return (
    <Box>
      <Box
        sx={{
          padding: (theme) => theme.spacing(2),
          paddingBottom: (theme) => theme.spacing(1),
        }}>
        <PageTitle>{formatMessage(messages.settings.settings)}</PageTitle>
      </Box>

      <Tabs value={routeLabel}>
        <Tab
          label={formatMessage(messages.settings.account)}
          onClick={() => {
            navigate('/app/settings/account');
          }}
          value={'account'}
        />

        <Tab
          label={'Preferences'}
          onClick={() => {
            navigate('/app/settings/preferences');
          }}
          value={'preferences'}
        />
        <Tab
          label={'Additional services'}
          onClick={() => {
            navigate('/app/settings/services');
          }}
          value={'services'}
        />
      </Tabs>

      <Outlet />
    </Box>
  );
};

Component.displayName = 'Settings';

export default Component;
