import * as Dates from '../Dates';
import NetatmoDevice from './NetatmoDevice';
import NetatmoModule from './NetatmoModule';
export default class ChartsDataContainer {
  chartsData: any;

  constructor(data) {
    this.chartsData = data;
  }

  getData() {
    return this.chartsData;
  }

  getChartData(
    elementId: string,
    metric: string | string[],
    scale: string,
    begin: number | null,
    end: number | null,
  ) {
    const paths =
      typeof metric === 'string'
        ? [this.getPath(elementId, metric, scale)]
        : Object.values(metric).map((item) => {
            return this.getPath(elementId, item, scale);
          });
    if (paths.every((path) => path in this.chartsData)) {
      const data = Object.keys(this.chartsData[paths[0]])
        .filter(
          (key) =>
            (begin === null || key >= begin.toString()) &&
            (end === null || key <= end.toString()),
        )
        .reduce((obj, item) => {
          obj[item] = Object.values(paths).map((path) => {
            return this.chartsData[path][item];
          });
          return obj;
        }, {});
      return data;
    }
    return {};
  }

  getFormattedChartData(
    elementId: string,
    metric: string | string[],
    scale: string,
    begin: number,
    end: number,
    format: string,
    timezone: string,
  ) {
    const data = this.getChartData(elementId, metric, scale, begin, end);
    return Object.keys(data).reduce((obj, item) => {
      obj[Dates.formatDate(parseInt(item, 10), timezone, format)] = data[item];
      return obj;
    }, {});
  }

  getPath(elementId: string, metric: string, scale: string) {
    return `${elementId}-${metric}-${scale}`;
  }

  update(data) {
    const obj = {};
    const newObj = {};
    Object.keys(data.results).forEach((metric) => {
      const path = this.getPath(data.element, metric, data.scale);
      // Fix a Netatmo problem of aggregation daylight saving time
      if (path in this.chartsData) {
        obj[path] = Object.keys(this.chartsData[path])
          .filter(
            (key) => key < data.begin.toString() || key > data.end.toString(),
          )
          .reduce((reducer, item) => {
            reducer[item] = this.chartsData[path][item];
            return reducer;
          }, {});
      } else {
        obj[path] = {};
      }
      // Create newData
      const newData = {};
      Object.entries(data.results[metric]).forEach(([i, value]) => {
        newData[data.timestamps[i]] = value;
      });

      newObj[path] = {};
      newObj[path] = {...obj[path], ...newData};
    });
    this.chartsData = {...this.chartsData, ...newObj};
  }

  getReportStart(year: number, month: number | null, timezone: string) {
    if (month !== null) {
      return Dates.getScaleStart(
        Dates.timestampFromMonth(month, year, timezone),
        timezone,
        'month',
      );
    } else {
      return Dates.getScaleStart(
        Dates.timestampFromYear(year, timezone),
        timezone,
        'year',
      );
    }
  }

  getReportEnd(year: number, month: number | null, timezone: string) {
    if (month !== null) {
      return Dates.getScaleEnd(
        Dates.timestampFromMonth(month, year, timezone),
        timezone,
        'month',
      );
    } else {
      return Dates.getScaleEnd(
        Dates.timestampFromYear(year, timezone),
        timezone,
        'year',
      );
    }
  }

  getModuleReport(
    device: NetatmoDevice,
    module: NetatmoModule,
    metrics: string[],
    year: number,
    month: number | null,
    formatter: string,
  ): Record<string, number[]> {
    const start = this.getReportStart(year, month, device.timezone);
    const end = this.getReportEnd(year, month, device.timezone);

    return typeof module !== 'undefined'
      ? this.getFormattedChartData(
          module.id,
          metrics,
          '1day',
          start,
          end,
          formatter,
          device.timezone,
        )
      : {};
  }

  getMonthlyModuleReport(
    device: NetatmoDevice,
    module: NetatmoModule,
    metrics: string[],
    year: number,
    month: number,
  ) {
    return this.getModuleReport(
      device,
      module,
      metrics,
      year,
      month,
      'YYYY/MM/DD',
    );
  }

  getAnnualModuleReport(
    device: NetatmoDevice,
    module: NetatmoModule,
    metrics: string[],
    year: number,
  ) {
    return this.getModuleReport(
      device,
      module,
      metrics,
      year,
      null,
      'YYYY/MM/DD',
    );
  }
}
