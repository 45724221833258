import {createSelector} from 'reselect';
import {CommonRootState} from '../types';

export const selectWundergroundConnectedAt = (state: CommonRootState) =>
  state.wunderground.link && state.wunderground.link.date_connected;

export const selectWundergroundConnected = (state: CommonRootState) =>
  state.wunderground.link !== null;

export const selectWundergroundActive = (state: CommonRootState) =>
  state.wunderground.link !== null && state.wunderground.link.account.active;

export const getWundergroundStationList = (state: CommonRootState) => {
  if (state.wunderground.link !== null) {
    return state.wunderground.link.account.weather_stations;
  }
  return [];
};

const getRawCurrentStationId = (state: CommonRootState) =>
  state.wunderground.currentStation;

export const getCurrentWundergroundStation = createSelector(
  getRawCurrentStationId,
  getWundergroundStationList,
  (stationId, stationList) => {
    if (stationList.length === 0) {
      return null;
    }
    const currentStation = stationId
      ? stationList.find((item) => item.device_id === stationId) ||
        stationList[0]
      : stationList[0];

    return currentStation;
  },
);
