import {createBrowserRouter} from 'react-router-dom';
import AppContainer from './AppContainer';
import {authRoutes} from './auth/routes';
import {dashboardRoutes} from './dashboard/routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppContainer />,
    children: [
      {
        path: '',
        lazy: () => import('../screens/LandingPage'),
      },
      {
        path: 'auth',
        children: authRoutes,
      },
      {
        path: 'map',
        lazy: () => import('../screens/WeatherMap'),
      },
      {
        path: 'app',
        lazy: () => import('./dashboard'),
        children: dashboardRoutes,
      },
      {
        path: 'terms-and-conditions',
        lazy: () => import('../screens/legal/TermsOfService'),
      },
      {
        path: 'privacy-policy',
        lazy: () => import('../screens/legal/PrivacyPolicy'),
      },
      {
        path: 'account-deletion',
        lazy: () => import('../screens/legal/AccountDeletionInformation'),
      },
      {
        path: '*',
        lazy: async () => import('../screens/NotFound'),
      },
    ],
  },
]);

export default router;
