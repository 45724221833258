import {createSlice} from '@reduxjs/toolkit';
import {NetatmoHistoryMeasureResult} from '../../api/types';
import {NetatmoCommon} from '../../utils';

type Props = {
  netatmoWeatherHistoryData: Record<string, Record<string, any>>;
  netatmoEnergyHistoryData: Record<string, Record<string, any>>;
};

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    netatmoWeatherHistoryData: {},
    netatmoEnergyHistoryData: {},
  } as Props,
  reducers: {
    setNetatmoWeatherHistoryData: (
      state,
      {payload: data}: {payload: NetatmoHistoryMeasureResult[]},
    ) => {
      const handler = new NetatmoCommon.ChartsDataContainer(
        state.netatmoWeatherHistoryData,
      );
      Object.values(data).forEach((item) => {
        handler.update(item);
      });
      state.netatmoWeatherHistoryData = handler.getData();
    },
    setNetatmoEnergyHistoryData: (
      state,
      {payload: data}: {payload: NetatmoHistoryMeasureResult[]},
    ) => {
      const handler = new NetatmoCommon.ChartsDataContainer(
        state.netatmoEnergyHistoryData,
      );
      Object.values(data).forEach((item) => {
        handler.update(item);
      });
      state.netatmoEnergyHistoryData = handler.getData();
    },
  },
});

export const {setNetatmoWeatherHistoryData, setNetatmoEnergyHistoryData} =
  dataSlice.actions;

export default dataSlice.reducer;
