import {createSlice} from '@reduxjs/toolkit';
import {BillingStatus} from '../../api/types';

type SliceState = {
  billing: BillingStatus;
};

const initialState: SliceState = {
  billing: {
    premium: false,
    legacy_premium: false,
    purchase: null,
    free_subscription: null,
  },
};

export const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setBillingStatus: (state, {payload: billing}: {payload: BillingStatus}) => {
      state.billing = billing;
    },
  },
});

export const {setBillingStatus} = slice.actions;

export default slice.reducer;
