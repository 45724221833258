import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

declare global {
  interface Console {
    tron: any;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    hot: Palette['primary'];
    cold: Palette['primary'];
    rain: Palette['primary'];
  }

  interface PaletteOptions {
    hot?: PaletteOptions['primary'];
    cold?: PaletteOptions['primary'];
    rain?: PaletteOptions['primary'];
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
