import {WeatherStationFromList} from '../../api/types';
import * as NetatmoCommon from '../NetatmoCommon';

export default class StationDataHandler extends NetatmoCommon.ChartsDataContainer {
  /********************************************
   * Outdoor Report
   ********************************************/
  getOutdoorReport(
    station: WeatherStationFromList,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'indoor' | 'outdoor' | 'rain', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<
      'indoor' | 'outdoor' | 'rain',
      Record<string, number[]>
    >;
    reportData.outdoor = this.getFormattedChartData(
      station.id.toString(),
      ['T', 'T_MIN', 'T_MAX', 'U', 'U_MIN', 'U_MAX'],
      'D',
      start,
      end,
      formatter,
      station.timezone,
    );

    reportData.indoor = this.getFormattedChartData(
      station.id.toString(),
      ['SLP', 'SLP_MIN', 'SLP_MAX'],
      'D',
      start,
      end,
      formatter,
      station.timezone,
    );

    reportData.rain = station.available_measures.includes('RR_1H')
      ? this.getFormattedChartData(
          station.id.toString(),
          ['RR_1H'],
          'D',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlyOutdoorReport(
    station: WeatherStationFromList,
    year: number,
    month: number,
  ) {
    return this.getOutdoorReport(station, year, month, 'YYYY/MM/DD');
  }

  getAnnualOutdoorReport(station: WeatherStationFromList, year: number) {
    return this.getOutdoorReport(station, year, null, 'YYYY/MM/DD');
  }

  /********************************************
   * Wind Report
   ********************************************/
  getWindReport(
    station: WeatherStationFromList,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'wind', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<'wind', Record<string, number[]>>;
    reportData.wind = station.available_measures.includes('FF')
      ? this.getFormattedChartData(
          station.id.toString(),
          ['FF', 'DD', 'FXY', 'DXY'],
          'D',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlyWindReport(
    station: WeatherStationFromList,
    year: number,
    month: number,
  ) {
    return this.getWindReport(station, year, month, 'YYYY/MM/DD');
  }

  getAnnualOutdoorWindReport(station: WeatherStationFromList, year: number) {
    return this.getWindReport(station, year, null, 'YYYY/MM/DD');
  }

  getSolarReport(
    station: WeatherStationFromList,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'solar', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<'solar', Record<string, number[]>>;
    reportData.solar = station.available_measures.includes('FF')
      ? this.getFormattedChartData(
          station.id.toString(),
          ['SOLAR_RADIATION', 'SOLAR_RADIATION_MAX', 'SOLAR_ENERGY'],
          'D',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlySolarReport(
    station: WeatherStationFromList,
    year: number,
    month: number,
  ) {
    return this.getSolarReport(station, year, month, 'YYYY/MM/DD');
  }

  getAnnualSolarReport(station: WeatherStationFromList, year: number) {
    return this.getSolarReport(station, year, null, 'YYYY/MM/DD');
  }
}
