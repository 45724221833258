import * as Sentry from '@sentry/react';
import {RootState} from '../store';

// Function to configure Sentry. Call this when your app mounts
export const configure = () => {
  Sentry.init({
    dsn: 'https://0dfa4e58c58c4965b2a771a60ffe812b@o76219.ingest.sentry.io/5507134',
    release: process.env.SENTRY_RELEASE,
    environment: process.env.SENTRY_ENV,
  });
};

export const createReduxEnhancer = () =>
  Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      if (action.type === 'user/storeToken') {
        // Return a transformed action to remove sensitive information
        return {
          type: action.type,
          payload: {},
        };
      }

      return action;
    },
    stateTransformer: (state: RootState) => {
      // Transform the state to remove sensitive information
      const transformedState = {
        ...state,
        netatmoWeather: {
          ...state.netatmoWeather,
          mapMarkers: null,
          historyData: null,
          reports: null,
        },
        user: {
          ...state.user,
          token: null,
        },
      };

      return transformedState;
    },
  });

// Function to set user context.
// Use this to send up info about the current logged in user
export const setUser = (email: string, username: string) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: email,
        username,
        email,
      });
    });
  }
};

// Function to report handled errors to Sentry
// I use this if I want to report some API failure
export const captureErrorMessage = (msg: string, tagCtx = {}) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setTags(tagCtx);
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureMessage(msg);
    });
  } else {
    console.log(`[${Sentry.Severity.Error}] ${msg}`);
  }
};

export const captureWarningMessage = (msg: string, tagCtx = {}) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setTags(tagCtx);
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Warning);
      Sentry.captureMessage(msg);
    });
  } else {
    console.log(`[${Sentry.Severity.Warning}] ${msg}`);
  }
};

export const captureInfoMessage = (msg: string, tagCtx = {}) => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.setTags(tagCtx);
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Info);
      Sentry.captureMessage(msg);
    });
  } else {
    console.log(`[${Sentry.Severity.Info}] ${msg}`);
  }
};

export const captureException = () => {
  Sentry.captureException(new Error('Oops!'));
};
