import {Box as MuiBox} from '@mui/material';
import React from 'react';

const Box = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MuiBox>
>((props, ref) => {
  return <MuiBox ref={ref} {...props} />;
});

export default Box;
