import {create} from 'apisauce';
import qs from 'qs';
import {
  AlertService,
  AmbientWeatherService,
  AuthService,
  BillingService,
  DavisService,
  EcowittService,
  GoogleService,
  MyAcuriteService,
  NetatmoEnergyService,
  NetatmoWeatherService,
  UserService,
  WeatherService,
  WeatherflowService,
  WundergroundService,
} from './actions';
import * as ErrorCode from './errorCodes';

const apiClient = (url_root: string) =>
  create({
    baseURL: `${url_root}/api`,
    timeout: 60000,
    paramsSerializer: function (params) {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  });

export {
  AlertService,
  AmbientWeatherService,
  AuthService,
  BillingService,
  DavisService,
  EcowittService,
  ErrorCode,
  GoogleService,
  MyAcuriteService,
  NetatmoEnergyService,
  NetatmoWeatherService,
  UserService,
  WeatherService,
  WeatherflowService,
  WundergroundService,
  apiClient,
};

export const SOURCE_SYNOP = 0;
export const SOURCE_NETATMO_WEATHER = 1;
export const SOURCE_DAVIS = 2;
export const SOURCE_WEATHERFLOW = 3;
export const SOURCE_AMBIENT_WEATHER = 4;
export const SOURCE_WUNDERGROUND = 5;
export const SOURCE_ECOWITT = 6;
export const SOURCE_MY_ACURITE = 7;

export const SUPPORTED_API_VERSION = 7;
// Gigabyte backend
// export const URL_ROOT = 'http://192.168.1.42:8000';
// MacBook Vincent 14'
// export const URL_ROOT = 'http://192.168.1.43:8000';
export const URL_ROOT = 'https://api2.smartmixin.io';
