import {Divider, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import {selectUserInfo} from '@smartmixin/core';
import {messages} from '@smartmixin/i18n';
import {AccountCircle, CogOutline, Logout} from 'mdi-material-ui';
import React from 'react';
import isEqual from 'react-fast-compare';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Box from '../../../components/Box';

const AuthenticatedContent = () => {
  const {formatMessage} = useIntl();

  const {email, firstName, lastName} = useSelector(selectUserInfo, isEqual);

  const dispatch = useDispatch();

  const handleLogout = React.useCallback(() => {
    dispatch({type: 'LOGOUT'});
  }, [dispatch]);

  return (
    <>
      <MenuItem component={Link} to="/app/settings/account">
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <Box>
          <ListItemText
            primary={`${firstName} ${lastName}`}
            secondary={email}
          />
        </Box>
      </MenuItem>
      <Divider />
      <MenuItem component={Link} to="/app/settings">
        <ListItemIcon>
          <CogOutline />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.settings.settings)} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.auth.logout)} />
      </MenuItem>
    </>
  );
};

export default AuthenticatedContent;
