import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../types';

export const selectToken = (state: CommonRootState) => state.user.token;
export const selectIsLoggedIn = (state: CommonRootState) =>
  state.user.token !== null;
export const selectUserInfo = (state: CommonRootState) => {
  const {firstName, lastName, email, isAdmin} = state.user;
  return {firstName, lastName, email, isAdmin};
};

export const selectIsAdmin = (state: CommonRootState) => state.user.isAdmin;
export const selectUserEmail = (state: CommonRootState) => state.user.email;

const selectUserFirstname = (state: CommonRootState) => state.user.firstName;
const selectUserLastname = (state: CommonRootState) => state.user.lastName;

export const getUserUsername = createSelector(
  selectUserFirstname,
  selectUserLastname,
  (firstName, lastName) => {
    return `${firstName} ${lastName}`;
  },
);

export const getDebugEmail = (state: CommonRootState) => state.user.debugEmail;
