import {AlertColor} from '@mui/material';
import {selectAlert, setAlert} from '@smartmixin/core';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Alert from '../../components/Alert';
import Snackbar from '../../components/Snackbar';

const SEVERITY_MAPPING = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
} as Record<string, AlertColor>;

const AlertSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const clear = React.useCallback(() => dispatch(setAlert(null)), [dispatch]);

  const handleClose = () => {
    setOpen(false);
    clear();
  };

  const alert = useSelector(selectAlert);

  React.useEffect(() => {
    if (alert) {
      setOpen(true);
    }
  }, [alert]);

  return alert ? (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <Alert severity={SEVERITY_MAPPING[alert.type]} onClose={handleClose}>
        {alert.message}
      </Alert>
    </Snackbar>
  ) : null;
};

export default AlertSnackbar;
