import {WeatherStationFromList} from '../../api/types';
import NetatmoDevice from '../NetatmoCommon/NetatmoDevice';
import {getStationName} from '../Station';
import {ModuleStringTypes} from './constants';
import NetatmoWeatherModule from './NetatmoWeatherModule';
import {NetatmoType, NetatmoWeatherStationType} from './types';
export default class WeatherStation extends NetatmoDevice {
  data: NetatmoWeatherStationType;
  genericStation: WeatherStationFromList;

  // Modules
  moduleList: NetatmoWeatherModule[];
  outdoorModuleList: NetatmoWeatherModule[];
  indoorModuleList: NetatmoWeatherModule[];

  defaultModule: NetatmoWeatherModule;
  naMain: NetatmoWeatherModule;
  naModule1: NetatmoWeatherModule;
  naModule2: NetatmoWeatherModule;
  naModule3: NetatmoWeatherModule;

  // Station properties
  isPublic: boolean;
  isOwner: boolean;
  name: string;
  customizedName: string;
  lastUpdate: number;
  coordinates: {latitude: number; longitude: number};

  constructor(
    data: NetatmoWeatherStationType,
    genericStation?: WeatherStationFromList,
  ) {
    super();
    this.data = data;
    this.genericStation = genericStation;

    this.id = this.getId();
    this.timezone = this.getTimezone();

    this.moduleList = this.getModuleList();
    this.outdoorModuleList = this.getOutdoorModuleList();
    this.indoorModuleList = this.getIndoorModuleList();
    this.defaultModule = this.getDefaultModule();
    this.naMain = this.getNaMain();
    this.naModule1 = this.getNaModule1();
    this.naModule2 = this.getNaModule2();
    this.naModule3 = this.getNaModule3();

    this.isPublic = this.getisPublic();
    this.isOwner = this.getIsOwner();
    this.name = this.getTitle();
    this.customizedName = this.getCustomizedName();
    this.coordinates = this.getCoordinate();

    this.lastUpdate = this.getLastUpdate();
  }

  getId() {
    return this.data._id;
  }

  getisPublic() {
    if ('favorite' in this.data) {
      return true;
    }
    return false;
  }

  getIsOwner() {
    if ('read_only' in this.data || this.getisPublic()) {
      return false;
    }
    return true;
  }
  /************************************
   ************* SETTINGS *************
   ************************************/
  getTimezone() {
    return this.getPlace().timezone;
  }

  getName() {
    return this.data.station_name;
  }

  getTitle() {
    return (
      this.getCustomizedName() ||
      (!this.isPublic ? this.getHomeName() : this.getLocation())
    );
  }

  getCustomizedName() {
    return getStationName(this.genericStation);
  }

  getHomeName() {
    return this.data.home_name;
  }

  getHomeId() {
    return this.data.home_id;
  }

  getPlace() {
    if (typeof this.data.place !== 'undefined') {
      return this.data.place;
    }
    return {};
  }

  getCity() {
    return this.getPlace().city;
  }

  getCountry() {
    return this.getPlace().country;
  }

  getLocation() {
    const city = this.getCity();
    const country = this.getCountry();
    if (city && country) {
      return `${this.getPlace().city}, ${this.getPlace().country}`;
    } else if (country) {
      return `???, ${this.getPlace().country}`;
    } else if (city) {
      return this.getPlace().city;
    }
    return '???';
  }

  getAltitude() {
    if ('altitude' in this.getPlace()) {
      return this.getPlace().altitude !== null
        ? this.getPlace().altitude
        : null;
    }
    return null;
  }

  getCoordinate() {
    return {
      latitude: this.getPlace().location[1],
      longitude: this.getPlace().location[0],
    };
  }

  getLastStatusStore() {
    return this.data.last_status_store;
  }

  isReachable() {
    return this.data.reachable;
  }

  getLastUpdate() {
    const mainModule = this.getModuleFromType(ModuleStringTypes.NA_MAIN);
    if (mainModule?.getDashboardData()) {
      return mainModule?.getDashboardData().time_utc;
    }
    return null;
  }

  getWifiStatus() {
    return this.data.wifi_status;
  }

  formatWifiStatus() {
    const wifiStatus = this.getWifiStatus();
    if (wifiStatus >= 86) {
      return 'bad';
    }
    if (wifiStatus >= 71) {
      return 'average';
    }
    return 'good';
  }

  getIndoorModuleList() {
    if (this.data !== null) {
      return [...this.moduleList].filter((module) => {
        if (
          [ModuleStringTypes.NA_MAIN, ModuleStringTypes.NA_MODULE4].includes(
            module.getType(),
          )
        ) {
          return true;
        }
        return false;
      });
    }
    return [];
  }

  getOutdoorModuleList() {
    if (this.data !== null) {
      return [...this.moduleList].filter((module) => {
        if (
          [
            ModuleStringTypes.NA_MODULE1,
            ModuleStringTypes.NA_MODULE2,
            ModuleStringTypes.NA_MODULE3,
          ].includes(module.getType())
        ) {
          return true;
        }
        return false;
      });
    }
    return [];
  }

  getModuleList() {
    const moduleList = [] as NetatmoWeatherModule[];
    if ('type' in this.data) {
      // Add Main module
      moduleList.push(new NetatmoWeatherModule(this.data));
    }
    // Add all other modules
    if ('modules' in this.data) {
      for (const item of this.data.modules) {
        // hack for weather map
        if ('data' in item) {
          // @ts-ignore
          moduleList.push(new NetatmoWeatherModule(item.data));
        } else {
          moduleList.push(new NetatmoWeatherModule(item));
        }
      }
    }
    return [...moduleList].sort((a, b) => {
      if (a.getType() < b.getType()) {
        return -1;
      }
      if (a.getType() > b.getType()) {
        return 1;
      }
      return 0;
    });
  }

  getModuleFromType(moduleType: NetatmoType) {
    if (this.data !== null) {
      for (const module of this.moduleList) {
        if (module.getType() === moduleType) {
          return module;
        }
      }
    }
    return null;
  }

  getDefaultModule() {
    if (this.getModuleFromType(ModuleStringTypes.NA_MODULE1) !== null) {
      return this.getModuleFromType(ModuleStringTypes.NA_MODULE1);
    }
    return this.getModuleFromType(ModuleStringTypes.NA_MAIN);
  }

  hasOutdoorModule() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE1) !== null;
  }

  hasRainModule() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE3) !== null;
  }

  hasWindModule() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE2) !== null;
  }

  getMetricCount() {
    let counter = 0;
    for (const module of this.moduleList) {
      if (module.getType() === ModuleStringTypes.NA_MAIN) {
        counter += this.isPublic ? 1 : 5;
      } else if (module.getType() === ModuleStringTypes.NA_MODULE1) {
        counter += 2;
      } else if (module.getType() === ModuleStringTypes.NA_MODULE2) {
        counter += 1;
      } else if (module.getType() === ModuleStringTypes.NA_MODULE3) {
        counter += 1;
      } else if (module.getType() === ModuleStringTypes.NA_MODULE4) {
        counter += 3;
      }
    }
    return counter;
  }

  getNaMain() {
    return this.getModuleFromType(ModuleStringTypes.NA_MAIN);
  }

  getNaModule1() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE1);
  }

  getNaModule2() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE2);
  }

  getNaModule3() {
    return this.getModuleFromType(ModuleStringTypes.NA_MODULE3);
  }

  getModuleFromId(moduleId: string) {
    for (const module of this.moduleList) {
      if (module.id === moduleId) {
        return module;
      }
    }
    return null;
  }

  hasChanged(newStationInstance) {
    return (
      this.id !== newStationInstance.id ||
      this.lastUpdate !== newStationInstance.lastUpdate
    );
  }
}
