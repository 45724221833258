import {createSlice} from '@reduxjs/toolkit';
import {
  WundergroundUserLink,
  WundergroundWeatherStation,
} from '../../api/types';

type Props = {
  link: WundergroundUserLink | null;
  currentStation: string | null;
};

export const wundergroundSlice = createSlice({
  name: 'wunderground',
  initialState: {
    link: null,
    currentStation: null,
  } as Props,
  reducers: {
    setWundergroundUserLink: (
      state,
      {payload: link}: {payload: WundergroundUserLink},
    ) => {
      if (state.link === null || link === null) {
        state.link = link;
      } else {
        // mergeDeep in order to keep generic_station key which is not present for station list
        const oldWeatherStations = state.link.account.weather_stations;
        const newWeatherStations = link.account.weather_stations;

        state.link = link;
        const mergedWeatherStations = [];
        newWeatherStations.forEach((newItem) => {
          const oldStation = oldWeatherStations.find(
            (oldItem) => oldItem.device_id === newItem.device_id,
          );
          if (oldStation) {
            mergedWeatherStations.push({...oldStation, ...newItem});
          } else {
            mergedWeatherStations.push(newItem);
          }
        });
        state.link.account.weather_stations = mergedWeatherStations;
      }
    },
    setWundergroundStationData: (
      state,
      {payload: station}: {payload: WundergroundWeatherStation},
    ) => {
      if (state.link !== null) {
        const stationIdx = state.link.account.weather_stations.findIndex(
          (item) => item.device_id === station.device_id,
        );
        if (stationIdx !== -1) {
          state.link.account.weather_stations[stationIdx] = station;
        }
      }
    },
    setWundergroundCurrentStation: (state, {payload: stationid}) => {
      state.currentStation = stationid;
    },
  },
});

export const {
  setWundergroundUserLink,
  setWundergroundStationData,
  setWundergroundCurrentStation,
} = wundergroundSlice.actions;

export default wundergroundSlice.reducer;
