import {ApiRequestParams} from '../types';

const getUserDetail = (): ApiRequestParams => ({
  url: 'user/me',
  method: 'GET',
  authRequired: true,
});

const updateUserDetail = (userDetailDict: object): ApiRequestParams => ({
  url: 'user/me',
  method: 'PATCH',
  params: userDetailDict,
  authRequired: true,
});

const deleteUseraccount = (): ApiRequestParams => ({
  url: 'user/account/delete',
  method: 'POST',
  authRequired: true,
});

const updateUserProfile = (userProfileDict: object): ApiRequestParams => ({
  url: 'user/profile',
  method: 'PATCH',
  params: userProfileDict,
  authRequired: true,
});

const updateUserDevice = (
  deviceId: string,
  os: string,
  osVersion: string,
  name: string,
  appVersion: string,
  fbToken: string,
): ApiRequestParams => ({
  url: 'user/devices/',
  method: 'POST',
  params: {
    device_id: deviceId,
    os,
    os_version: osVersion,
    name,
    app_version: appVersion,
    fb_token: fbToken,
  },
  authRequired: true,
});

const disconnectUserDevice = (deviceId: string): ApiRequestParams => ({
  url: `user/devices/${deviceId}/disconnect/`,
  method: 'POST',
  authRequired: false,
});

export default {
  getUserDetail,
  updateUserDetail,
  deleteUseraccount,
  updateUserProfile,
  updateUserDevice,
  disconnectUserDevice,
};
