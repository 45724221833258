import {createSelector} from '@reduxjs/toolkit';
import {CommonRootState} from '../types';

export const selectUserUnits = (state: CommonRootState) => {
  return state.userPreference.units;
};

export const selectUserFeelLike = (state: CommonRootState) => {
  return state.userPreference.feelLike;
};

export const selectUserDateTime = (state: CommonRootState) => {
  return state.userPreference.date_time;
};

const getDeviceLanguage = (_: CommonRootState, deviceLanguage: string) =>
  deviceLanguage;

export const selectRawUserLanguage = (
  state: CommonRootState,
): string | null => {
  return state.userPreference.language;
};

export const selectUserLanguage = createSelector(
  selectRawUserLanguage,
  getDeviceLanguage,
  (userLanguage, deviceLanguage) => {
    return userLanguage || deviceLanguage || 'en';
  },
  {
    devModeChecks: {identityFunctionCheck: 'never'},
  },
);
