import {messages as i18nMessages} from '@smartmixin/i18n';

export const AVAILABLE_LANGUAGES = [
  'en',
  'fr',
  'cs',
  'da',
  'de',
  'es',
  'hr',
  'hu',
  'it',
  'nl',
  'pl',
  'sr',
  'sv',
  'nb',
  'pt',
  'sk',
  'bg',
  'ru',
  'uk',
  'ko',
  'ja',
];

export const languageChoices = [
  {i18n: i18nMessages.settings.bulgarian, name: 'български', code: 'bg'},
  {i18n: i18nMessages.settings.czech, name: 'Česky', code: 'cs'},
  {i18n: i18nMessages.settings.danish, name: 'Dansk', code: 'da'},
  {i18n: i18nMessages.settings.german, name: 'Deutsch', code: 'de'},
  {i18n: i18nMessages.settings.english, name: 'English', code: 'en'},
  {i18n: i18nMessages.settings.spanish, name: 'Español', code: 'es'},
  {i18n: i18nMessages.settings.french, name: 'Français', code: 'fr'},
  {i18n: i18nMessages.settings.italian, name: 'Italiano', code: 'it'},
  {i18n: i18nMessages.settings.croatian, name: 'Hrvatski', code: 'hr'},
  {i18n: i18nMessages.settings.hungarian, name: 'Magyar', code: 'hu'},
  {i18n: i18nMessages.settings.dutch, name: 'Nederlands', code: 'nl'},
  {i18n: i18nMessages.settings.norwegian, name: 'Norsk', code: 'nb'},
  {i18n: i18nMessages.settings.polish, name: 'Polski', code: 'pl'},
  {i18n: i18nMessages.settings.portuguese, name: 'Português', code: 'pt'},
  {i18n: i18nMessages.settings.serbian, name: 'Српски', code: 'sr'},
  {i18n: i18nMessages.settings.slovak, name: 'Slovenský', code: 'sk'},
  {i18n: i18nMessages.settings.swedish, name: 'Svenska', code: 'sv'},
  {i18n: i18nMessages.settings.russian, name: 'Русский', code: 'ru'},
  {i18n: i18nMessages.settings.ukrainian, name: 'Українська', code: 'uk'},
  {i18n: i18nMessages.settings.korean, name: '한국인', code: 'ko'},
  {i18n: i18nMessages.settings.japanese, name: '日本語', code: 'ja'},
].sort((a, b) => (a.code > b.code ? 1 : -1));

export const DEFAULT_LANGUAGE = 'en';
