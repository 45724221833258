import {Snackbar as MuiSnackbar} from '@mui/material';
import React from 'react';

const Snackbar = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MuiSnackbar>
>((props, ref) => {
  return <MuiSnackbar ref={ref} {...props} />;
});

export default Snackbar;
