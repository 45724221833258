import {Box, Toolbar} from '@mui/material';
import React from 'react';
import {Outlet} from 'react-router-dom';
import AppNavBar from '../containers/AppNavBar';
import ScrollToTop from './utils/ScrollToTop';

export default function AppContainer() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <AppNavBar />
      <Toolbar />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
        <Outlet />
      </Box>
    </React.Fragment>
  );
}
