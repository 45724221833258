import {RoomDashboardData, RoomType} from './types';

export default class Room {
  room: RoomType;
  dashboardData: RoomDashboardData | undefined;

  id: string;

  constructor(data: RoomType, dashboardData: RoomDashboardData | undefined) {
    this.room = data;
    this.dashboardData = dashboardData;

    this.id = this.getId();
  }

  getId() {
    return this.room.id;
  }

  getName() {
    return this.room.name;
  }

  getType() {
    return this.room.type;
  }

  getModuleIds() {
    return Object.keys(this.room).includes('module_ids')
      ? this.room.module_ids
      : [];
  }

  getDashboardData() {
    return this.dashboardData;
  }

  getThermMeasuredTemperature() {
    return this.getDashboardData()?.therm_measured_temperature;
  }

  getThermSetpointTemperature() {
    return this.getDashboardData()?.therm_setpoint_temperature;
  }

  getThermSetpointMode() {
    return this.getDashboardData()?.therm_setpoint_mode;
  }

  getThermSetpointEntime() {
    const endTime = this.getDashboardData()?.therm_setpoint_end_time;

    return endTime !== 0 ? endTime : null;
  }

  isReachable() {
    return this.getDashboardData()?.reachable;
  }
}
