import logo from '../assets/img/app-logo.webp';

interface Props {
  size: number;
}

const Logo = ({size}: Props) => {
  return <img src={logo} alt={'smartmixin-logo'} height={size} />;
};

export default Logo;
