import {SupportedForecastModelList} from './constants';
import {NormalizedForecastModel} from './types';

export default class ForecastModelListHandler {
  modelList: NormalizedForecastModel[];

  constructor(modelList: NormalizedForecastModel[]) {
    this.modelList = modelList;
  }

  getGlobalForecastModelList = () =>
    this.modelList.filter((item) => item.type === 'global');

  getRegionalForecastModelList = () =>
    this.modelList.filter((item) => item.type === 'regional');

  getForecastModelFromKey = (key: string) => {
    const forecastModels = this.modelList.filter((item) => item.key === key);
    if (forecastModels.length > 0) {
      return forecastModels[0];
    } else {
      return SupportedForecastModelList[0];
    }
  };

  getList = () => this.modelList;
}
