import {Typography as MuiTypography, styled} from '@mui/material';
import React from 'react';

const UnstyledPageTitle = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MuiTypography>
>((props, ref) => {
  return <MuiTypography ref={ref} variant="h4" {...props} />;
});

const PageTitle = styled(UnstyledPageTitle)(() => ({
  fontWeight: 700,
}));

export default PageTitle;
