import {CssBaseline} from '@mui/material';
import {UserProvider} from '@smartmixin/core';
import ReactGA from 'react-ga4';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as StoreProvider} from 'react-redux';
import {RouterProvider} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {LanguageProvider, deviceLanguage} from './i18n';
import {SentryUtils} from './lib';
import UserSyncProvider from './lib/UserSyncProvider';
import router from './navigation/router';
import store, {persistor} from './store';
import {ThemeProvider} from './theme';

ReactGA.initialize(import.meta.env.VITE_GA_ID);

if (process.env.NODE_ENV !== 'development') {
  SentryUtils.configure();
}

const is24 =
  new Date(2014, 1, 1, 15, 0, 0, 0).toLocaleTimeString().indexOf('15') > -1;

export default function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserProvider
          deviceLanguage={deviceLanguage}
          uses24HourClock={is24}
          usesMetricSystem={true}>
          <ThemeProvider>
            <CssBaseline />
            <LanguageProvider>
              <HelmetProvider>
                <UserSyncProvider>
                  <RouterProvider router={router} />
                </UserSyncProvider>
              </HelmetProvider>
            </LanguageProvider>
          </ThemeProvider>
        </UserProvider>
      </PersistGate>
    </StoreProvider>
  );
}
