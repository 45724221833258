import {createSlice} from '@reduxjs/toolkit';
import {DavisUserLink, DavisWeatherStation} from '../../api/types';

type Props = {
  link: DavisUserLink | null;
  currentStation: number | null;
};

export const davisSlice = createSlice({
  name: 'davis',
  initialState: {
    link: null,
    currentStation: null,
  } as Props,
  reducers: {
    setDavisUserLink: (state, {payload: link}: {payload: DavisUserLink}) => {
      if (state.link === null || link === null) {
        state.link = link;
      } else {
        // mergeDeep in order to keep generic_station key which is not present for station list
        const oldWeatherStations = state.link.account.weather_stations;
        const newWeatherStations = link.account.weather_stations;

        state.link = link;
        const mergedWeatherStations = [];
        newWeatherStations.forEach((newItem) => {
          const oldStation = oldWeatherStations.find(
            (oldItem) => oldItem.device_id === newItem.device_id,
          );
          if (oldStation) {
            mergedWeatherStations.push({...oldStation, ...newItem});
          } else {
            mergedWeatherStations.push(newItem);
          }
        });
        state.link.account.weather_stations = mergedWeatherStations;
      }
    },
    setDavisStationData: (
      state,
      {payload: station}: {payload: DavisWeatherStation},
    ) => {
      if (state.link !== null) {
        const stationIdx = state.link.account.weather_stations.findIndex(
          (item) => item.device_id === station.device_id,
        );
        if (stationIdx !== -1) {
          state.link.account.weather_stations[stationIdx] = station;
        }
      }
    },
    setDavisCurrentStation: (state, {payload: stationid}) => {
      state.currentStation = stationid;
    },
  },
});

export const {setDavisUserLink, setDavisCurrentStation, setDavisStationData} =
  davisSlice.actions;

export default davisSlice.reducer;
