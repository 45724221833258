import React from 'react';
import isEqual from 'react-fast-compare';
import {useDispatch, useSelector} from 'react-redux';
import {FeelLike, LoginResponse, UserDetail} from '../api/types';
import {
  getDebugEmail,
  selectFavoriteStationIds,
  selectIsLoggedIn,
  selectPurchase,
  selectUserDateTime,
  selectUserFeelLike,
  selectUserLanguage,
  selectUserUnits,
  setBillingStatus,
  setFavoriteStationList,
  setNetatmoEnergyUserLink,
  setNetatmoWeatherUserLink,
  setStationList,
  setStationSort,
  storeDateTime,
  storeFeelLike,
  storeLanguage,
  storeUnits,
  storeUserData,
} from '../store';
import {JwtToken} from '../store/user/types';
import {Units} from '../utils';
import {DateTimeConfig} from '../utils/Dates';
import useParamSelector from './useParamSelector';

type Props = {
  deviceLanguage: string;
  uses24HourClock: boolean;
  usesMetricSystem: boolean;
};

const useUserData = ({
  deviceLanguage,
  uses24HourClock,
  usesMetricSystem,
}: Props) => {
  const dispatch = useDispatch();
  const debugEmail = useSelector(getDebugEmail);

  const editUserDetails = React.useCallback(
    (data: UserDetail, token?: JwtToken) => {
      const {
        email,
        first_name: firstName,
        last_name: lastName,
        profile: {units, language, feel_like: feelLike, date_time, station_ids},
        is_superuser: isAdmin,
        billing,
        netatmo_weather: netatmoWeather,
        netatmo_energy: netatmoEnergy,
        stations,
      } = data;

      // update user info
      dispatch(
        storeUserData({
          token,
          userInfo: {
            email,
            firstName,
            lastName,
            isAdmin: debugEmail ? true : isAdmin,
          },
        }),
      );

      // Store user preferences
      dispatch(storeUnits(units));
      dispatch(storeFeelLike(feelLike));
      dispatch(storeLanguage(language));
      dispatch(storeDateTime(date_time));
      if (station_ids && station_ids.length > 0) {
        dispatch(setStationSort(station_ids));
      }

      // update billing status
      dispatch(setBillingStatus(billing));

      // update provider
      dispatch(setNetatmoEnergyUserLink(netatmoEnergy));
      dispatch(setNetatmoWeatherUserLink(netatmoWeather));

      // Update all weather stations
      dispatch(setStationList(stations));

      // Extract favorite stations
      dispatch(
        setFavoriteStationList(
          stations.filter((station) => station.favorite === true),
        ),
      );
    },
    [debugEmail, dispatch],
  );

  const storeLoginDetails = React.useCallback(
    (data: LoginResponse) => {
      const {
        user,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = data;

      editUserDetails(user, {accessToken, refreshToken});
    },
    [editUserDetails],
  );

  // current user language to be used
  const language = useParamSelector(selectUserLanguage, deviceLanguage);

  // Tells whether user is logged in or not
  const isLoggedIn = useSelector(selectIsLoggedIn);

  // Handle User units
  const storedUnits = useSelector(selectUserUnits, isEqual);
  const units = React.useMemo(() => {
    if (storedUnits) {
      return storedUnits;
    } else if (usesMetricSystem) {
      return {
        T: 0,
        RR: 0,
        FF: 0,
        SLP: 0,
        DISTANCE: 0,
      };
    } else {
      return {
        T: 1,
        RR: 1,
        FF: 1,
        SLP: 1,
        DISTANCE: 1,
      };
    }
  }, [storedUnits, usesMetricSystem]) as Units.Units;

  // Handle User DateTime formats
  const storedDateTimeConfig = useSelector(
    selectUserDateTime,
    isEqual,
  ) as DateTimeConfig;
  const dateTimeConfig: DateTimeConfig = React.useMemo(() => {
    if (storedDateTimeConfig) {
      // case where only part of config is present
      if (!Object.keys(storedDateTimeConfig).includes('month_format')) {
        return {
          ...storedDateTimeConfig,
          month_format: uses24HourClock ? 'DD/MM' : 'MM/DD',
        };
      }
      return storedDateTimeConfig;
    } else if (uses24HourClock) {
      // European
      return {
        is_24_hour: true,
        month_format: 'DD/MM',
      };
    }
    // American
    return {
      is_24_hour: false,
      month_format: 'MM/DD',
    };
  }, [storedDateTimeConfig, uses24HourClock]);

  const favoriteIds = useSelector(selectFavoriteStationIds);
  const storedFeelLike = useSelector(selectUserFeelLike);

  const userFeelLike: FeelLike = React.useMemo(() => {
    if (storedFeelLike) {
      return storedFeelLike;
    } else if (usesMetricSystem) {
      return 0;
    }
    return 1;
  }, [storedFeelLike, usesMetricSystem]);

  const purchase = useSelector(selectPurchase);

  const getRegistrationHeaders = React.useCallback(() => {
    const headers = {
      'X-SmartMixin-Units': JSON.stringify(units),
      'X-SmartMixin-FeelLike': JSON.stringify(userFeelLike),
      'X-SmartMixin-DateTime': JSON.stringify(dateTimeConfig),
      'X-SmartMixin-FavoriteIds': JSON.stringify(favoriteIds),
    };

    if (purchase) {
      // Add purchase ID
      headers['X-SmartMixin-PurchaseId'] = purchase.id;
    }
    return headers;
  }, [units, userFeelLike, dateTimeConfig, favoriteIds, purchase]);

  return React.useMemo(
    () => ({
      editUserDetails,
      storeLoginDetails,
      language,
      units,
      userFeelLike,
      dateTimeConfig,
      isLoggedIn,
      getRegistrationHeaders,
    }),
    [
      dateTimeConfig,
      editUserDetails,
      getRegistrationHeaders,
      isLoggedIn,
      language,
      storeLoginDetails,
      units,
      userFeelLike,
    ],
  );
};

export default useUserData;
