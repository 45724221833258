import {createSlice} from '@reduxjs/toolkit';
import {
  SOURCE_AMBIENT_WEATHER,
  SOURCE_DAVIS,
  SOURCE_ECOWITT,
  SOURCE_MY_ACURITE,
  SOURCE_NETATMO_WEATHER,
  SOURCE_SYNOP,
  SOURCE_WUNDERGROUND,
} from '../../api';
import {
  DataGranularity,
  StationSource,
  WeatherStationFromDetail,
  WeatherStationFromList,
} from '../../api/types';
import {Dates, Time} from '../../utils';
import {ForecastModelConfig} from '../../utils/WeatherForecast/types';

export type GenericStationComparisonConfig = {
  type: string;
  params?: {
    station: WeatherStationFromList;
  };
};

type ChartConfig = {
  scale: Time.Scale;
  granularity: DataGranularity;
  comparison: GenericStationComparisonConfig | null;
};

type WeatherState = {
  // Save favorite stations IDs
  favoriteStationIds: number[];
  stationList: WeatherStationFromList[];
  stationSort: number[];
  currentStation: number;
  stationDetailList: WeatherStationFromDetail[];
  stationMapFilter: {
    source: StationSource[];
    metric: 'T' | 'FXY' | 'RR_1H';
  };
  chartConfig: ChartConfig;
  // Weather forecast
  forecastModels: Record<number, ForecastModelConfig[]>;
  selectedForecastModel: Record<number, string>;
};

export const initialState: WeatherState = {
  favoriteStationIds: [],
  stationList: [],
  stationSort: [],
  currentStation: null,
  stationDetailList: [],
  stationMapFilter: {
    source: [
      SOURCE_SYNOP,
      SOURCE_NETATMO_WEATHER,
      SOURCE_DAVIS,
      SOURCE_WUNDERGROUND,
      SOURCE_AMBIENT_WEATHER,
      SOURCE_ECOWITT,
      SOURCE_MY_ACURITE,
    ],
    metric: 'T',
  },
  chartConfig: {
    scale: Dates.DAY,
    granularity: 'H',
    comparison: null,
  },
  // Weather forecasts
  forecastModels: {},
  selectedForecastModel: {},
};

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    clearStationList: (state) => {
      state.stationList = [];
    },
    cleanUpStationList: (state) => {
      state.stationDetailList = [];
      const pinnedIds = [
        ...new Set(
          // keep favorite stations
          state.favoriteStationIds
            // + linked stations
            .concat(state.stationList.filter((s) => s.link).map((s) => s.id))
            // + currently displayed station (if any)
            .concat(state.currentStation || []),
        ),
      ];

      // Keep only favorite stations + currentStation
      state.stationList = state.stationList.filter((s) =>
        pinnedIds.includes(s.id),
      );
    },
    clearStationDetailList: (state) => {
      state.stationDetailList = [];
    },
    setFavoriteStationList: (
      state,
      {payload: stationList}: {payload: WeatherStationFromList[]},
    ) => {
      state.favoriteStationIds = stationList.map((station) => station.id);
    },
    removeFavoriteStation: (state, {payload: stationId}: {payload: number}) => {
      state.favoriteStationIds = state.favoriteStationIds.filter(
        (id) => id !== stationId,
      );
    },
    addFavoriteStation: (state, {payload: stationId}: {payload: number}) => {
      state.favoriteStationIds.push(stationId);
    },
    addStation: (
      state,
      {payload: station}: {payload: WeatherStationFromList},
    ) => {
      state.stationList = state.stationList
        .filter((s) => s.id !== station.id)
        .concat([station]);
      if (!state.stationSort.includes(station.id)) {
        // Add station id if not already in the sorted list
        state.stationSort = state.stationSort.concat([station.id]);
      }
    },
    setStationList: (
      state,
      {payload: stationList}: {payload: WeatherStationFromList[]},
    ) => {
      const newIds = stationList.map((s) => s.id);
      state.stationList = state.stationList
        .filter((s) => !newIds.includes(s.id))
        .concat(stationList);
      if (state.stationSort.length !== stationList.length) {
        // Add station ids that are not yet in the sorted list
        state.stationSort = state.stationSort.concat(
          stationList
            .filter((s) => !state.stationSort.includes(s.id))
            .map((s) => s.id),
        );
      }
    },
    removeStation: (state, {payload: stationId}: {payload: number}) => {
      // Remove station from lists
      state.stationList = state.stationList.filter((s) => s.id !== stationId);
      state.stationDetailList = state.stationDetailList.filter(
        (s) => s.id !== stationId,
      );
      // Remove from the sorted list
      state.stationSort = state.stationSort.filter((id) => id !== stationId);
      // reset currentStation if this was the station being removed
      if (state.currentStation === stationId) {
        state.currentStation = null;
      }
    },
    setCurrentPublicStation: (
      state,
      {payload: stationId}: {payload: number},
    ) => {
      state.currentStation = stationId;
    },
    setStationDetail: (
      state,
      {payload: station}: {payload: WeatherStationFromDetail},
    ) => {
      state.stationDetailList = state.stationDetailList
        .filter((s) => s.id !== station.id)
        .concat([station]);
    },
    cleanupOldWeatherStations: (state) => {
      const pinnedIds = [
        ...new Set(state.favoriteStationIds.concat(state.currentStation)),
      ];
      state.stationDetailList = [];
      state.stationList = state.stationList.filter((s) =>
        pinnedIds.includes(s.id),
      );
    },
    setStationMapSource: (
      state,
      {payload: choices}: {payload: StationSource[]},
    ) => {
      state.stationMapFilter.source = choices;
    },
    setMapMetric: (
      state,
      {payload: value}: {payload: 'T' | 'FXY' | 'RR_1H'},
    ) => {
      state.stationMapFilter.metric = value;
    },
    // History
    setWeatherChartComparison: (
      state,
      {
        payload: comparisonConfig,
      }: {payload: GenericStationComparisonConfig | null},
    ) => {
      state.chartConfig = {
        ...state.chartConfig,
        comparison: comparisonConfig,
      };
    },
    setWeatherChartScale: (state, {payload: scale}: {payload: Time.Scale}) => {
      state.chartConfig = {...state.chartConfig, scale};
    },
    setWeatherChartGranularity: (
      state,
      {payload: granularity}: {payload: DataGranularity},
    ) => {
      state.chartConfig = {...state.chartConfig, granularity};
    },
    setStationSort: (state, {payload: value}: {payload: number[]}) => {
      state.stationSort = value;
    },
    // Weather Forecast reducers
    setWeatherForecastModels: (
      state,
      {
        payload: {stationId, forecastModels},
      }: {
        payload: {
          stationId: number;
          forecastModels: ForecastModelConfig[];
        };
      },
    ) => {
      const newForecastModels = {} as Record<number, ForecastModelConfig[]>;
      newForecastModels[stationId] = forecastModels;
      state.forecastModels = {...state.forecastModels, ...newForecastModels};
    },
    setWeatherSelectedForecastModel: (
      state,
      {
        payload: {stationId, forecastModel},
      }: {payload: {stationId: number; forecastModel: string}},
    ) => {
      const newSelectedForecastModel = {} as Record<number, any>;
      newSelectedForecastModel[stationId] = forecastModel;
      state.selectedForecastModel = {
        ...state.selectedForecastModel,
        ...newSelectedForecastModel,
      };
    },
  },
});

export const {
  clearStationList,
  clearStationDetailList,
  cleanUpStationList,
  setFavoriteStationList,
  removeFavoriteStation,
  addFavoriteStation,
  addStation,
  setStationList,
  removeStation,
  setCurrentPublicStation,
  setStationDetail,
  setStationMapSource,
  setMapMetric,
  setWeatherChartComparison,
  setWeatherChartScale,
  setWeatherChartGranularity,
  setStationSort,
  setWeatherForecastModels,
  setWeatherSelectedForecastModel,
} = weatherSlice.actions;

export default weatherSlice.reducer;
