import constate from 'constate';
import useAlert from './useAlert';
import useApiClient from './useApiClient';
import useFavoriteStation from './useFavoriteStation';
import useFetchStationDetail from './useFetchStationDetail';
import useFetchStationList from './useFetchStationList';
import useHistoryNavigation from './useHistoryNavigation';
import useHistoryToday from './useHistoryToday';
import useNetatmoWeatherCalendarData from './useNetatmoWeatherCalendarData';
import useNetatmoWeatherChart from './useNetatmoWeatherChart';
import useNetatmoWeatherChartData from './useNetatmoWeatherChartData';
import useNetatmoWeatherHistory from './useNetatmoWeatherHistory';
import usePagination from './usePagination';
import useParamSelector from './useParamSelector';
import useRainRadar from './useRainRadar';
import useScaleChoices from './useScaleChoices';
import useSensorNames from './useSensorNames';
import useStationForecast from './useStationForecast';
import useStationLastUpdateFormat from './useStationLastUpdateFormat';
import useUserData from './useUserData';
import useWeatherCalendarData from './useWeatherCalendarData';
import useWeatherChart from './useWeatherChart';
import useWeatherChartData from './useWeatherChartData';
import useWeatherHistory from './useWeatherHistory';
const [
  UserProvider,
  useUserLanguage,
  useDateTimeConfig,
  useUserEdit,
  useUserLogin,
  useUserLoggedIn,
  useRegistrationHeaders,
  useUserUnits,
  useUserFeelLike,
] = constate(
  useUserData,
  (value) => value.language,
  (value) => value.dateTimeConfig,
  (value) => value.editUserDetails,
  (value) => value.storeLoginDetails,
  (value) => value.isLoggedIn,
  (value) => value.getRegistrationHeaders,
  (value) => value.units,
  (value) => value.userFeelLike,
);

export {
  UserProvider,
  useAlert,
  useApiClient,
  useDateTimeConfig,
  useFavoriteStation,
  useFetchStationDetail,
  useFetchStationList,
  useHistoryNavigation,
  useHistoryToday,
  useNetatmoWeatherCalendarData,
  useNetatmoWeatherChart,
  useNetatmoWeatherChartData,
  useNetatmoWeatherHistory,
  usePagination,
  useParamSelector,
  useRainRadar,
  useRegistrationHeaders,
  useScaleChoices,
  useSensorNames,
  useStationForecast,
  useStationLastUpdateFormat,
  useUserEdit,
  useUserFeelLike,
  useUserLanguage,
  useUserLoggedIn,
  useUserLogin,
  useUserUnits,
  useWeatherCalendarData,
  useWeatherChart,
  useWeatherChartData,
  useWeatherHistory,
};
