import {createSelector} from 'reselect';
import {WeatherStationForecast} from '../../api/types';
import {Generic} from '../../utils';
import {CommonRootState} from '../types';
import {
  getId,
  getSelectedForecastModelByGenericStationId,
} from '../weather/selectors';

const getWeatherRawHistoryData = (state: CommonRootState) => {
  return state.weatherData.historyData || {};
};

export const getWeatherHistoryData = createSelector(
  getWeatherRawHistoryData,
  (data) => new Generic.StationDataHandler(data),
);

export const getWeatherRawForecastData = (state: CommonRootState) => {
  return state.weatherData.forecastData || {};
};

export const EMPTY_FORECAST = {
  data: {
    daily: [],
    hourly: [],
  },
  metadata: {
    forecast_model: null,
    mesh_size: null,
    runs: [],
  },
  computed_at: null,
} as WeatherStationForecast;

export const getWeatherStationForecastData = createSelector(
  getWeatherRawForecastData,
  getSelectedForecastModelByGenericStationId,
  getId,
  (forecastDataDict, model, stationId) => {
    const key = model
      ? model.serverId.meshSize
        ? `${stationId}-${model.serverId.forecastModel}-${model.serverId.meshSize}`
        : Object.keys(forecastDataDict).find((k) =>
            k.startsWith(`${stationId}-${model.serverId.forecastModel}-`),
          )
      : Object.keys(forecastDataDict).find((k) => k.startsWith(`${stationId}`));

    if (!key || !(key in forecastDataDict)) {
      return EMPTY_FORECAST;
    } else {
      return forecastDataDict[key];
    }
  },
);
