import moment from 'moment-timezone';
import NetatmoModule from '../NetatmoCommon/NetatmoModule';
import {ModuleIntegerTypes, ModuleStringTypes} from './constants';
import {NetatmoWeatherModuleType} from './types';

export default class Module extends NetatmoModule {
  data: NetatmoWeatherModuleType;
  lastUpdate: number;

  constructor(data: NetatmoWeatherModuleType) {
    super();
    this.data = data;

    this.id = data._id;
    this.lastUpdate = this.getLastUpdate();
  }

  getName() {
    if (
      this.getData().module_name !== undefined &&
      this.getData().module_name !== ''
    ) {
      return this.getData().module_name;
    }
    return null;
  }

  getDefaultName() {
    if (this.getType() === ModuleStringTypes.NA_MODULE1) {
      return 'outdoor';
    }
    if (this.getType() === ModuleStringTypes.NA_MODULE3) {
      return 'rain';
    }
    if (this.getType() === ModuleStringTypes.NA_MODULE2) {
      return 'wind';
    }
    return 'indoor';
  }

  getType() {
    return this.getData().type;
  }

  getIntegerType() {
    return ModuleIntegerTypes[this.getType()];
  }

  getDataType() {
    return this.getData().data_type;
  }

  getFirmware() {
    return this.getData().firmware;
  }

  getRfStatus() {
    return this.getData().rf_status;
  }

  formatRfStatus() {
    const rfStatus = this.getRfStatus();
    if (rfStatus >= 86) {
      return 'bad';
    }
    if (rfStatus >= 71) {
      return 'average';
    }
    return 'good';
  }

  isReachable() {
    return this.getData().reachable;
  }

  getBattery() {
    return this.getData().battery_vp;
  }

  getBatteryPercent() {
    return this.getData().battery_percent;
  }

  getLastUpdate() {
    return this.getDashboardData().time_utc;
  }

  isDataOutdated() {
    if (
      this.lastUpdate &&
      moment.unix(this.lastUpdate) > moment().subtract(12, 'hours')
    ) {
      return false;
    }
    return true;
  }

  /************************************
   ********** DASHBOARD DATA **********
   ************************************/
  getData() {
    return this.data;
  }

  getDashboardData() {
    return this.getData().dashboard_data || {};
  }

  getDashboardMetric(metric: string) {
    if (this.getDashboardData()) {
      return this.getDashboardData()[metric];
    }
    return null;
  }

  getTemp() {
    return this.getDashboardMetric('Temperature');
  }

  getTempTrend() {
    return this.getDashboardMetric('temp_trend');
  }

  getMaxTemp() {
    return this.getDashboardMetric('max_temp');
  }

  getMaxTempDate() {
    return this.getDashboardMetric('date_max_temp');
  }

  getMinTemp() {
    return this.getDashboardMetric('min_temp');
  }

  getMinTempDate() {
    return this.getDashboardMetric('date_min_temp');
  }

  getCO2() {
    return this.getDashboardMetric('CO2');
  }

  getNoise() {
    return this.getDashboardMetric('Noise');
  }

  getPressure() {
    return this.getDashboardMetric('Pressure');
  }

  getPressureTrend() {
    return this.getDashboardMetric('pressure_trend');
  }

  getHumidity() {
    return this.getDashboardMetric('Humidity');
  }

  getWindAngle() {
    return this.getDashboardMetric('WindAngle');
  }

  getWindStrength() {
    return this.getDashboardMetric('WindStrength');
  }

  getMaxWindAngle() {
    return this.getDashboardMetric('max_wind_angle');
  }

  getMaxWindStrength() {
    return this.getDashboardMetric('max_wind_str');
  }

  getMaxWindTimestamp() {
    return this.getDashboardMetric('date_max_wind_str');
  }

  getGustAngle() {
    return this.getDashboardMetric('GustAngle');
  }

  getGustStrength() {
    return this.getDashboardMetric('GustStrength');
  }

  getRain() {
    return this.getDashboardMetric('Rain');
  }

  getSumRain1Hour() {
    return this.getDashboardMetric('sum_rain_1');
  }

  getSumRain24Hours() {
    return this.getDashboardMetric('sum_rain_24');
  }

  getAvailableMetrics() {
    const metrics = [];
    this.getDataType().forEach((v) => {
      if (v === 'Temperature') {
        metrics.push('T');
      } else if (v === 'Humidity') {
        metrics.push('U');
      } else if (v === 'Noise') {
        metrics.push('NOISE');
      } else if (v === 'CO2') {
        metrics.push('CO2');
      } else if (v === 'Rain') {
        metrics.push('RR_1H');
      } else if (v === 'Wind') {
        metrics.push('FF');
        metrics.push('FXY');
      } else if (v === 'Pressure') {
        metrics.push('SLP');
      }
    });
    return metrics;
  }
}
