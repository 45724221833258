import {AppBar, Button, IconButton, Toolbar} from '@mui/material';
import {messages} from '@smartmixin/i18n';
import {useIntl} from 'react-intl';
import Logo from '../../components/Logo';
import Stack from '../../components/Stack';
import ThemeToggle from './ThemeToggle';
import UserMenu from './UserMenu';

const AppNavBar = () => {
  const {formatMessage} = useIntl();
  return (
    <AppBar
      position="fixed"
      elevation={0}
      variant="outlined"
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}>
      <Toolbar>
        <Stack direction="row" spacing={1} sx={{flexGrow: 1, display: 'flex'}}>
          <IconButton href="/">
            <Logo size={26} />
          </IconButton>
          <Button href={'/app/stations/'} color="inherit">
            {formatMessage(messages.common.weatherStations)}
          </Button>
          <Button href={'/map/'} color="inherit">
            {formatMessage(messages.common.weatherMap)}
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          <ThemeToggle />
          <UserMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;
