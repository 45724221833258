import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';
import {IconButton} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {setTheme} from '../../../store/prefs';
import {selectIsDarkTheme} from '../../../store/prefs/selectors';

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector(selectIsDarkTheme);
  return (
    <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => dispatch(setTheme(isDarkTheme ? 'light' : 'dark'))}>
      {isDarkTheme ? <LightModeIcon /> : <DarkModeRoundedIcon />}
    </IconButton>
  );
};

export default ThemeToggle;
