import {createSelector} from 'reselect';
import {SOURCE_NETATMO_WEATHER} from '../../api';
import {
  NetatmoWeatherStation,
  NetatmoWeatherStationType,
  Utils,
} from '../../utils/NetatmoWeather';
import {getForecastModelList} from '../../utils/WeatherForecast/utils';
import {CommonRootState} from '../types';
import {getSortedStationList} from '../weather/selectors';

export const selectNetatmoWeatherConnectedAt = (state: CommonRootState) =>
  state.netatmoWeather.link && state.netatmoWeather.link.date_connected;

export const selectNetatmoWeatherConnected = (state: CommonRootState) =>
  state.netatmoWeather.link !== null;

export const selectNetatmoWeatherActive = (state: CommonRootState) =>
  state.netatmoWeather.link !== null &&
  state.netatmoWeather.link.account.active;

export const getUserLinkMetadata = (state: CommonRootState) => {
  return state.netatmoWeather.link && state.netatmoWeather.link.metadata;
};

export const getRawStationList = (state: CommonRootState) => {
  if (state.netatmoWeather.link !== null) {
    return state.netatmoWeather.link.account.extra_data.devices;
  }
  return [];
};

export const getUid = (_: CommonRootState, uid: string) => uid;

export const getGenericStationByUid = createSelector(
  getSortedStationList,
  getUid,
  (stationList, uid) => {
    return stationList.find(
      (s) => s.uid === uid && s.source === SOURCE_NETATMO_WEATHER,
    );
  },
);

export const getStationList = createSelector(
  getRawStationList,
  getSortedStationList,
  (stationList, genericStationList) =>
    Utils.getStationList(
      stationList as NetatmoWeatherStationType[],
      genericStationList,
    ),
);

export const getPublicStationList = (state: CommonRootState) => {
  if (state.netatmoWeather.link !== null) {
    return Utils.getStationList(
      state.netatmoWeather.mapMarkers as unknown as NetatmoWeatherStationType[],
      null,
    );
  }
  return [];
};

export const getCurrentStation = createSelector(
  getStationList,
  (state: CommonRootState) => state.netatmoWeather.currentStation,
  getPublicStationList,
  (stationList, currentStationId, publicStationList) => {
    let defaultStation = null as NetatmoWeatherStation;
    let station = null as NetatmoWeatherStation;
    let publicStation = null as NetatmoWeatherStation;
    if (stationList.length !== 0) {
      if (currentStationId !== null) {
        station = Utils.getStationFromId(stationList, currentStationId);
      }
      defaultStation = stationList[0];
    }
    if (station === null && publicStationList.length !== 0) {
      if (currentStationId !== null) {
        publicStation = Utils.getStationFromId(
          publicStationList,
          currentStationId,
        );
      }
    }
    return station || publicStation || defaultStation;
  },
);

const getRawForecastModels = (state: CommonRootState) => {
  if (state.netatmoWeather.link !== null) {
    return state.netatmoWeather.forecastModels;
  }
  return {};
};

const getId = (_: CommonRootState, id: string) => id;

export const getForecastModelsByStationId = createSelector(
  getRawForecastModels,
  getId,
  (forecastModels, id) => {
    if (id in forecastModels) {
      return getForecastModelList(forecastModels[id]);
    }
    return null;
  },
);

const getRawSelectedForecastModel = (state: CommonRootState) => {
  if (state.netatmoWeather.link !== null) {
    return state.netatmoWeather.selectedForecastModel;
  }
  return {};
};

export const getSelectedForecastModelByStationId = createSelector(
  getRawSelectedForecastModel,
  getId,
  (selectedForecastModel, id) => {
    if (id in selectedForecastModel) {
      return selectedForecastModel[id];
    }
    return 'GFS';
  },
);

export const getStationById = createSelector(
  getStationList,
  getId,
  (stationList, id) => {
    const match = stationList.filter((station) => station.id === id);
    if (match.length > 0) {
      return match[0];
    }
    return null;
  },
);

export const getStationByHomeId = createSelector(
  getStationList,
  getId,
  (stationList, id) => {
    const match = stationList.filter((station) => station.getHomeId() === id);
    if (match.length > 0) {
      return match[0];
    }
    return null;
  },
);

export const selectNetatmoWeatherChartConfig = (state: CommonRootState) =>
  state.netatmoWeather.chartConfig;

export const selectNetatmoWeatherMapFilterStations = (state: CommonRootState) =>
  state.netatmoWeather.mapFilterStations;

export const selectNetatmoWeatherMapRainMeasurement = (
  state: CommonRootState,
) => state.netatmoWeather.mapRainMeasurement;

export const selectNetatmoWeatherMapWindMeasurement = (
  state: CommonRootState,
) => state.netatmoWeather.mapWindMeasurement;
