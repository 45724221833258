import chroma from 'chroma-js';

export const color = (value: string | number | chroma.Color) => chroma(value);

export const isDark = (value: string | number | chroma.Color) => {
  // YIQ equation from http://24ways.org/2010/calculating-color-contrast
  const rgb = color(value).rgb();
  const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return yiq < 128;
};

export const isLight = (value: string | number | chroma.Color) => {
  return !isDark(value);
};
