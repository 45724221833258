import {createSelector} from 'reselect';
import {
  SOURCE_AMBIENT_WEATHER,
  SOURCE_DAVIS,
  SOURCE_ECOWITT,
  SOURCE_MY_ACURITE,
  SOURCE_NETATMO_WEATHER,
  SOURCE_SYNOP,
  SOURCE_WEATHERFLOW,
  SOURCE_WUNDERGROUND,
} from '../../api';
import {WeatherForecast} from '../../utils';
import {NormalizedForecastModel} from '../../utils/WeatherForecast/types';
import {getAmbientWeatherStationList} from '../ambientWeather/selectors';
import {getDavisStationList} from '../davis/selectors';
import {getEcowittStationList} from '../ecowitt/selectors';
import {CommonRootState} from '../types';
import {getWeatherflowStationList} from '../weatherflow/selectors';
import {getWundergroundStationList} from '../wunderground/selectors';

export const getStationList = (state: CommonRootState) =>
  state.weather.stationList;

const getStationSort = (state: CommonRootState) => state.weather.stationSort;

export const getSortedStationList = createSelector(
  getStationList,
  getStationSort,
  (stationList, stationSort) =>
    stationList
      .slice()
      .sort((a, b) => stationSort.indexOf(a.id) - stationSort.indexOf(b.id)),
);

export const selectFavoriteStationIds = (state: CommonRootState) =>
  state.weather.favoriteStationIds;

export const getFavoriteStationList = createSelector(
  selectFavoriteStationIds,
  getStationList,
  (favoriteStationIds, stationList) =>
    stationList.filter((station) => favoriteStationIds.includes(station.id)),
);

/*
 * Retrieve ids from both stationList and favoriteIdList
 */
export const getUserStationIds = createSelector(
  getStationList,
  selectFavoriteStationIds,
  (stationList, favoriteIds) => {
    const fullList = stationList.map((s) => s.id).concat(favoriteIds);
    return fullList.filter((item, index) => fullList.indexOf(item) === index);
  },
);

export const getRawCurrentStationId = (state: CommonRootState) =>
  state.weather.currentStation;

const getStationDetailList = (state: CommonRootState) =>
  state.weather.stationDetailList;

export const getCurrentPublicStationId = createSelector(
  getRawCurrentStationId,
  selectFavoriteStationIds,
  getSortedStationList,
  (stationId, favoriteIds, stationList) =>
    stationId ||
    (favoriteIds.length > 0
      ? favoriteIds[0]
      : stationList.length > 0
        ? stationList[0].id
        : null),
);

export const getCurrentPublicStationFromList = createSelector(
  getCurrentPublicStationId,
  getStationList,
  getStationDetailList,
  (currentStationId, stationList, stationDetailList) => {
    if (currentStationId) {
      const currentStation = stationList.find((s) => s.id === currentStationId);
      if (currentStation) {
        return currentStation;
      }
      const currentStationDetail = stationDetailList.find(
        (s) => s.id === currentStationId,
      );
      if (currentStationDetail) {
        return currentStationDetail;
      }
    }
    return null;
  },
);

export const getId = (_: CommonRootState, id: number) => id;

export const getPublicStationFromList = createSelector(
  getStationList,
  getStationDetailList,
  getId,
  (stationList, stationDetailList, id) => {
    if (id) {
      const currentStation = stationList.find((s) => s.id === id);
      if (currentStation) {
        return currentStation;
      }
      const currentStationDetail = stationDetailList.find((s) => s.id === id);
      if (currentStationDetail) {
        return currentStationDetail;
      }
    }
    return null;
  },
);

export const getCurrentPublicStationFromDetail = createSelector(
  getStationDetailList,
  getId,
  (stationDetailList, id) => stationDetailList.find((s) => s.id === id),
);

export const getStationMapSourceFilter = (state: CommonRootState) =>
  (state.weather.stationMapFilter && state.weather.stationMapFilter.source) || [
    SOURCE_SYNOP,
    SOURCE_NETATMO_WEATHER,
    SOURCE_DAVIS,
    SOURCE_WUNDERGROUND,
    SOURCE_AMBIENT_WEATHER,
    SOURCE_ECOWITT,
    SOURCE_MY_ACURITE,
    SOURCE_WEATHERFLOW,
  ];

export const getMapMetric = (state: CommonRootState) =>
  (state.weather.stationMapFilter && state.weather.stationMapFilter.metric) ||
  'T';

export const selectWeatherChartConfig = (state: CommonRootState) =>
  state.weather.chartConfig;

/*
Weather Forecast
*/
const getRawForecastModels = (state: CommonRootState) => {
  return state.weather.forecastModels;
};

export const getForecastModelsByGenericStationId = createSelector(
  getRawForecastModels,
  getId,
  (forecastModels, id) => {
    if (id in forecastModels) {
      return WeatherForecast.ForecastUtils.getForecastModelList(
        forecastModels[id],
      ).getList();
    }
    return [];
  },
);

const getRawSelectedForecastModel = (state: CommonRootState) => {
  return state.weather.selectedForecastModel;
};

export const getSelectedForecastModelByGenericStationId = createSelector(
  getForecastModelsByGenericStationId,
  getRawSelectedForecastModel,
  getId,
  (forecastModelList, selectedForecastModelKeyDict, id) => {
    let selectedForecastModel = null as NormalizedForecastModel;
    let key = 'GFS';
    if (id in selectedForecastModelKeyDict) {
      key = selectedForecastModelKeyDict[id];
    }
    selectedForecastModel = forecastModelList.find((item) => item.key === key);
    return selectedForecastModel;
  },
);

export const getFullStationList = createSelector(
  getSortedStationList,
  getAmbientWeatherStationList,
  getWeatherflowStationList,
  getWundergroundStationList,
  getEcowittStationList,
  getDavisStationList,
  (
    publishStationList,
    ambientStationList,
    weatherflowStationList,
    wundergroundStationList,
    ecowittStationList,
    davisStationList,
  ) => {
    const fullList = [
      ...publishStationList,
      ...ambientStationList.map((s) => s.generic_station_meta),
      ...weatherflowStationList.map((s) => s.generic_station_meta),
      ...wundergroundStationList.map((s) => s.generic_station_meta),
      ...ecowittStationList.map((s) => s.generic_station_meta),
      ...davisStationList.map((s) => s.generic_station_meta),
    ];

    return fullList.filter(
      (station, index, self) =>
        index === self.findIndex((s) => s.id === station.id),
    );
  },
);
