import {Navigate} from 'react-router-dom';

export const serviceRoutes = [
  {
    path: '',
    lazy: () => import('../../../../screens/settings/ServiceListScreen'),
  },
  {
    path: 'ambient-weather',
    lazy: () =>
      import(
        '../../../../screens/settings/services/AmbientWeatherServiceScreen'
      ),
  },
  {
    path: 'davis',
    lazy: () =>
      import('../../../../screens/settings/services/DavisServiceScreen'),
  },
  {
    path: 'ecowitt',
    lazy: () =>
      import('../../../../screens/settings/services/EcowittServiceScreen'),
  },
  {
    path: 'my-acurite',
    lazy: () =>
      import('../../../../screens/settings/services/MyAcuriteServiceScreen'),
  },
  {
    path: 'netatmo-energy',
    lazy: () =>
      import(
        '../../../../screens/settings/services/NetatmoEnergyServiceScreen'
      ),
  },
  {
    path: 'netatmo-weather',
    lazy: () =>
      import(
        '../../../../screens/settings/services/NetatmoWeatherServiceScreen'
      ),
  },
  {
    path: 'weatherflow',
    lazy: () =>
      import('../../../../screens/settings/services/WeatherflowServiceScreen'),
  },
  {
    path: 'wunderground',
    lazy: () =>
      import('../../../../screens/settings/services/WundergroundServiceScreen'),
  },
  {
    path: '*',
    element: <Navigate to="" replace />,
  },
];
