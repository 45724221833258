import {Tabs as MuiTabs, styled} from '@mui/material';

const Tabs = styled(MuiTabs)(({theme}) => ({
  borderBottom: '1px solid',
  borderColor: theme.palette.divider,
  '&.Mui-selected': {
    color: 'pink',
    fontWeight: theme.typography.fontWeightMedium.toString(),
  },
}));

export default Tabs;
