const NETATMO_RATE_LIMITED = '100';
const NETATMO_USER_LINK_NOT_FOUND = '101';
const NETATMO_ACCOUNT_REVOKED = '102';
const NETATMO_NOT_FOUND = '103';

const FORECAST_NOT_AVAILABLE_FOR_STATION = '204';

export const HANDLED_ERROR_CODES = [
  NETATMO_RATE_LIMITED,
  NETATMO_USER_LINK_NOT_FOUND,
  NETATMO_ACCOUNT_REVOKED,
  NETATMO_NOT_FOUND,
  FORECAST_NOT_AVAILABLE_FOR_STATION,
];
