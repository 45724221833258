import {messages} from '@smartmixin/i18n';

export const metricToMessage = {
  T: messages.units.temperature,
  U: messages.units.humidity,
  FF: messages.units.wind,
  SLP: messages.units.pressure,
  RR_1H: messages.units.precipitation,
  SOLAR_RADIATION: messages.units.solarRadiation,
  UV: messages.units.uv,
  VIS: messages.units.visibility,
  AQI: messages.units.AQI,
  PM_1: messages.units.PM_1,
  PM_10: messages.units.PM_10,
  PM_2P5: messages.units.PM_2P5,
  LIGHTNING_STRIKE_COUNT: messages.units.lightningStrikeCount,
};
