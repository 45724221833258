import {Navigate} from 'react-router-dom';
import {default as SettingsContainer} from './settings';
import {settingsRoutes} from './settings/routes';
import {stationRoutes} from './stations/routes';

export const dashboardRoutes = [
  {
    path: '',
    element: <Navigate to="stations" replace />,
  },
  {
    path: 'stations',
    children: stationRoutes,
  },
  {
    path: 'settings',
    children: settingsRoutes,
    Component: SettingsContainer,
  },
  {
    path: '*',
    lazy: async () => import('../../screens/NotFound'),
  },
];
