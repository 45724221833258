import {createSlice} from '@reduxjs/toolkit';
import {JwtToken} from './types';

type SliceState = {
  token: JwtToken | null;
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  debugEmail: string;
};

const initialState: SliceState = {
  token: null,
  email: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  debugEmail: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeToken: (state, {payload: token}: {payload: JwtToken}) => {
      state.token = token;
    },
    storeUserData: (
      state,
      {
        payload: {
          token,
          userInfo: {email, firstName, lastName, isAdmin},
        },
      }: {
        payload: {
          token?: JwtToken;
          userInfo: {
            email: string;
            firstName: string;
            lastName: string;
            isAdmin: boolean;
          };
        };
      },
    ) => {
      if (token) {
        state.token = token;
      }
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.isAdmin = isAdmin;
    },
    removeToken: (state) => {
      state.token = null;
    },
    storeUserInfo: (state, {payload: {email, firstName, lastName, isAdmin}}) =>
      (state = {...state, email, firstName, lastName, isAdmin}),
    setDebugEmail: (state, {payload: email}: {payload: string}) => {
      state.debugEmail = email;
    },
  },
});

export const {
  storeToken,
  storeUserData,
  removeToken,
  storeUserInfo,
  setDebugEmail,
} = userSlice.actions;

export default userSlice.reducer;
