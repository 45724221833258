import {Divider, ListItemIcon, ListItemText, MenuItem} from '@mui/material';
import {messages} from '@smartmixin/i18n';
import {AccountPlus, CogOutline, Login} from 'mdi-material-ui';
import React from 'react';
import {useIntl} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';

const UnauthenticatedContent = () => {
  const {formatMessage} = useIntl();
  const location = useLocation();

  return (
    <React.Fragment>
      <MenuItem component={Link} to="/app/settings">
        <ListItemIcon>
          <CogOutline />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.settings.settings)} />
      </MenuItem>
      <Divider />
      <MenuItem
        component={Link}
        to={{
          pathname: '/auth/login',
        }}
        state={{from: location}}>
        <ListItemIcon>
          <Login />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.auth.login)} />
      </MenuItem>
      <MenuItem
        component={Link}
        to={{
          pathname: '/auth/register',
        }}
        state={{from: location}}>
        <ListItemIcon>
          <AccountPlus />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.auth.register)} />
      </MenuItem>
    </React.Fragment>
  );
};

export default UnauthenticatedContent;
