import {Navigate} from 'react-router-dom';

export const authRoutes = [
  {
    path: 'login',
    lazy: () => import('../../screens/auth/LoginScreen'),
  },
  {
    path: 'register',
    lazy: () => import('../../screens/auth/RegisterScreen'),
  },
  {
    path: 'forgot-password',
    lazy: () => import('../../screens/auth/ForgotPasswordScreen'),
  },
  {
    path: 'verify-email/:key',
    lazy: () => import('../../screens/auth/VerifyEmailScreen'),
  },
  {
    path: 'reset-password/:uidb36/:key',
    lazy: () => import('../../screens/auth/ResetPasswordFormScreen'),
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];
