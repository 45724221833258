import {NormalizedForecastModel} from './types';

export const SupportedForecastModelList = [
  {
    key: 'GFS',
    premium: false,
    serverId: {
      forecastModel: 'GFS',
      meshSize: null,
    },
    type: 'global',
    meshSize: 25,
    title: 'GFS',
    description: 'GFS description',
    editor: 'NOAA',
    horizon: 384,
    frequency: '00, 06, 12, 18 UTC',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
  },
  {
    key: 'HRRR_CONUS',
    premium: true,
    serverId: {
      forecastModel: 'HRRR_CONUS',
      meshSize: '0P03',
    },
    type: 'regional',
    meshSize: 3,
    title: 'HRRR',
    description: 'HRRR description',
    editor: 'NOAA',
    horizon: 18,
    frequency: 'hourly',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/rapid-refresh-update',
  },
  {
    key: 'NAM_CONUS',
    premium: true,
    serverId: {
      forecastModel: 'NAM_CONUS',
      meshSize: '0P03',
    },
    type: 'regional',
    meshSize: 5,
    title: 'NAM CONUS',
    description: 'NAM description',
    editor: 'NOAA',
    horizon: 18,
    frequency: '00, 06, 12, 18 UTC',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/north-american-mesoscale',
  },
  {
    key: 'NAM_NORTH_AMERICA',
    premium: true,
    serverId: {
      forecastModel: 'NAM_NORTH_AMERICA',
      meshSize: '0P32',
    },
    type: 'regional',
    meshSize: 32,
    title: 'NAM North America',
    description: 'NAM description',
    editor: 'NOAA',
    horizon: 18,
    frequency: '00, 06, 12, 18 UTC',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/north-american-mesoscale',
  },
  {
    key: 'EC_RDPS',
    premium: true,
    serverId: {
      forecastModel: 'EC_RDPS',
      meshSize: '0P10',
    },
    type: 'regional',
    meshSize: 10,
    title: 'RDPS (Regional)',
    description: '',
    editor: 'Environement Canada',
    horizon: 80,
    frequency: '00, 06, 12, 18 UTC',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/north-american-mesoscale',
  },
  {
    key: 'EC_GDPS',
    premium: true,
    serverId: {
      forecastModel: 'EC_GDPS',
      meshSize: '0P15',
    },
    type: 'global',
    meshSize: 15,
    title: 'GDPS (Global)',
    description: '',
    editor: 'Environement Canada',
    horizon: 240,
    frequency: '00, 12 UTC',
    link: 'https://www.ncei.noaa.gov/products/weather-climate-models/north-american-mesoscale',
  },
  {
    key: 'AROME',
    premium: true,
    serverId: {
      forecastModel: 'AROME',
      meshSize: '0P01',
    },
    type: 'regional',
    meshSize: 1.3,
    title: 'AROME',
    description: 'Arome description',
    editor: 'Météo France',
    horizon: 39,
    frequency: '00, 06, 12, 18 UTC',
    link: 'http://www.meteofrance.fr/prevoir-le-temps/la-prevision-du-temps/le-modele-a-maille-fine-arome',
  },
  {
    key: 'ARPEGE_EU',
    premium: true,
    serverId: {
      forecastModel: 'ARPEGE',
      meshSize: '0P10',
    },
    type: 'regional',
    meshSize: 10,
    title: 'ARPEGE Europe',
    description: 'Arpege Europe description',
    editor: 'Météo France',
    horizon: 72,
    frequency: '00, 06, 12, 18 UTC',
    link: 'http://www.meteofrance.fr/prevoir-le-temps/la-prevision-du-temps/les-modeles-de-prevision-de-meteo-france',
  },
  {
    key: 'ARPEGE_GLOBAL',
    premium: true,
    serverId: {
      forecastModel: 'ARPEGE',
      meshSize: '0P50',
    },
    type: 'global',
    meshSize: 50,
    title: 'ARPEGE Global',
    description: 'Arepge description',
    editor: 'Météo France',
    horizon: 72,
    frequency: '00, 06, 12, 18 UTC',
    link: 'http://www.meteofrance.fr/prevoir-le-temps/la-prevision-du-temps/les-modeles-de-prevision-de-meteo-france',
  },
  {
    key: 'ICON_EU',
    premium: true,
    serverId: {
      forecastModel: 'ICON_EU',
      meshSize: null,
    },
    type: 'regional',
    meshSize: 7,
    title: 'ICON-EU',
    description: 'Icon Europe description',
    editor: 'DWD',
    horizon: 120,
    frequency: '00, 06, 12, 18 UTC',
    link: 'https://www.dwd.de/EN/ourservices/nwp_forecast_data/nwp_forecast_data.html',
  },
  {
    key: 'COSMO_DE',
    premium: true,
    serverId: {
      forecastModel: 'COSMO_DE',
      meshSize: null,
    },
    type: 'regional',
    meshSize: 2.2,
    title: 'COSMO-D2',
    description: 'Cosmo description',
    editor: 'DWD',
    horizon: 27,
    frequency: '00, 06, 09, 12, 15, 18, 21 UTC',
    link: 'https://www.dwd.de/EN/ourservices/nwp_forecast_data/nwp_forecast_data.html',
  },
  {
    key: 'ICON_DE',
    premium: true,
    serverId: {
      forecastModel: 'ICON_DE',
      meshSize: null,
    },
    type: 'regional',
    meshSize: 2.2,
    title: 'ICON-D2',
    description: 'ICON-D2 description',
    editor: 'DWD',
    horizon: 27,
    frequency: '00, 12 UTC',
    link: 'https://www.dwd.de/EN/ourservices/nwp_forecast_data/nwp_forecast_data.html',
  },
  {
    key: 'MET_NORWAY',
    premium: true,
    serverId: {
      forecastModel: 'MET_NORWAY',
      meshSize: null,
    },
    type: 'global',
    meshSize: null,
    title: 'MET Norway',
    description: 'MET Norway',
    editor: 'Meteorologisk institutt',
    horizon: 240,
    frequency: null,
    link: 'https://www.met.no/en',
  },
  {
    key: 'WEATHERFLOW_FORECAST',
    premium: true,
    serverId: {
      forecastModel: 'WEATHERFLOW_FORECAST',
      meshSize: null,
    },
    type: 'global',
    meshSize: null,
    title: 'Weatherflow Forecast',
    description: 'MET Norway',
    editor: 'Weatherflow',
    horizon: 240,
    frequency: null,
    link: 'https://weatherflow.com/tempest-forecasting/',
  },
  {
    key: 'SMARTMIXIN_MULTIMODEL',
    premium: true,
    serverId: {
      forecastModel: 'SMARTMIXIN_MULTIMODEL',
      meshSize: null,
    },
    type: 'global',
    meshSize: null,
    title: 'SmartMixin multi-model',
    description: '',
    editor: 'SmartMixin',
    horizon: 240,
    frequency: null,
    link: null,
  },
] as NormalizedForecastModel[];
