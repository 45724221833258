import {createSlice} from '@reduxjs/toolkit';
import {Alert} from './types';

type SliceState = {
  alert: Alert | null;
  upgradeRequired: boolean;
};

const initialState: SliceState = {
  alert: null,
  upgradeRequired: false,
};

export const uiSlice = createSlice({
  name: 'uiInteraction',
  initialState,
  reducers: {
    setAlert: (state, {payload: alert}: {payload: Alert | null}) => {
      state.alert = alert;
    },
    setUpgradeRequired: (state, {payload: required}: {payload: boolean}) => {
      state.upgradeRequired = required;
    },
  },
});

export const {setAlert, setUpgradeRequired} = uiSlice.actions;

export default uiSlice.reducer;
