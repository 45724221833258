import {AVAILABLE_LANGUAGES} from './setup';

export const deviceLanguage = navigator.languages.reduce(
  (firstMatch: string | null, language) => {
    const languageCode = language.split('-')[0];
    if (!firstMatch && AVAILABLE_LANGUAGES.includes(languageCode)) {
      firstMatch = languageCode;
    }
    return firstMatch;
  },
  null,
);
