import {createSlice} from '@reduxjs/toolkit';

type InitialState = {
  theme: null | 'light' | 'dark';
  netatmoWeather: {
    chartView: boolean;
    analysisChartView: boolean;
  };
  netatmoEnergy: {
    chartView: boolean;
    analysisChartView: boolean;
  };
  weather: {
    chartView: boolean;
  };
  gesture: {
    chart: boolean;
  };
  rainRadar: {
    cloudLayer: boolean;
  };
  lastNavigator: null | 'NetatmoWeather' | 'NetatmoEnergy' | 'Weather';
};

export const initialState = {
  theme: null,
  netatmoWeather: {
    chartView: true,
    analysisChartView: false,
  },
  netatmoEnergy: {
    chartView: true,
    analysisChartView: false,
  },
  weather: {
    chartView: true,
  },
  gesture: {
    chart: false,
  },
  lastNavigator: null,
  rainRadar: {
    cloudLayer: false,
  },
} as InitialState;

export const prefsSlice = createSlice({
  name: 'prefs',
  initialState: initialState,
  reducers: {
    setTheme: (state, {payload: theme}) => {
      state.theme = theme;
    },
    setNetatmoWeatherChartView: (
      state,
      {payload: value}: {payload: boolean},
    ) => {
      state.netatmoWeather = {...state.netatmoWeather, chartView: value};
    },
    setNetatmoWeatherAnalysisChartView: (
      state,
      {payload: value}: {payload: boolean},
    ) => {
      state.netatmoWeather = {
        ...state.netatmoWeather,
        analysisChartView: value,
      };
    },
    setNetatmoEnergyChartView: (
      state,
      {payload: value}: {payload: boolean},
    ) => {
      state.netatmoEnergy = {...state.netatmoEnergy, chartView: value};
    },
    setNetatmoEnergyAnalysisChartView: (
      state,
      {payload: value}: {payload: boolean},
    ) => {
      state.netatmoEnergy = {
        ...state.netatmoEnergy,
        analysisChartView: value,
      };
    },
    setWeatherChartView: (state, {payload: value}: {payload: boolean}) => {
      state.weather = {...state.weather, chartView: value};
    },
    setChartGesture: (state, {payload: value}: {payload: boolean}) => {
      state.gesture = {
        ...state.gesture,
        chart: value,
      };
    },
    setLastNavigator: (
      state,
      {
        payload: value,
      }: {
        payload: 'NetatmoWeather' | 'NetatmoEnergy' | 'Weather';
      },
    ) => {
      state.lastNavigator = value;
    },
    setCloudLayer: (state, {payload: value}: {payload: boolean}) => {
      state.rainRadar = {...state.rainRadar, cloudLayer: value};
    },
  },
});

export const {
  setTheme,
  setNetatmoWeatherChartView,
  setNetatmoWeatherAnalysisChartView,
  setNetatmoEnergyChartView,
  setNetatmoEnergyAnalysisChartView,
  setWeatherChartView,
  setChartGesture,
  setLastNavigator,
  setCloudLayer,
} = prefsSlice.actions;

export default prefsSlice.reducer;
