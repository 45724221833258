import {NetatmoType} from './types';

export const ModuleStringTypes = {
  NA_MAIN: 'NAMain', // Main module
  NA_MODULE1: 'NAModule1', // Outside module
  NA_MODULE2: 'NAModule2', // Wind module
  NA_MODULE3: 'NAModule3', // rain module
  NA_MODULE4: 'NAModule4', // additional indoor
} as Record<string, NetatmoType>;

export const ModuleIntegerTypes = {
  NAMain: 0,
  NAModule1: 1,
  NAModule2: 2,
  NAModule3: 3,
  NAModule4: 4,
};
