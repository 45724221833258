import {createSelector} from 'reselect';
import {HomeDataHandler} from '../../utils/NetatmoEnergy';
import {StationDataHandler} from '../../utils/NetatmoWeather';
import {CommonRootState} from '../types';

const getNetatmoWeatherRawHistoryData = (state: CommonRootState) => {
  return state.data.netatmoWeatherHistoryData || {};
};

export const getNetatmoWeatherHistoryData = createSelector(
  getNetatmoWeatherRawHistoryData,
  (data) => new StationDataHandler(data),
);

const getNetatmoEnergyRawHistoryData = (state: CommonRootState) => {
  return state.data.netatmoEnergyHistoryData || {};
};

export const getNetatmoEnergyHistoryData = createSelector(
  getNetatmoEnergyRawHistoryData,
  (data) => new HomeDataHandler(data),
);
