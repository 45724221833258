export const ModuleStringTypes = {
  NA_PLUG: 'NAPlug', // Wifi Relay
  NA_THERM1: 'NATherm1', // Thermostat module
  NA_VALVE: 'NRV', // Smart Valve
  OTH: 'OTH', // Modulating relay
  OTM: 'OTM', // Modulating thermostat
};

export const PROGRAM = 'program'; // Currently following a weekly schedule
export const AWAY = 'away'; // Currently applying the "away" temperature as defined by the user
export const FROST_GUARD = 'hg'; // Frost-guard
export const MANUAL = 'manual'; // Currently applying a manually set temperature setpoint
export const OFF = 'off'; // Currently off
export const MAX = 'max'; // Heating continuously

export const ZONE_TYPE = {
  day: 0,
  night: 1,
  away: 2,
  hg: 3,
  personalized: 4,
  eco: 5,
  comfort: 8,
};

export const ZoneType = {
  DAY: 0,
  NIGHT: 1,
  AWAY: 2,
  HG: 3,
  PERSONALIZED: 4,
  ECO: 5,
  COMFORT: 8,
};
