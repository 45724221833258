import {NetatmoWeatherModule} from '.';
import {ChartsDataContainer} from '../NetatmoCommon';
import {ModuleStringTypes} from './constants';
import NetatmoWeatherStation from './NetatmoWeatherStation';
export default class StationDataHandler extends ChartsDataContainer {
  /********************************************
   * Outdoor Report
   ********************************************/
  getOutdoorReport(
    station: NetatmoWeatherStation,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'indoor' | 'outdoor' | 'rain', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<
      'indoor' | 'outdoor' | 'rain',
      Record<string, number[]>
    >;
    reportData.outdoor = station.naModule1
      ? this.getFormattedChartData(
          station.naModule1.id,
          [
            'Temperature',
            'min_temp',
            'max_temp',
            'Humidity',
            'min_hum',
            'max_hum',
          ],
          '1day',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    reportData.indoor = station.naMain
      ? this.getFormattedChartData(
          station.naMain.id,
          ['Pressure', 'min_pressure', 'max_pressure'],
          '1day',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    reportData.rain = station.naModule3
      ? this.getFormattedChartData(
          station.naModule3.id,
          ['sum_rain'],
          '1day',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlyOutdoorReport(
    station: NetatmoWeatherStation,
    year: number,
    month: number,
  ) {
    return this.getOutdoorReport(station, year, month, 'YYYY/MM/DD');
  }

  getAnnualOutdoorReport(station: NetatmoWeatherStation, year: number) {
    return this.getOutdoorReport(station, year, null, 'YYYY/MM/DD');
  }

  /********************************************
   * Wind Report
   ********************************************/
  getWindReport(
    station: NetatmoWeatherStation,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'wind', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<'wind', Record<string, number[]>>;
    reportData.wind = station.naModule2
      ? this.getFormattedChartData(
          station.naModule2.id,
          ['WindStrength', 'WindAngle', 'GustStrength', 'GustAngle'],
          '1day',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlyWindReport(
    station: NetatmoWeatherStation,
    year: number,
    month: number,
  ) {
    return this.getWindReport(station, year, month, 'YYYY/MM/DD');
  }

  getAnnualOutdoorWindReport(station: NetatmoWeatherStation, year: number) {
    return this.getWindReport(station, year, null, 'YYYY/MM/DD');
  }

  /********************************************
   * Indoor Report
   ********************************************/
  getIndoorReport(
    station: NetatmoWeatherStation,
    module: NetatmoWeatherModule,
    year: number,
    month: number | null,
    formatter: string,
  ): Record<'indoor', Record<string, number[]>> {
    const start = this.getReportStart(year, month, station.timezone);
    const end = this.getReportEnd(year, month, station.timezone);

    const reportData = {} as Record<'indoor', Record<string, number[]>>;

    const metricList = [
      'Temperature',
      'min_temp',
      'max_temp',
      'Humidity',
      'Co2',
    ];
    if (module.getType() === ModuleStringTypes.NA_MAIN) {
      metricList.push('Noise');
    }
    reportData.indoor = station.getModuleFromId(module.id)
      ? this.getFormattedChartData(
          module.id,
          metricList,
          '1day',
          start,
          end,
          formatter,
          station.timezone,
        )
      : {};

    return reportData;
  }

  getMonthlyIndoorReport(
    station: NetatmoWeatherStation,
    module: NetatmoWeatherModule,
    year: number,
    month: number,
  ) {
    return this.getIndoorReport(station, module, year, month, 'YYYY/MM/DD');
  }

  getAnnualIndoorReport(
    station: NetatmoWeatherStation,
    module: NetatmoWeatherModule,
    year: number,
  ) {
    return this.getIndoorReport(station, module, year, null, 'YYYY/MM/DD');
  }
}
