import {IconButton, MenuList} from '@mui/material';
import {selectIsLoggedIn} from '@smartmixin/core';
import {AccountCircle} from 'mdi-material-ui';
import React from 'react';
import Menu from '../../../components/Menu';
import {useSelector} from '../../../store';
import AuthenticatedContent from './AuthenticatedContent';
import UnauthenticatedContent from './UnauthenticatedContent';

const UserMenu = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={openMenu}>
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClick={closeMenu}
        onClose={closeMenu}>
        <MenuList disablePadding>
          {isLoggedIn ? <AuthenticatedContent /> : <UnauthenticatedContent />}
        </MenuList>
      </Menu>
    </>
  );
};

export default UserMenu;
