import NetatmoModule from '../NetatmoCommon/NetatmoModule';
import {ModuleStringTypes} from './constants';
import {ModuleDashboardData, NetatmoEnergyModuleType} from './types';
export default class Module extends NetatmoModule {
  module: NetatmoEnergyModuleType;
  dashboardData: ModuleDashboardData | undefined;

  constructor(
    data: NetatmoEnergyModuleType,
    dashboardData: ModuleDashboardData | undefined,
  ) {
    super();
    this.module = data;
    this.dashboardData = dashboardData;

    this.id = this.getId();
  }

  getId() {
    return this.module.id;
  }

  getType() {
    return this.module.type;
  }

  isBridged() {
    return typeof this.module.bridge !== 'undefined';
  }

  getBridgeId() {
    return this.module.bridge;
  }

  formatType() {
    const formatMatrix = {
      NAPlug: 'relay',
      NATherm1: 'smartThermostat',
      NRV: 'smartValve',
      OTH: 'relay',
      OTM: 'smartThermostat',
    };
    return formatMatrix[this.getType()];
  }

  getName() {
    if (this.module.name !== undefined && this.module.name !== '') {
      return this.module.name;
    }
    return null;
  }

  getDefaultName() {
    if (this.getType() === ModuleStringTypes.NA_PLUG) {
      return 'relay';
    } else if (this.getType() === ModuleStringTypes.NA_THERM1) {
      return 'thermostat';
    } else if (this.getType() === ModuleStringTypes.NA_VALVE) {
      return 'valve';
    } else if (this.getType() === ModuleStringTypes.OTM) {
      return 'thermostat';
    } else if (this.getType() === ModuleStringTypes.OTH) {
      return 'relay';
    }
  }

  getSetupDate() {
    return this.module.setup_date;
  }

  getModulesBridged() {
    return this.module.modules_bridged;
  }

  getRoomId() {
    return this.module.room_id;
  }

  getDashboardData() {
    return this.dashboardData;
  }

  getFirmwareRevision() {
    return this.getDashboardData()?.firmware_revision;
  }

  getBatteryLevel() {
    return this.getDashboardData()?.battery_level;
  }

  getBatteryState() {
    return this.getDashboardData()?.battery_state;
  }

  rfStrength() {
    return this.getDashboardData()?.rf_strength;
  }

  formatRfStatus() {
    const rfStatus = this.rfStrength();
    if (typeof rfStatus !== 'undefined') {
      if (rfStatus >= 86) {
        return 'bad';
      }
      if (rfStatus >= 71) {
        return 'average';
      }
    }
    return 'good';
  }

  getWifiStrength() {
    return this.getDashboardData()?.wifi_strength;
  }

  formatWifiStatus() {
    const rfStatus = this.getWifiStrength();
    if (typeof rfStatus !== 'undefined') {
      if (rfStatus >= 86) {
        return 'bad';
      }
      if (rfStatus >= 71) {
        return 'average';
      }
    }
    return 'good';
  }

  anticipating() {
    return this.getDashboardData()?.anticipating;
  }

  getBoilerStatus() {
    // only for NATherm1
    return this.getDashboardData()?.boiler_status;
  }

  isReachable() {
    return this.getDashboardData()?.reachable;
  }

  // getThermProgramList() {
  //   let scheduleList = [];
  //   for (let item of this.getData().therm_program_list) {
  //     scheduleList.push(new Schedule(item));
  //   }
  //   return scheduleList;
  // }

  // getCurrentThermProgram() {
  //   for (let schedule of this.getThermProgramList()) {
  //     if (schedule.isSelected()) {
  //       return schedule;
  //     }
  //   }
  // }

  // getThermProgram(programId) {
  //   for (let schedule of this.getThermProgramList()) {
  //     if (schedule.id === programId) {
  //       return schedule;
  //     }
  //   }
  //   return null;
  // }

  // getProgramSetpointTemp(schedule = null) {
  //   return this.getProgramSetpointZone(schedule).temp;
  // }

  // getFrostGuardSetpointTemp(schedule = null) {
  //   return this.getZoneFromType(
  //     schedule || this.getCurrentThermProgram(),
  //     ZONE_TYPE[FROST_GUARD],
  //   ).temp;
  // }

  // getAwaySetpointTemp(schedule = null) {
  //   return this.getZoneFromType(
  //     schedule || this.getCurrentThermProgram(),
  //     ZONE_TYPE[AWAY],
  //   ).temp;
  // }

  // getProgramSetpointZoneName(schedule = null) {
  //   return this.getProgramSetpointZone(schedule).name;
  // }

  // getProgramSetpointZone(schedule = null) {
  //   const program = schedule || this.getCurrentThermProgram();
  //   const currentOffset = Dates.timeDiffWithMidnight(this.relay.timezone);
  //   let currentTimeslotId = null;
  //   for (let timeslot of program.getTimetable()) {
  //     if (currentOffset > timeslot.m_offset) {
  //       currentTimeslotId = timeslot.id;
  //     } else {
  //       break;
  //     }
  //   }
  //   const currentZone = this.getZoneFromType(program, currentTimeslotId);
  //   return currentZone;
  // }

  // getProgramSetpointZoneEndtime(program = null) {
  //   const selectedProgram = program || this.getCurrentThermProgram();
  //   const currentOffset = Dates.timeDiffWithMidnight(this.relay.timezone);
  //   for (let timeslot of selectedProgram.getTimetable()) {
  //     if (currentOffset < timeslot.m_offset) {
  //       return (
  //         Dates.timestampAtWeekStart(this.relay.timezone) +
  //         60 * timeslot.m_offset
  //       );
  //     }
  //   }
  //   return null;
  // }

  // getZoneFromType(program, type) {
  //   for (let zone of program.getZoneList()) {
  //     if (zone.id === type) {
  //       return zone;
  //     }
  //   }
  // }

  // getCurrentThermProgramName() {
  //   return this.getCurrentThermProgram().name;
  // }

  // getFirmware() {
  //   return this.getData().firmware;
  // }

  // getRfStatus() {
  //   return this.getData().rf_status;
  // }

  // formatRfStatus() {
  //   const rfStatus = this.getRfStatus();
  //   switch (rfStatus) {
  //     case rfStatus >= 86:
  //       return 'bad';
  //     case rfStatus >= 71:
  //       return 'average';
  //     default:
  //       return 'good';
  //   }
  // }

  // getBatteryPercent() {
  //   return this.getData().battery_percent;
  // }

  // /************************************
  //  ********** DASHBOARD DATA **********
  //  ************************************/
  // getData() {
  //   return this.module.data;
  // }

  // getTemp() {
  //   return this.getData().measured.temperature;
  // }

  // getSetpointMode() {
  //   return this.getData().setpoint.setpoint_mode;
  // }

  // getManualSetpointTemp() {
  //   return this.getData().setpoint.setpoint_temp;
  // }

  // getSetpointEndTime() {
  //   return this.getData().setpoint.setpoint_endtime !== undefined
  //     ? this.getData().setpoint.setpoint_endtime
  //     : null;
  // }

  // getSetpointInfo() {
  //   const setpoint = {};
  //   setpoint.mode = this.getSetpointMode();
  //   // const timezone = this.getThermostat().timezone
  //   if (setpoint.mode === PROGRAM) {
  //     setpoint.temp = this.getProgramSetpointTemp();
  //     setpoint.endtime = null;
  //   } else if (setpoint.mode === AWAY) {
  //     setpoint.temp = this.getZoneFromType(
  //       this.getCurrentThermProgram(),
  //       ZONE_TYPE[AWAY],
  //     ).temp;
  //     setpoint.endtime = this.getSetpointEndTime();
  //   } else if (setpoint.mode === FROST_GUARD) {
  //     setpoint.temp = this.getZoneFromType(
  //       this.getCurrentThermProgram(),
  //       ZONE_TYPE[setpoint.mode],
  //     ).temp;
  //     setpoint.endtime = this.getSetpointEndTime();
  //   } else if (setpoint.mode === MANUAL) {
  //     setpoint.temp = this.getManualSetpointTemp();
  //     setpoint.endtime = this.getSetpointEndTime();
  //   } else if (setpoint.mode === OFF) {
  //     setpoint.temp = null;
  //   } else if (setpoint.mode === MAX) {
  //     setpoint.temp = null;
  //     setpoint.endtime = this.getSetpointEndTime();
  //   }
  //   return setpoint;
  // }

  // isHeatingOn() {
  //   if (this.getSetpointMode() === MAX) {
  //     return true;
  //   }
  //   return this.getData().therm_relay_cmd !== 0;
  // }
}
