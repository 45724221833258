import {createSlice} from '@reduxjs/toolkit';
import {NetatmoDataGranularity} from '../../api/types';
import {DAY} from '../../utils/Dates';
import {
  HomeStatus,
  NetatmoEnergyChartConfig,
  NetatmoEnergyComparisonConfig,
} from '../../utils/NetatmoEnergy/types';
import {Scale} from '../../utils/Time';

type Account = {
  uid: string;
  active: boolean;
  last_updated: number;
  extra_data: Record<string, any>;
};

type Link = {
  account: Account;
  date_connected: number;
};

type Props = {
  link: Link | null;
  currentHome: string | null;
  homeStatus: Record<string, HomeStatus>;
  chartConfig: NetatmoEnergyChartConfig;
};

export const netatmoEnergySlice = createSlice({
  name: 'netatmoEnergy',
  initialState: {
    link: null,
    currentHome: null,
    homeStatus: {},
    chartConfig: {
      scale: DAY,
      granularity: 'max',
      comparison: null,
    },
  } as Props,
  reducers: {
    setNetatmoEnergyUserLink: (state, {payload: link}) => {
      state.link = link;
    },
    setNetatmoEnergyAccount: (state, {payload: account}) => {
      if (state.link !== null) {
        state.link.account = account;
      }
    },
    setNetatmoEnergyCurrentHome: (state, {payload: homeId}) => {
      state.currentHome = homeId;
    },
    setNetatmoEnergyHomeStatus: (
      state,
      {
        payload: {homeId, status},
      }: {
        payload: {
          homeId: string;
          status: HomeStatus;
        };
      },
    ) => {
      const newStatus = {} as Record<string, HomeStatus>;
      newStatus[homeId] = status;
      state.homeStatus = {...state.homeStatus, ...newStatus};
    },
    setNetatmoEnergyChartComparison: (
      state,
      {
        payload: comparisonConfig,
      }: {payload: NetatmoEnergyComparisonConfig | null},
    ) => {
      state.chartConfig = {
        ...state.chartConfig,
        comparison: comparisonConfig,
      };
    },
    setNetatmoEnergyChartScale: (state, {payload: scale}: {payload: Scale}) => {
      state.chartConfig = {...state.chartConfig, scale};
    },
    setNetatmoEnergyChartGranularity: (
      state,
      {payload: granularity}: {payload: NetatmoDataGranularity},
    ) => {
      state.chartConfig = {...state.chartConfig, granularity};
    },
  },
});

export const {
  setNetatmoEnergyUserLink,
  setNetatmoEnergyAccount,
  setNetatmoEnergyCurrentHome,
  setNetatmoEnergyHomeStatus,
  setNetatmoEnergyChartComparison,
  setNetatmoEnergyChartScale,
  setNetatmoEnergyChartGranularity,
} = netatmoEnergySlice.actions;

export default netatmoEnergySlice.reducer;
