import {SupportedForecastModelList} from './constants';
import ForecastModelListHandler from './ForecastModelListHandler';
import {ForecastModelConfig} from './types';

export const getForecastModelList = (rawModelList: ForecastModelConfig[]) => {
  const modelList = SupportedForecastModelList.filter(
    (formattedModel) =>
      rawModelList.filter(
        (rawModel) =>
          rawModel.forecast_model === formattedModel.serverId.forecastModel &&
          (formattedModel.serverId.meshSize === null ||
            rawModel.mesh_size === formattedModel.serverId.meshSize),
      ).length > 0,
  );

  return new ForecastModelListHandler(modelList);
};
