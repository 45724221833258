import {Tab as MuiTab, styled} from '@mui/material';

const Tab = styled(MuiTab)(({theme}) => ({
  color: theme.palette.text.secondary,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
  },
}));

export default Tab;
