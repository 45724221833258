import {RootState} from '..';

export const selectTheme = (state: RootState) => state.prefs.theme;

export const selectIsDarkTheme = (state: RootState) => {
  if (state.prefs.theme) {
    return state.prefs.theme === 'dark';
  }
  return false;
};

export const selectNetatmoWeatherChartView = (state: RootState) =>
  state.prefs.netatmoWeather.chartView;

export const selectNetatmoWeatherAnalysisChartView = (state: RootState) =>
  state.prefs.netatmoWeather.analysisChartView;

export const selectNetatmoEnergyChartView = (state: RootState) =>
  state.prefs.netatmoEnergy.chartView;

export const selectNetatmoEnergyAnalysisChartView = (state: RootState) =>
  state.prefs.netatmoEnergy.analysisChartView;

export const selectWeatherChartView = (state: RootState) =>
  state.prefs.weather.chartView;

export const selectChartGestureEnabled = (state: RootState) =>
  state.prefs.gesture.chart;

export const getLastNavigator = (state: RootState) =>
  state.prefs.lastNavigator || null;

export const getCloudLayer = (state: RootState) =>
  state.prefs.rainRadar.cloudLayer;
