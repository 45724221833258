import {configureStore} from '@reduxjs/toolkit';
import {CommonRootState} from '@smartmixin/core';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import {createMigrate, persistReducer, persistStore} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {migrations} from './migrations';
import rootReducer from './reducers';

// Create persistant store
export const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  blacklist: ['ui', 'data', 'weatherData'],
  stateReconcilier: autoMergeLevel2,
  debug: true,
  migrate: createMigrate(migrations, {debug: true}),
  writeFailHandler: (err: Error) => console.tron('[PersistError] ', err),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/* ------------- Redux Configuration ------------- */

const enhancers = [];
// if (process.env.NODE_ENV === "development") {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const Reactotron = require("../lib/Reactotron").default;
//   enhancers.push(Reactotron.createEnhancer());
// } else {
//   // Sentry Enhancer
//   enhancers.push(SentryUtils.createReduxEnhancer());
// }
/* ------------- Assemble Middleware ------------- */

const store = configureStore({
  reducer: persistedReducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(enhancers);
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;

// Create typed useSelector
export const useSelector: TypedUseSelectorHook<RootState & CommonRootState> =
  useReduxSelector;

export default store;
