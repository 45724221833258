import {createSlice} from '@reduxjs/toolkit';
import {
  AmbientWeatherUserLink,
  AmbientWeatherWeatherStation,
} from '../../api/types';

type Props = {
  link: AmbientWeatherUserLink | null;
  currentStation: string | null;
};

export const ambientWeatherSlice = createSlice({
  name: 'ambientWeather',
  initialState: {
    link: null,
    currentStation: null,
  } as Props,
  reducers: {
    setAmbientWeatherUserLink: (
      state,
      {payload: link}: {payload: AmbientWeatherUserLink},
    ) => {
      if (state.link === null || link === null) {
        state.link = link;
      } else {
        // mergeDeep in order to keep generic_station key which is not present for station list
        const oldWeatherStations = state.link.account.weather_stations;
        const newWeatherStations = link.account.weather_stations;

        state.link = link;
        const mergedWeatherStations = [];
        newWeatherStations.forEach((newItem) => {
          const oldStation = oldWeatherStations.find(
            (oldItem) => oldItem.device_id === newItem.device_id,
          );
          if (oldStation) {
            mergedWeatherStations.push({...oldStation, ...newItem});
          } else {
            mergedWeatherStations.push(newItem);
          }
        });
        state.link.account.weather_stations = mergedWeatherStations;
      }
    },
    setAmbientWeatherStationData: (
      state,
      {payload: station}: {payload: AmbientWeatherWeatherStation},
    ) => {
      if (state.link !== null) {
        const stationIdx = state.link.account.weather_stations.findIndex(
          (item) => item.device_id === station.device_id,
        );
        if (stationIdx !== -1) {
          state.link.account.weather_stations[stationIdx] = station;
        }
      }
    },
    setAmbientWeatherCurrentStation: (
      state,
      {payload: stationid}: {payload: string},
    ) => {
      state.currentStation = stationid;
    },
  },
});

export const {
  setAmbientWeatherUserLink,
  setAmbientWeatherStationData,
  setAmbientWeatherCurrentStation,
} = ambientWeatherSlice.actions;

export default ambientWeatherSlice.reducer;
