import {Alert as MuiAlert} from '@mui/material';
import React from 'react';

const Alert = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MuiAlert>
>((props, ref) => {
  return <MuiAlert ref={ref} {...props} />;
});

export default Alert;
