import {SOURCE_NETATMO_WEATHER} from '../../api';
import {WeatherStationFromList} from '../../api/types';
import {getAvg, getMax, getMin, getSum} from '../NetatmoCommon/Utils';
import NetatmoWeatherStation from './NetatmoWeatherStation';
import {NetatmoWeatherStationType} from './types';

export const getStationFromId = (
  stationList: NetatmoWeatherStation[],
  stationId: string,
) => {
  for (const station of stationList) {
    if (station.id === stationId) {
      return station;
    }
  }
  return null;
};

export const getStationList = (
  items: NetatmoWeatherStationType[],
  genericStationList: WeatherStationFromList[],
) => {
  const stationList = [] as NetatmoWeatherStation[];
  if (typeof items !== 'undefined') {
    for (const item of items) {
      // Do not add stations that don't have place attribute
      if (typeof item.place !== 'undefined') {
        const genericStation = genericStationList.find(
          (s) => s.uid === item._id && s.source === SOURCE_NETATMO_WEATHER,
        );
        stationList.push(new NetatmoWeatherStation(item, genericStation));
      }
    }
  }
  return stationList;
};

export const convertOutdoorReportToAnnual = (
  report: Record<'indoor' | 'outdoor' | 'rain', Record<string, number[]>>,
  year: number,
) => {
  const months = Array.from({length: 12}, (_, key) => key + 1);

  const annualReport = {
    indoor: {},
    outdoor: {},
    rain: {},
  } as Record<'indoor' | 'outdoor' | 'rain', Record<string, number[]>>;

  months.forEach((month) => {
    const formattedMonth = `${year}/${month.toString().padStart(2, '0')}`;
    annualReport.indoor[formattedMonth] = [
      getAvg(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        0,
      ),
    ];
    annualReport.outdoor[formattedMonth] = [
      getAvg(
        Object.keys(report.outdoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.outdoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        0,
      ),
      getMin(
        Object.keys(report.outdoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.outdoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        1,
      ),
      getMax(
        Object.keys(report.outdoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.outdoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        2,
      ),
      getAvg(
        Object.keys(report.outdoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.outdoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        3,
      ),
    ];
    annualReport.rain[formattedMonth] = [
      getSum(
        Object.keys(report.rain)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.rain[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        0,
      ),
    ];
  });

  return annualReport;
};

export const convertIndoorReportToAnnual = (
  report: Record<'indoor', Record<string, number[]>>,
  year: number,
  main: boolean,
) => {
  const months = Array.from({length: 12}, (_, key) => key + 1);

  const annualReport = {
    indoor: {},
  } as Record<'indoor', Record<string, number[]>>;

  months.forEach((month) => {
    const formattedMonth = `${year}/${month.toString().padStart(2, '0')}`;
    annualReport.indoor[formattedMonth] = [
      // average temperature
      getAvg(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        0,
      ),
      // min temperature
      getMin(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        1,
      ),
      // max temperature
      getMax(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        2,
      ),
      // avg humidity
      getAvg(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        3,
      ),
      // avg co2
      getAvg(
        Object.keys(report.indoor)
          .filter((ts) => ts.startsWith(formattedMonth))
          .reduce(
            (obj, key) => {
              obj[key] = report.indoor[key];
              return obj;
            },
            {} as Record<string, number[]>,
          ),
        4,
      ),
    ];

    if (main) {
      annualReport.indoor[formattedMonth].push(
        // avg noise
        getAvg(
          Object.keys(report.indoor)
            .filter((ts) => ts.startsWith(formattedMonth))
            .reduce(
              (obj, key) => {
                obj[key] = report.indoor[key];
                return obj;
              },
              {} as Record<string, number[]>,
            ),
          5,
        ),
      );
    }
  });

  return annualReport;
};

export const getYearRangeFromFormattedReport = (
  report: Record<string, any>,
) => {
  const yearArray = Object.keys(report).map((key) => key.substring(0, 4));
  return [...new Set(yearArray)];
};
