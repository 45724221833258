import {createSlice} from '@reduxjs/toolkit';
import {FeelLike} from '../../api/types';
import {DateTimeConfig} from '../../utils/Dates';
import {Units} from '../../utils/Units';

type SliceState = {
  language: string | null;
  units: Units | null;
  feelLike: FeelLike | null;
  date_time: DateTimeConfig | null;
};

const initialState: SliceState = {
  language: null,
  units: null,
  feelLike: null,
  date_time: null,
};

export const userPreferenceSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    storeLanguage: (state, {payload: languageCode}: {payload: string}) => {
      state.language = languageCode;
    },
    storeUnits: (state, {payload: units}: {payload: Units}) => {
      state.units = units;
    },
    storeFeelLike: (state, {payload: feelLike}: {payload: FeelLike}) => {
      state.feelLike = feelLike;
    },
    storeDateTime: (state, {payload: dateTime}: {payload: DateTimeConfig}) => {
      state.date_time = dateTime;
    },
  },
});

export const {storeLanguage, storeUnits, storeFeelLike, storeDateTime} =
  userPreferenceSlice.actions;

export default userPreferenceSlice.reducer;
