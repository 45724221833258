import {messages} from '@smartmixin/i18n';
import {ApiErrorResponse, HEADERS} from 'apisauce';
import {StatusCodes} from 'http-status-codes';
import JwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import React from 'react';
import isEqual from 'react-fast-compare';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useUserLanguage} from '.';
import {ErrorCode, SUPPORTED_API_VERSION, URL_ROOT, apiClient} from '../api';
import {ApiRequestParams, ApiResponse} from '../api/types';
import {
  getDebugEmail,
  selectIsAdmin,
  selectToken,
  setAlert,
  setUpgradeRequired,
  storeToken,
} from '../store';
import {JwtToken} from '../store/user/types';

interface FieldInfo {
  code: 'string' | number;
  message: 'string';
}

export interface ErrorInfo {
  statusCode?: number;
  fields: Record<string, FieldInfo>;
  details?: {
    message: string;
    code: string | number;
  };
}

interface DecodedJwtToken {
  token_type: string;
  exp: number;
  jti: string;
  user_id: number;
}

type RefreshTokenResponse = {
  refresh: string;
  access: string;
};

const client = apiClient(URL_ROOT);

const useApiClient = () => {
  const dispatch = useDispatch();
  const storedToken = useSelector(selectToken, isEqual) as JwtToken | null;
  const language = useUserLanguage();
  const [loading, setLoading] = React.useState(false);
  const {formatMessage} = useIntl();
  const debugEmail = useSelector(getDebugEmail);
  const isAdmin = useSelector(selectIsAdmin);

  const timerRef = React.useRef(null);

  function debounce(f, interval: number) {
    return (...args: any) => {
      clearTimeout(timerRef.current);
      return new Promise((resolve) => {
        timerRef.current = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  }

  const fetch = React.useCallback(
    async (
      apiCall: ApiRequestParams,
      options: {
        debounce?: number;
        ignore404?: boolean;
        ignore401?: boolean;
        accessToken?: string;
        external?: boolean;
        headers?: HEADERS;
      } = {},
    ): Promise<any> => {
      const errorHandler = (errorRes: ApiErrorResponse<any>) => {
        const info: ErrorInfo = {
          fields: {},
        };
        if (errorRes.problem === 'CLIENT_ERROR') {
          info.statusCode = errorRes.status;
          if (errorRes.status === StatusCodes.BAD_REQUEST) {
            if (errorRes.data.non_field_errors) {
              // global error
              // always 1 elem:
              info.details = errorRes.data.non_field_errors[0];
              info.fields = {};
            } else {
              // fields error
              // technical, not translated:
              // info.type = response.data.types.FIELDS
              Object.keys(errorRes.data).forEach((key) => {
                const name = key as string;
                const details = errorRes.data[key][0] as FieldInfo; // always 1 elem
                info.fields[name] = details;
              });
              return info;
            }
            return info;
          }
          info.details = errorRes.data.detail; // global error
          info.fields = {};
        } else {
          if (errorRes.problem === 'CONNECTION_ERROR') {
            info.details = {
              message: formatMessage(messages.network.connectionError),
              code: 'connectionError',
            };
          } else if (errorRes.problem === 'NETWORK_ERROR') {
            info.details = {
              message: formatMessage(messages.network.networkError),
              code: 'networkError',
            };
          } else if (errorRes.problem === 'TIMEOUT_ERROR') {
            info.details = {
              message: formatMessage(messages.network.timeoutError),
              code: 'timeoutError',
            };
          } else if (errorRes.problem === 'SERVER_ERROR') {
            if (errorRes.status === StatusCodes.SERVICE_UNAVAILABLE) {
              info.details = errorRes.data.detail;
            } else {
              info.details = {
                message: formatMessage(messages.network.serverError),
                code: 'serverError',
              };
            }
          } else if (errorRes.problem === 'CANCEL_ERROR') {
            info.details = {
              message: formatMessage(messages.network.cancelError),
              code: 'cancelError',
            };
          } else {
            info.details = {
              message: formatMessage(messages.network.unknownError),
              code: 'unknownError',
            };
          }
          info.fields = {};
        }
        return info;
      };

      const getToken = async (token: JwtToken | null) => {
        /* This is the automatic refresh token mechanism
        When getting the token, we ensure the stored access
        token is valid and if not, we refresh it using the
        stored refresh token */
        if (token) {
          const decodedAccessToken = JwtDecode(
            token.accessToken,
          ) as DecodedJwtToken;
          if (
            moment().unix() >
            moment.unix(decodedAccessToken.exp).unix() - 5 * 60
          ) {
            // Refresh Token in order to get a renewed token pair (access + refresh)
            const decodedRefreshToken = JwtDecode(
              token.refreshToken,
            ) as DecodedJwtToken;
            if (moment().unix() > moment.unix(decodedRefreshToken.exp).unix()) {
              dispatch({type: 'LOGOUT'});
              return '';
            }
            client.deleteHeader('Authorization');
            const res = (await client.post('auth/token/refresh/', {
              refresh: token.refreshToken,
            })) as ApiResponse<RefreshTokenResponse, any>;
            if (res.ok) {
              if (res.data) {
                const {refresh: refreshToken, access: accessToken} = res.data;
                dispatch(storeToken({accessToken, refreshToken}));
                return accessToken;
              } else {
                // We should never be there
                return '';
              }
            } else {
              const err = errorHandler(res as ApiErrorResponse<any>);

              if (err.details) {
                dispatch(
                  setAlert({message: err.details.message, type: 'ERROR'}),
                );
              }

              if (err.statusCode === StatusCodes.UNAUTHORIZED) {
                dispatch({type: 'LOGOUT'});
              }
              return Promise.reject(err);
            }
          } else {
            // stored accessToken is still valid, use it
            return token.accessToken;
          }
        } else {
          // There is not stored token
          return '';
        }
      };

      const fetchData = async (
        requestParams: ApiRequestParams,
        userToken: JwtToken | null,
      ) => {
        if (options && options.external) {
          Object.keys(client.headers).forEach((key) => {
            key.startsWith('X-SmartMixin-') && client.deleteHeader(key);
          });
        } else {
          // API header for SmartMixin only
          client.setHeader('X-SmartMixin-Context', 'UI');
        }

        client.setHeader('Accept-Language', `${language}`);
        const {url, method, params} = requestParams;

        const token =
          options && options.accessToken
            ? options.accessToken
            : await getToken(userToken);
        // Do not use Authorization header if external of token is empty
        if ((options && options.external) || !token) {
          client.deleteHeader('Authorization');
        } else {
          // it's an SmartMixin API call
          client.setHeader('Authorization', `Bearer ${token}`);
        }

        if (debugEmail && isAdmin) {
          client.setHeader('X-Login-As', `${debugEmail}`);
        } else {
          client.deleteHeader('X-Login-As');
        }

        if (options && options.headers) {
          Object.entries(options.headers).forEach(([key, value]) => {
            client.setHeader(key, value);
          });
        }

        let res;
        if (method === 'GET') {
          res = await client.get(url, params);
        } else if (method === 'POST') {
          res = await client.post(url, params);
        } else if (method === 'PATCH') {
          res = await client.patch(url, params);
        } else if (method === 'PUT') {
          res = await client.put(url, params);
        } else if (method === 'DELETE') {
          res = await client.delete(url, params);
        } else {
          throw 'method not allowed';
        }

        setLoading(false);

        if (res.headers && Object.keys(res.headers).includes('x-api-version')) {
          if (
            SUPPORTED_API_VERSION < parseInt(res.headers['x-api-version'], 10)
          ) {
            dispatch(setUpgradeRequired(true));
          }
        }

        if (!res.ok) {
          const err = errorHandler(res as ApiErrorResponse<any>);

          if (
            err.details &&
            !ErrorCode.HANDLED_ERROR_CODES.includes(err.details.code.toString())
          ) {
            if (err.statusCode === StatusCodes.NOT_FOUND) {
              if (options && options.ignore404) {
              } else {
                dispatch(
                  setAlert({message: err.details.message, type: 'ERROR'}),
                );
              }
            } else if (err.statusCode === StatusCodes.UNAUTHORIZED) {
              if (options && options.ignore401) {
              } else {
                dispatch(
                  setAlert({message: err.details.message, type: 'ERROR'}),
                );
              }
            } else {
              dispatch(setAlert({message: err.details.message, type: 'ERROR'}));
            }
          }

          return Promise.reject(err);
        } else {
          return res.data;
        }
      };

      const methodCall =
        options && options.debounce
          ? debounce(fetchData, options.debounce)
          : fetchData;

      setLoading(true);

      return methodCall(apiCall, storedToken);
    },
    [debugEmail, dispatch, isAdmin, language, storedToken, formatMessage],
  );
  return {fetch, loading};
};

export default useApiClient;
