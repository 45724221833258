import {Menu as MuiMenu, styled} from '@mui/material';
import React from 'react';

const MyMenu = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MuiMenu>
>((props, ref) => {
  return (
    <MuiMenu
      slotProps={{
        paper: {
          variant: 'outlined',
          elevation: 0,
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      ref={ref}
      {...props}
    />
  );
});

const Menu = styled(MyMenu)(({theme}) => ({
  '& .MuiPaper-root': {
    minWidth: 200,
    '& .MuiMenuItem-root': {
      marginInline: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      '& .MuiListItemText-primary': {
        fontSize: 14,
        fontWeight: 500,
      },
      '& .MuiListItemText-secondary': {
        fontSize: 12,
      },
      '& .MuiMenuItem-gutters': {},
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export default Menu;
