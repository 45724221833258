import {createSlice} from '@reduxjs/toolkit';
import {HistoryMeasureResult, WeatherStationForecast} from '../../api/types';
import {NetatmoCommon} from '../../utils';

type Props = {
  historyData: Record<string, Record<string, any>>;
  forecastData: Record<string, WeatherStationForecast>;
};

export const dataSlice = createSlice({
  name: 'weatherData',
  initialState: {
    historyData: {},
    forecastData: {},
  } as Props,
  reducers: {
    pushWeatherHistorySeries: (
      state,
      {payload: data}: {payload: HistoryMeasureResult[]},
    ) => {
      const handler = new NetatmoCommon.ChartsDataContainer(state.historyData);
      Object.values(data).forEach((item) => {
        handler.update(item);
      });
      state.historyData = handler.getData();
    },
    pushStationForecast: (
      state,
      {
        payload: {stationId, forecastModel, meshSize, data},
      }: {
        payload: {
          stationId: number;
          forecastModel: string;
          meshSize?: string;
          data: WeatherStationForecast;
        };
      },
    ) => {
      const newStationForecastData = {} as Record<
        string,
        WeatherStationForecast
      >;
      const key = `${stationId}-${forecastModel}-${meshSize}`;
      newStationForecastData[key] = data;
      state.forecastData = {...state.forecastData, ...newStationForecastData};
    },
  },
});

export const {pushWeatherHistorySeries, pushStationForecast} =
  dataSlice.actions;

export default dataSlice.reducer;
