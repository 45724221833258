import NetatmoEnergyHome from './NetatmoEnergyHome';
import {HomeStatus, HomeType} from './types';

export const getHomeFromId = (
  homeList: NetatmoEnergyHome[],
  homeId: string,
) => {
  for (const home of homeList) {
    if (home.id === homeId) {
      return home;
    }
  }
  return null;
};

export const getHomeList = (
  items: HomeType[],
  statuses: Record<string, HomeStatus>,
) => {
  const homeList = [];
  if (typeof items !== 'undefined') {
    for (const item of items) {
      if (
        typeof item.rooms !== 'undefined' &&
        typeof item.modules !== 'undefined'
      ) {
        homeList.push(new NetatmoEnergyHome(item, statuses[item.id]));
      }
    }
  }
  return homeList;
};
