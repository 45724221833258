import {SOURCE_WEATHERFLOW} from '../../api';
import {StationMeta, WeatherStationFromList} from '../../api/types';

export const getCustomStationName = (s: WeatherStationFromList) =>
  (s.link && s.link?.user_data?.name) || s.custom_name || '';

export const getStationName = (s: WeatherStationFromList) => {
  if (s) {
    return (
      (s.link && (s.link?.user_data?.name || s.link.name)) ||
      s.custom_name ||
      s.name
    );
  }
  return '';
};

export const isWeatherflowRainCheckEnabled = (meta: StationMeta) =>
  (Object.keys(meta).includes('weatherflow_raincheck_enabled') &&
    meta.weatherflow_raincheck_enabled) ||
  !Object.keys(meta).includes('weatherflow_raincheck_enabled');

export const getRainToday = (s: WeatherStationFromList) => {
  if (s) {
    if (
      s.source === SOURCE_WEATHERFLOW &&
      isWeatherflowRainCheckEnabled(s.meta) &&
      !isNaN(Number(s.last_measure?.RR_TODAY_RAIN_CHECK))
    ) {
      // Weatherlink Rain Check
      return s.last_measure?.RR_TODAY_RAIN_CHECK;
    }
    if (!isNaN(Number(s.last_measure?.RR_TODAY))) {
      return s.last_measure?.RR_TODAY;
    }
    if (!isNaN(Number(s.last_measure?.RR_PIEZO_TODAY))) {
      return s.last_measure?.RR_PIEZO_TODAY;
    }
  }
  return null;
};

export const getRainYesterday = (s: WeatherStationFromList) => {
  if (s) {
    if (
      s.source === SOURCE_WEATHERFLOW &&
      isWeatherflowRainCheckEnabled(s.meta) &&
      !isNaN(Number(s.last_measure?.RR_YESTERDAY_RAIN_CHECK))
    ) {
      // Weatherlink Rain Check
      return s.last_measure?.RR_YESTERDAY_RAIN_CHECK;
    }
    if (!isNaN(Number(s.last_measure?.RR_YESTERDAY))) {
      return s.last_measure?.RR_YESTERDAY;
    }
  }
  return null;
};
